// #if process.env.BUILD_SECURE === '1'
// export const ADSERVER_RATE_WARN_DEFAULT_VALUE = 50;
// export const CSV_FILE_EXTENSION = '.csv';
// export const MAX_SUMMARY_PROVIDER_ROWS_VISIBLE_IN_DASHBOARD = 7;
// export const MAX_AVAILABLE_ORDER_FOR_MONITORING = 5000;
// export const MINIMUM_IMPRESSIONS_DEFAULT = 10;
// export const LOGIN_STRING = 'loggedin';
// export const PROPERTY_NAME_LENGTH_CONSTANTS = 20;
// export const REPORT_TABLE_DATE_FORMAT = 'MMM DD, YYYY';
// #endif

export const TRIMMED_STRING_SUFFIX = '...';
export const DATE_RANGE_PICKER_FORMAT_FROM = 'YYYY-MM-DD';
export const DATE_RANGE_PICKER_FORMAT_TO = 'YYYY-MM-DD';
export const DATE_RANGE_PICKER_FORMAT = 'YYYY-MM-DD';
export const DATE_RANGE_PICKER_DISPLAY_FORMAT = 'MMM DD, YY';
export const FILE_NAME_DATE_FORMAT = 'YYYYMMDD';
export const FLAGGED_SINCE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const PIE_CHART_COLORS = [
    '#CF2D4F',
    '#322773',
    '#0E6996',
    '#208E9B',
    '#8CBFAF',
];
