import {
    SET_AD_TRACE_DOM_INSTANCE,
    AD_TRACE_DOM_INSTANCE_START,
} from '../actions/ActionTypes';
import { adTraceCMWidgets } from '../constants/PrivacyConstants';

const InitialState = {
    domInstanceResponse: '',
    isAdTraceLoading: false,
    isConsentMismatch: false,
    consentDetails: null,
    sessionData: null,
    url: null,
    consentMisType: null,
    cmpData: null,
    consentString: null,
    trackingType: null,
    cookies: null,
    localStorage: null,
    adTraceCMWidgets: adTraceCMWidgets,
};

let tcfData = {
    tcfPolicyVersion: null,
    cmpId: null,
    cmpVersion: null,
    gdprApplies: null,
    eventStatus: null,
    cmpStatus: null,
    listenerId: null,
    isServiceSpecific: null,
    useNonStandardStacks: false,
    publisherCC: null,
    purposeOneTreatment: null,
    outOfBand: {
        allowedVendors: {},
        disclosedVendors: {},
    },
    purpose: {
        consents: {},
        legitimateInterests: {},
    },
    vendor: {
        consents: {},
        legitimateInterests: {},
    },
    specialFeatureOptins: {},
    publisher: {
        consents: {},
        legitimateInterests: {},
        customPurpose: {
            consents: {},
            legitimateInterests: {},
        },
        restrictions: {},
    },
};

const removeByKey = (widgets, key) => {
    return widgets.filter(w => w.key !== key);
};

const widgetsReorg = (widgets, trackingType, cookies, localStorage) => {
    let resWidgets = widgets;

    if (!localStorage && !cookies) {
        if (trackingType === 'local_storage') {
            resWidgets = removeByKey(widgets, 3);
        }

        if (trackingType === 'cookie') {
            resWidgets = removeByKey(widgets, 4);
        }
    } else if (!cookies) {
        resWidgets = removeByKey(widgets, 3);
    } else if (!localStorage) {
        resWidgets = removeByKey(widgets, 4);
    }

    return resWidgets;
};

const AdTraceReducer = (state = InitialState, action) => {
    switch (action.type) {
        case AD_TRACE_DOM_INSTANCE_START:
            return { ...state, isAdTraceLoading: true };
        case SET_AD_TRACE_DOM_INSTANCE:
            let domInstanceResponse = action.domInstance;
            let issueType =
                domInstanceResponse.spec_param &&
                domInstanceResponse.spec_param.spec &&
                domInstanceResponse.spec_param.spec.name;
            let consentDetails = domInstanceResponse.consent_mismatch_details;
            let consentMisType =
                consentDetails &&
                consentDetails.consent &&
                consentDetails.consent.origin;
            let consentString =
                consentDetails &&
                consentDetails.consent &&
                consentDetails.consent.consent_string;

            let sessionData = domInstanceResponse.ip;
            let url = domInstanceResponse.url;
            let cmpData = null;
            let trackingType = consentDetails && consentDetails.tracking_type;
            let tracking = consentDetails && consentDetails.tracking;
            let cookies = tracking && tracking.cookie;
            let localStorage = tracking && tracking.local_storage;
            let copyAdTraceCMWidgets = Object.assign(
                {},
                state.adTraceCMWidgets
            );

            if (consentMisType) {
                let copyArr = Array.from(
                    copyAdTraceCMWidgets['left'][consentMisType]
                );

                copyAdTraceCMWidgets['left'][consentMisType] = widgetsReorg(
                    copyArr,
                    trackingType,
                    cookies,
                    localStorage
                );
            }
            if (consentMisType === 'gdpr') {
                let data =
                    (consentDetails &&
                        consentDetails.consent &&
                        consentDetails.consent.tcf_data) ||
                    (domInstanceResponse &&
                        domInstanceResponse.impression_data &&
                        domInstanceResponse.impression_data.cmpData);

                if (data && typeof data === 'object') {
                    let tcfKeys = Object.keys(tcfData);

                    tcfKeys.forEach(k => {
                        tcfData[k] = data[k] || null;
                    });

                    cmpData = { 'Consent string': { ...tcfData } };
                }
            }

            // Magnite specific: remove Magnite from the list of providers/dom chain participants
            if (
                domInstanceResponse.spec_param &&
                domInstanceResponse.spec_param.spec &&
                domInstanceResponse.spec_param.spec.category === 'privacy'
            ) {
                // if Magnite is SSP provider
                if (
                    domInstanceResponse.providers.ssp &&
                    domInstanceResponse.providers.ssp.name &&
                    domInstanceResponse.providers.ssp.name.toLowerCase() ===
                        'magnite'
                ) {
                    // remove Magnite from the list of providers
                    domInstanceResponse.providers.ssp = null;
                }

                // if Magnite is in the list of dom chain participants, filter it out
                if (domInstanceResponse.dom_chain) {
                    domInstanceResponse.dom_chain = domInstanceResponse.dom_chain.filter(
                        participant => participant.toLowerCase() !== 'magnite'
                    );
                }
            }

            return {
                ...state,
                domInstanceResponse,
                isAdTraceLoading: false,
                isConsentMismatch: issueType === 'consent_mismatch',
                consentDetails,
                sessionData,
                url,
                consentMisType,
                cmpData,
                consentString,
                trackingType,
                cookies,
                localStorage,
                adTraceCMWidgets: copyAdTraceCMWidgets,
                gpc:
                    domInstanceResponse &&
                    domInstanceResponse.impression_data &&
                    domInstanceResponse.impression_data.gpc,
            };
        default:
            return state;
    }
};

export default AdTraceReducer;
