import React from 'react';
import CreativeReportTabsShared from '../CreativeReportTabsShared';

export const CreativeReportTabs = props => {
    const tabs = [
        'creativeInsightsSSP',
        'harContent',
        'threatInfo',
        'landingPage',
    ];

    return <CreativeReportTabsShared tabs={tabs} {...props} />;
};
