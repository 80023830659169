import React from 'react';
import DomChain from './DomChain';
import { getFormattedDate, IssueDetails } from '../../utils/utils';
import TooltipComponent from '../shared/TooltipComponent';

class InfoBar extends React.Component {
    constructor(props) {
        super(props);

        this.getPlacement = this.getPlacement.bind(this);
        this.getPageURL = this.getPageURL.bind(this);
        this.getTrimmedData = this.getTrimmedData.bind(this);
    }

    getPlacement(impression_data) {
        if (!(impression_data.ast || impression_data.prebid)) {
            return null;
        }

        if (
            !(
                (impression_data.ast && impression_data.ast.s) ||
                (impression_data.prebid && impression_data.prebid.s)
            )
        ) {
            return null;
        }

        let placement = impression_data.ast.s || impression_data.prebid.s;

        return (
            <tr>
                <td className="adtrace-impression-title ast">Placement</td>
                {this.getTrimmedData(placement)}
            </tr>
        );
    }

    getPageURL(impression_data) {
        if (!(impression_data && impression_data.url)) {
            return null;
        }

        let pageURL = impression_data.url;

        return (
            <div className="adtrace-impression-content p-url">
                <span>{pageURL}</span>
            </div>
        );
    }

    getTrimmedData(data) {
        return (
            <td className="adtrace-impression-content long-text">
                <TooltipComponent
                    key={`adtrace-impression-content-${data}`}
                    placement="right"
                    copyAct={true}
                >
                    {data}
                </TooltipComponent>
            </td>
        );
    }

    render() {
        const { response } = this.props;
        const impression_data = response.impression_data || null;
        const domChain = response.dom_chain || null;
        const providers = response.providers || null;
        let formatted_date = null;

        if (response && response.dom_instance && response.dom_instance.date) {
            formatted_date = getFormattedDate(
                response.dom_instance.date,
                'ddd, DD MMM YYYY [at] HH:mm:ss [EST]',
                null
            );
        } else formatted_date = 'No Date Found';

        return (
            <div className="panel">
                {response.dom_instance && response.dom_instance.origin_type ? (
                    <div className="flex flex-row">
                        <h2 className="title">
                            <span>Issue Detected</span>
                        </h2>
                        {response.spec_param ? (
                            <div className="bottom-bordered d-flex flex-row">
                                {response.spec_param.spec
                                    ? IssueDetails(
                                          response.spec_param.spec.category,
                                          null,
                                          true
                                      )
                                    : null}
                                {response.spec_param.spec ? (
                                    <div className="d-flex flex-column issue-detect-info ml-2">
                                        <span>{`${response.spec_param.spec.category.toUpperCase()} ISSUE`}</span>
                                        <span>
                                            {
                                                response.spec_param.spec
                                                    .human_name
                                            }
                                        </span>
                                        {response.spec_param.param ? (
                                            <span>
                                                ({response.spec_param.param})
                                            </span>
                                        ) : null}
                                    </div>
                                ) : null}

                                {response.false_positive ? (
                                    <span className="warn-badge">
                                        false positive
                                    </span>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                ) : null}

                {response.domain ? (
                    <React.Fragment>
                        <h2 className="title">Domain</h2>
                        <section className="bottom-bordered">
                            <span>{response.domain}</span>
                            {response.dom_instance.origin_type ===
                            'provider_security'
                                ? ' (unsafe domain)'
                                : null}
                            {response.dom_instance.origin_type ===
                            'provider_ibv'
                                ? ' (video (arbitrage))'
                                : null}
                            {response.dom_instance.origin_type === 'unsafejs'
                                ? ' (unsafe JS)'
                                : null}
                            {response.false_positive ? (
                                <span className="warn-badge">
                                    false positive
                                </span>
                            ) : null}
                            {response.dom_instance.origin_type === 'spec_domain'
                                ? ' (banned domain)'
                                : null}
                            {response.dom_instance.origin_type ===
                            'provider_hrap'
                                ? ' (High Risk Ad Platform)'
                                : null}
                        </section>
                    </React.Fragment>
                ) : null}

                {formatted_date ? (
                    <React.Fragment>
                        <h2 className="title">Date</h2>
                        <section className="bottom-bordered">
                            {formatted_date}
                        </section>
                    </React.Fragment>
                ) : null}

                {(impression_data && impression_data.dfp && (
                    <React.Fragment>
                        {impression_data.dfp.ad ||
                        impression_data.dfp.o ||
                        impression_data.dfp.u ||
                        impression_data.dfp.l ? (
                            <React.Fragment>
                                <h2 className="title">
                                    <span>Ad serving context</span>
                                </h2>
                                <section
                                    id="ad-serving-context"
                                    className="bottom-bordered"
                                >
                                    <table>
                                        <tbody>
                                            {impression_data.dfp.ad ? (
                                                <tr>
                                                    <td className="adtrace-impression-title">
                                                        Advertiser ID
                                                    </td>
                                                    <td className="adtrace-impression-content">
                                                        {impression_data.dfp.ad}
                                                    </td>
                                                </tr>
                                            ) : null}
                                            {impression_data.dfp.o ? (
                                                <tr>
                                                    <td className="adtrace-impression-title">
                                                        Order ID
                                                    </td>
                                                    <td className="adtrace-impression-content">
                                                        {impression_data.dfp.o}
                                                    </td>
                                                </tr>
                                            ) : null}
                                            {impression_data.dfp.u ? (
                                                <tr>
                                                    <td className="adtrace-impression-title">
                                                        Ad Unit
                                                    </td>
                                                    <td className="adtrace-impression-content">
                                                        {impression_data.dfp.u}
                                                    </td>
                                                </tr>
                                            ) : null}
                                            {impression_data.dfp.l ? (
                                                <tr>
                                                    <td className="adtrace-impression-title">
                                                        Line Item
                                                    </td>
                                                    <td className="adtrace-impression-content">
                                                        {impression_data.dfp.l}
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </section>
                            </React.Fragment>
                        ) : null}
                    </React.Fragment>
                )) ||
                    null}

                {impression_data &&
                impression_data.ast &&
                (impression_data.ast.ast_b || impression_data.ast.ast_c) ? (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Impression Source (DSP)</span>
                        </h2>
                        <section
                            id="ad-serving-context"
                            className="bottom-bordered"
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="adtrace-impression-title ast">
                                            Source
                                        </td>
                                        <td className="adtrace-impression-content">
                                            Xandr
                                        </td>
                                    </tr>
                                    {impression_data.ast.ast_b ? (
                                        <tr>
                                            <td className="adtrace-impression-title ast">
                                                Member ID
                                            </td>
                                            <td className="adtrace-impression-content">
                                                {impression_data.ast.ast_b}
                                            </td>
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.ast_c ? (
                                        <tr>
                                            <td className="adtrace-impression-title ast">
                                                Creative ID
                                            </td>
                                            <td className="adtrace-impression-content long-text">
                                                {impression_data.ast.ast_c}
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </section>
                    </React.Fragment>
                ) : providers && providers.dsp ? (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Impression Source (DSP)</span>
                        </h2>
                        <section
                            id="ad-serving-context"
                            className="bottom-bordered"
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="adtrace-impression-title ast">
                                            Source
                                        </td>
                                        <td className="adtrace-impression-content">
                                            {providers.dsp.name}
                                        </td>
                                    </tr>
                                    {providers.dsp.ref ? (
                                        <tr>
                                            <td className="adtrace-impression-title ast">
                                                Creative ID
                                            </td>
                                            {this.getTrimmedData(
                                                providers.dsp.ref
                                            )}
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </section>
                    </React.Fragment>
                ) : null}

                {impression_data &&
                impression_data.ast &&
                impression_data.ast.crs_crid ? (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Provider (SSP)</span>
                        </h2>
                        <section
                            id="ad-serving-context"
                            className="bottom-bordered"
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="adtrace-impression-title ast">
                                            Source
                                        </td>
                                        <td className="adtrace-impression-content">
                                            Verizon Media
                                        </td>
                                    </tr>
                                    {impression_data.ast.dsp_id ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                DSP ID
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.dsp_id
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.dsp_crid ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                DSP CRID
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.dsp_crid
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.crs_crid ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                Crs CRID
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.crs_crid
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.kvmsft_pagetype ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                kvmsft_pagetype
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast
                                                    .kvmsft_pagetype
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.kvmn ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                kvmn
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.kvmn
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.kvpg ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                kvpg
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.kvpg
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data.ast.adomain ? (
                                        <tr>
                                            <td className="adtrace-impression-title">
                                                adomain
                                            </td>
                                            {this.getTrimmedData(
                                                impression_data.ast.adomain
                                            )}
                                        </tr>
                                    ) : null}
                                    {this.getPlacement(impression_data)}
                                </tbody>
                            </table>
                        </section>
                    </React.Fragment>
                ) : providers && providers.ssp ? (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Provider (SSP)</span>
                        </h2>
                        <section
                            id="ad-serving-context"
                            className="bottom-bordered"
                        >
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="adtrace-impression-title ast">
                                            Source
                                        </td>
                                        <td className="adtrace-impression-content">
                                            {providers.ssp.name}
                                        </td>
                                    </tr>
                                    {providers.ssp.ref ? (
                                        <tr>
                                            <td className="adtrace-impression-title ast">
                                                Creative ID
                                            </td>

                                            {this.getTrimmedData(
                                                providers.ssp.ref
                                            )}
                                        </tr>
                                    ) : null}
                                    {impression_data
                                        ? this.getPlacement(impression_data)
                                        : null}
                                </tbody>
                            </table>
                        </section>
                    </React.Fragment>
                ) : null}

                {impression_data && impression_data.url ? (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Page URL</span>
                        </h2>

                        <section
                            id="page-url"
                            className="bottom-bordered adtrace-impression-title ast"
                        >
                            {this.getPageURL(impression_data)}
                        </section>
                    </React.Fragment>
                ) : null}

                {(domChain && domChain.length > 0 && (
                    <React.Fragment>
                        <h2 className="title">
                            <span>Participants in Ad Chain</span>
                        </h2>

                        <section id="dom-chain" className="adtrace-call-chain">
                            <DomChain domChain={domChain} />
                        </section>
                    </React.Fragment>
                )) ||
                    null}
            </div>
        );
    }
}

export default InfoBar;
