import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Main from './component/Navigations/Main';
import Loader from './component/Loader';
import BlockUi from './component/shared/BlockUI';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { sleep } from './utils/utils';
import { unregister } from './registerServiceWorker';

import './scss/style.scss';

unregister();

export class App extends Component {
    static propTypes = {
        isApiCallInProgress: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);

        this.managePendo = this.managePendo.bind(this);
        if (
            !window.location.href.includes('appdev') &&
            !window.location.href.includes('preprod')
        ) {
            Sentry.init({
                dsn:
                    'https://c4fa6a087dbe41b8acaaa38990c92a1f@o1170723.ingest.sentry.io/6264519',
                integrations: [new BrowserTracing()],
                environment: process.env.NODE_ENV,

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
                ignoreErrors: [
                    'ResizeObserver loop limit exceeded',
                    'ResizeObserver loop completed with undelivered notifications.',
                    'Non-Error exception captured',
                    'Non-Error promise rejection captured',
                ],
                denyUrls: [
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                ],
            });
        }
    }

    async managePendo(retries = 0) {
        const { auth = null, settings = null } = this.props;
        const { pathname } = this.props.location;

        // first, we need to make sure that pendo is loaded into the window element
        if (window.pendo && window.pendo.flushNow) {
            let isAuthed = !!(
                auth &&
                auth.isLoggedIn &&
                !auth.isImpersonating &&
                settings &&
                settings.settings.email
            );
            let isOnAuthedPage = !['/login', '/reset'].includes(pathname);

            if (isAuthed && isOnAuthedPage) {
                let config = {
                    visitor: {
                        id: settings.settings.email,
                        email: settings.settings.email,
                        full_name: settings.settings.company,
                        tags: [
                            { role: [settings.settings.account_type] },
                            {
                                trial: [
                                    settings.settings.trial_end_date || 'False',
                                ],
                            },
                        ],
                        role: settings.settings.account_type,

                        company: settings.settings.company,
                        trial: settings.settings.trial_end_date || 'False',
                    },
                    account: {
                        id:
                            settings.settings.company ||
                            settings.settings.email,
                        name: settings.settings.company,
                    },
                };
                if (!window.pendo.isReady()) {
                    window.pendo.initialize(config);
                } else if (
                    window.pendo.getVisitorId() !== settings.settings.email
                ) {
                    window.pendo.flushNow(
                        () => {
                            window.pendo.identify(config);
                        },
                        e => {
                            window.pendo.identify(config);
                        }
                    );
                }
                return true;
            } else {
                window.pendo.flushNow();
            }
            return false;
        } else if (retries < 6) {
            await sleep(500);
            return this.managePendo(++retries);
        }
        return false;
    }

    render() {
        this.managePendo();

        return (
            <BlockUi
                tag="div"
                blocking={this.props.isApiCallInProgress}
                loader={<Loader active color="#02a17c" />}
            >
                <Main />
            </BlockUi>
        );
    }
}

const mapStateToProps = state => {
    return {
        isApiCallInProgress: state.apiCallStatus.isApiCallInProgress,
        auth: state.authReducer,
        settings: state.settings,
    };
};

export default withRouter(connect(mapStateToProps, null)(App));
