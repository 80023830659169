import {
    // #if process.env.BUILD_SECURE === '1'
//     LOAD_ALERT_LOG_REPORT_SUCCESS,
//     BLOCK_ALERT_LOG_REPORT_SUCCESS,
//     RESET_CREATIVE_REPORT,
//     CREATIVE_BLOCK_STATUS_SUCCESS,
    // #endif
    LOAD_SCAN_LOG_REPORT_SUCCESS,
} from '../actions/ActionTypes';
import { sortArrayOnName } from '../utils/utils';

const tresholdMap = {
    alcohol: 85,
    violence: 85,
    weapon: 85,
    drugs: 85,
    hate_symbols: 85,
    visually_disturbing: 85,
    gambling: 90,
    tobacco: 90,
    nudity: 90,
    cannabis: 90,
};

const getSensitiveImageryTreshold = (category, val) => {
    return val >= tresholdMap[category];
};

export default function AlertLogReportReducer(state = {}, action) {
    switch (action.type) {
        // #if process.env.BUILD_SECURE === '1'
//         case CREATIVE_BLOCK_STATUS_SUCCESS:
//             return { ...state, blocking_status: action.blocking_status };
//         case RESET_CREATIVE_REPORT:
//             return {};
//         case BLOCK_ALERT_LOG_REPORT_SUCCESS:
//             return { ...state, status: action.status };
//         case LOAD_ALERT_LOG_REPORT_SUCCESS:
//             let networkLoad =
//                 !!action.alertLogReport.network_load &&
//                 action.alertLogReport.network_load.length > 1
//                     ? action.alertLogReport.network_load.filter(
//                           n => n.label === 'Total'
//                       )
//                     : undefined;
//             let hostCount = action.alertLogReport.hosts_count;
//             return { ...action.alertLogReport, networkLoad, hostCount };
        // #endif
        case LOAD_SCAN_LOG_REPORT_SUCCESS:
            let { network_load } = action.scanLogReport;
            let netLoad = [];

            if (network_load && !network_load.hasOwnProperty('hosts_count')) {
                let labelSet = new Set([
                    ...Object.keys(network_load.initial),
                    ...Object.keys(network_load.polite),
                ]);
                let labels = [...labelSet];

                labels.forEach(label => {
                    let p = !network_load.polite[label]
                        ? 0
                        : network_load.polite[label];
                    let i = !network_load.initial[label]
                        ? 0
                        : network_load.initial[label];
                    let t = i + p;
                    netLoad.push({
                        label: label,
                        initial: i,
                        polite: p,
                        total: t,
                    });
                });

                netLoad.sort((a, b) => {
                    return a.total > b.total ? 1 : -1;
                });
            } else {
                netLoad = network_load;
            }

            let textContent = action.scanLogReport.text;
            let links = null,
                audioTranscription = null,
                textOcr = null;
            if (textContent) {
                if (textContent.ocr && textContent.ocr !== '') {
                    textOcr = textContent.ocr.split('\n');
                }
                if (textContent.links && textContent.links !== '') {
                    links = textContent.links.split('\n');
                }
                if (
                    textContent.audio_transcription &&
                    textContent.audio_transcription !== ''
                ) {
                    audioTranscription = textContent.audio_transcription;
                }
            }

            let sensitiveImagery = action.scanLogReport.sensitive_imagery;
            if (
                sensitiveImagery &&
                Array.isArray(sensitiveImagery) &&
                sensitiveImagery.length
            ) {
                sensitiveImagery = sensitiveImagery.filter(s => {
                    return getSensitiveImageryTreshold(s.name, s.score);
                });
                sensitiveImagery = sensitiveImagery.map(obj => ({
                    ...obj,
                    name: obj.name
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, match => match.toUpperCase()),
                }));

                sensitiveImagery = sortArrayOnName(sensitiveImagery);
            }
            return {
                ...action.scanLogReport,
                networkLoad: netLoad,
                links,
                textOcr,
                audioTranscription,
                sensitiveImagery: Array.isArray(sensitiveImagery)
                    ? sensitiveImagery.length
                        ? sensitiveImagery
                        : []
                    : null,
            };
        default:
            return state;
    }
}
