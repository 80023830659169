// Needs to be in unsecure build.

import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import {
    logInUser,
    getUsers,
    passwordExpired,
} from '../../actions/AuthActions';
import { generateKnowledgeOwlToken } from '../../api/AuthApi';
import {
    FormWithConstraints,
    FieldFeedbacks,
    FieldFeedback,
    Input,
} from 'react-form-with-constraints';
import { CompanyNavigations } from '../Navigations/CompanyNavigations';
import { endApiCall } from '../../actions/ApiCallStatusActions';
import MobileHeaderBeforeLogin from '../Navigations/MobileHeaderBeforeLogin';
import { getToastr } from '../../utils/utils';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            isAdmin: false,
            selectVal: null,
            isDoc: null,
            redirectUrl: '/',
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.storeRedirectUrl = this.storeRedirectUrl.bind(this);
    }

    timer = null;

    // #if process.env.BUILD_LOCAL !== '1'
    componentDidMount() {
        let { pathname, search } = this.props.location;
        let from = pathname !== '/login' ? pathname + search : '/';
        this.storeRedirectUrl(from);
    }
    // #endif

    componentDidUpdate(prevProps) {
        if (prevProps.isExpired !== this.props.isExpired) {
            if (this.props.isExpired) {
                document
                    .getElementById('password')
                    .setAttribute('isvalid', 'false');
            }
        }
    }

    handleChange = async e => {
        document.getElementById('password').setAttribute('isvalid', 'true');
        this.props.passwordExpired(false);
        await this.loginForm.validateFields(e.target);
    };

    getList = () => {
        return this.props.searchResult || [];
    };

    loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            callback(this.getList());
        }, 1000);
    };

    handleInputChange = inputValue => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            if (inputValue.length >= 3) {
                this.props.getUsersList(inputValue);
            }
        }, 500);
    };

    handleSelectChange = value => {
        if (!value) {
            this.setState({ selectVal: value });
        } else {
            this.setState({ selectVal: value.value });
        }
    };

    storeRedirectUrl(url) {
        let redirectUrl = sessionStorage.getItem('redirect_url');

        if (!redirectUrl || redirectUrl !== url) {
            sessionStorage.setItem('redirect_url', url);
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        const target = e.target;
        await this.loginForm.validateForm();
        const formIsValid = this.loginForm.isValid();
        if (formIsValid && !this.props.isExpired) {
            let data = {
                email: target.email.value,
                password: target.password.value,
                is_remembered: target.is_remembered.checked,
            };

            if (this.state.isAdmin && 'login_As' in target) {
                data['login_as'] =
                    this.state.selectVal || this.state.selectVal !== ''
                        ? this.state.selectVal
                        : null;
            }
            this.props
                .logInUser(data, !!data['login_as'])
                .then(async data => {
                    let url = new URL(window.location.href);
                    let rParam = url.searchParams.get('r');
                    if (rParam) {
                        let rHostName = new URL(rParam).hostname;
                        if (rHostName === 'docs.confiant.com') {
                            const k = await generateKnowledgeOwlToken();

                            if (k.success) {
                                let token = k.data;
                                this.setState({
                                    isDoc: `https://docs.confiant.com/help/remote-auth?n=${token}&r=${rParam}`,
                                });
                            }
                        }
                    }
                    if (
                        ((data && data.data && data.data.is_admin) || null) &&
                        !this.state.isAdmin
                    ) {
                        this.setState({ isAdmin: true });
                        this.props.endApiCall();
                    } else {
                        this.setState({ redirectToReferrer: true });
                    }
                })
                .catch(error => {
                    if (!this.props.isExpired) {
                        getToastr('error', error.message);
                    }
                });
        }
    }

    render() {
        const { redirectToReferrer, isAdmin, isDoc } = this.state;

        if (isDoc && redirectToReferrer) {
            window.location.href = isDoc;
        }

        // #if process.env.BUILD_LOCAL === '1'
//         let from =
//             this.props.location.state && this.props.location.state.redirectTo
//                 ? this.props.location.state.redirectTo
//                 : '/';
// 
//         if (redirectToReferrer && !isDoc) {
//             return <Redirect to={from} />;
//         }
        // #endif

        let loginAsField = isAdmin ? (
            <div className="form-group row row-form-mb">
                <label
                    htmlFor="login_As"
                    className="col-lg-2 col-md-3 col-form-label"
                >
                    Login As
                </label>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <AsyncSelect
                        inputId="login_As"
                        cacheOptions={true}
                        loadOptions={this.loadOptions}
                        onInputChange={this.handleInputChange}
                        isClearable={true}
                        onChange={value => this.handleSelectChange(value)}
                    />
                </div>
            </div>
        ) : (
            ''
        );

        return (
            <div className="container-fluid no-padding--left mlr-xs-none login-page">
                <CompanyNavigations />

                <MobileHeaderBeforeLogin />

                <div className="pt-3 mb-3">
                    <div className="dropdown dropdown-big">&nbsp;</div>
                </div>
                <div className="common-container">
                    <FormWithConstraints
                        onSubmit={this.handleSubmit}
                        ref={form => (this.loginForm = form)}
                        noValidate
                    >
                        <div className="form-group row row-form-mb">
                            <label
                                htmlFor="email"
                                className="col-lg-2 col-md-3 col-form-label"
                            >
                                <font color="red">*</font>Email
                            </label>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <Input
                                    type="email"
                                    required
                                    onChange={this.handleChange}
                                    readOnly={isAdmin}
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter email address"
                                />
                                <FieldFeedbacks for="email">
                                    <FieldFeedback when="valueMissing">
                                        Email is required.
                                    </FieldFeedback>
                                    <FieldFeedback when="*" />
                                </FieldFeedbacks>
                            </div>
                        </div>

                        <div className="form-group row row-form-mb">
                            <label
                                htmlFor="password"
                                className="col-lg-2 col-md-3 col-form-label"
                            >
                                <font color="red">*</font>Password
                            </label>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <Input
                                    type="password"
                                    required
                                    onChange={this.handleChange}
                                    readOnly={isAdmin}
                                    id="password"
                                    name="password"
                                    placeholder="Enter Password"
                                    className={`${
                                        this.props.isExpired
                                            ? 'form-control is-invalid'
                                            : 'form-control'
                                    }`}
                                />
                                <FieldFeedbacks for="password">
                                    <FieldFeedback when="valueMissing">
                                        Password is required.
                                    </FieldFeedback>
                                </FieldFeedbacks>
                                {this.props.isExpired && (
                                    <span
                                        key={2}
                                        className="invalid-feedback"
                                        style={{ display: 'block' }}
                                    >
                                        Your password has expired. Please check
                                        your email for instructions on updating
                                        it.
                                    </span>
                                )}
                            </div>
                        </div>

                        {loginAsField}

                        <div className="form-group row row-form-mb">
                            <label className="col-lg-2 col-md-3 col-form-label"></label>
                            <div className="col-md-5">
                                <label htmlFor="is_remembered">
                                    <Input
                                        type="checkbox"
                                        onChange={this.handleChange}
                                        id="is_remembered"
                                        name="is_remembered"
                                        style={{ verticalAlign: 'middle' }}
                                    />{' '}
                                    Keep me signed in for a week
                                </label>
                            </div>
                        </div>

                        <div className="form-group row row-form-mb">
                            <label className="col-lg-2 col-md-3 col-form-label"></label>
                            <div className="col-md-5">
                                <button
                                    type="submit"
                                    className="btn btn-secondary mr-4"
                                >
                                    {isAdmin ? 'CONTINUE' : 'LOG IN'}
                                </button>
                                <a href="/reset">Reset Password</a>
                            </div>
                        </div>
                        <div className="form-group row row-form-mb">
                            <label className="col-lg-2 col-md-3 col-form-label"></label>
                            <div className="col-md-5 qa-login">
                                <p>
                                    Log in{' '}
                                    <a
                                        href="https://clients.confiant.com"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>{' '}
                                    if you use AutoQA or AutoMonitor
                                </p>
                            </div>
                        </div>
                    </FormWithConstraints>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        searchResult: state.authReducer.searchResult,
        isExpired: state.authReducer.isExpired,
        isDoc: state.authReducer.isDoc,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        passwordExpired: isExpired => dispatch(passwordExpired(isExpired)),
        logInUser: (credentials, isImpersonating) =>
            dispatch(logInUser(credentials, isImpersonating)),
        endApiCall: () => dispatch(endApiCall()),
        getUsersList: search => dispatch(getUsers(search)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
