// Needs to be in unsecure build.

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import Raven from "raven-js";
import createRavenMiddleware from "raven-for-redux";

const composeEnhancers = composeWithDevTools({});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(
    thunk,
    createRavenMiddleware(Raven, {
      breadcrumbDataFromAction: action => {
        return { STRING: action.str };
      }
    })))
);

export default store;