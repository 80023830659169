// Needs to be in unsecure build.

import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    HIDE_COMPANY_NAME,
    USER_LIST_SUCCESS,
    EXPIRED_PASSWORD,
    ACTIVATE_DEMO_MODE,
} from './ActionTypes';
import { login, logout, getUsersList } from '../api/AuthApi';
import { beginApiCall, apiCallError } from './ApiCallStatusActions';
import history from '../component/Navigations/History';

export const loginSuccess = (
    data,
    isImpersonating = false,
    role = null,
    isSubUser = false,
    isReadOnly = false
) => ({
    type: LOGIN_SUCCESS,
    data: data,
    isImpersonating: isImpersonating,
    role,
    isSubUser,
    isReadOnly,
});

export const passwordExpired = passExpired => ({
    type: EXPIRED_PASSWORD,
    isExpired: passExpired,
});

export const logInUser = (credentials, isImpersonating = false) => {
    // we need to know if this is the last login attempt to properly refresh
    const isAdminComplete = !!('login_as' in credentials);
    // if the value is empty, we need to clear it out for login
    if ('login_as' in credentials && !credentials.login_as) {
        delete credentials['login_as'];
    }
    return dispatch => {
        dispatch(beginApiCall());
        return login(credentials)
            .then(async json => {
                if (json.success) {
                    // if this isn't an admin or we already submitted the spoof
                    // login we need to do a hard refresh to update source code
                    // #if process.env.BUILD_LOCAL !== '1'
                    if (!json.is_admin || (json.is_admin && isAdminComplete)) {
                        let rHostName = null;
                        let url = new URL(window.location.href);
                        let rParam = url.searchParams.get('r');
                        if (rParam) {
                            rHostName = new URL(rParam).hostname;
                        }

                        if (!rHostName || rHostName !== 'docs.confiant.com') {
                            history.push('/');
                            window.location.reload();
                        }
                    }
                    // #endif
                    return dispatch(loginSuccess(json, isImpersonating));
                } else if (json.expired_password) {
                    dispatch(passwordExpired(true));
                    dispatch(apiCallError());
                }
                throw Error(json.message);
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};

export const logoutSuccess = () => ({
    type: LOGOUT_SUCCESS,
});

export const logOutUser = () => {
    return dispatch => {
        return logout()
            .then(json => {
                if (json.success) {
                    history.push('/login');
                    return dispatch(logoutSuccess());
                } else {
                    throw Error(json.message);
                }
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};

export const hideCompanyName = () => ({
    type: HIDE_COMPANY_NAME,
});

export const activateDemo = () => ({
    type: ACTIVATE_DEMO_MODE,
});

export const userListSuccess = json => ({
    type: USER_LIST_SUCCESS,
    json,
});

export const getUsers = search => {
    return dispatch => {
        return getUsersList(search)
            .then(json => {
                if (json.success) {
                    return dispatch(userListSuccess(json));
                    // #endif
                } else {
                    throw Error(json.message);
                }
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};
