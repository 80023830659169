import {
    DATE_RANGE_PICKER_FORMAT_TO,
    DATE_RANGE_PICKER_FORMAT_FROM,
} from '../../constants/CommonConstants';
import { getFormattedDate } from '../../utils/utils';

export const getDateRange = (from, to) => {
    const fromDate = getFormattedDate(
            from,
            'MMM Do',
            DATE_RANGE_PICKER_FORMAT_FROM
        ),
        toDate = getFormattedDate(to, 'MMM Do', DATE_RANGE_PICKER_FORMAT_TO);
    return `${fromDate} - ${toDate}`;
};

export const createListOfSensitiveImagery = data => {
    return (
        <ul className="no-list-style control-addproperty">
            {data.map((d, index) => {
                return (
                    <li key={index} className="pb-1">
                        {d.name.replace(/\b\w/g, l => l.toUpperCase())} (
                        {convertNumtoPerc(d.score)})
                    </li>
                );
            })}
        </ul>
    );
};

export const convertNumtoPerc = num => {
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
    }).format(num / 100);
};
