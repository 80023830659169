import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { getFormattedNumber } from '../../utils/utils';

const CPMDistribution = ({ cpm }) => {
    let cpmQuartz = [cpm[1], cpm[2], cpm[3]];
    let maxCPM = Math.max(...cpmQuartz);

    let chartOptions = {
        chart: {
            type: 'column',
            alignTicks: false,
            height: 250,
            spacingLeft: -10,
        },
        xAxis: {
            categories: [`25th`, `50th`, `75th`],
            labels: {
                enabled: true,
            },
            title: {
                text: 'Percentile',
            },
        },
        yAxis: {
            min: 0,
            max: maxCPM,
            tickInterval: maxCPM / 4,
            endOnTick: false,
            minPadding: 0.5,
            maxPadding: 0,
            labels: {
                enabled: true,
                formatter: function() {
                    if (this.value === 0) {
                        return '';
                    }
                    return getFormattedNumber(this.value, `$0.00`);
                },
            },
        },
        series: [
            {
                showInLegend: false,
                data: cpmQuartz,
                color: '#ED1941',
            },
        ],
        credits: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
    };

    return (
        <div className="chart-wrapper" style={{ textTransform: 'none' }}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default CPMDistribution;
