import React from 'react';

const ConsentDetailHeader = ({ title, id, data, collapsible, handleClick }) => {
    if (data) {
        let d = data === 'cookie' ? data + 's' : data;
        return (
            <div className="d-flex flexrow special-header">
                <h2
                    className={`title consent-detail-header ${
                        collapsible ? 'collapsible' : ''
                    }`}
                    key={`${title}-${id}-header`}
                    onClick={() => handleClick()}
                >
                    {title}
                </h2>{' '}
                <h2 className="title consent-detail-header appendix">{d}</h2>{' '}
            </div>
        );
    }
    return (
        <h2
            className={`title consent-detail-header ${
                collapsible ? 'collapsible' : ''
            }`}
            key={`${title}-${id}-header`}
            onClick={() => handleClick()}
        >
            {title}
        </h2>
    );
};

export default ConsentDetailHeader;
