// Needs to be in unsecure build.
import moment from 'moment-timezone';

import { getAbortSignal } from '../api/ApiCommon';
import { apiCallError } from './ApiCallStatusActions';

const timezone = 'America/New_York';

export const dispatchApi = (
    onStart,
    request,
    onSuccess,
    onCleanup = () => {},
    onError = () => {}
) => {
    return dispatch => {
        dispatch(onStart());

        return request(getAbortSignal())
            .then(json => {
                if (!json) {
                    return;
                } else if (json.message === '401 Unauthorized') {
                    return;
                }

                return onSuccess(dispatch, json);
            })
            .then(data => {
                onCleanup(dispatch);

                return data;
            })
            .catch((error) => {
                let result = dispatch(apiCallError());

                onCleanup(dispatch);
                onError(dispatch);

                return result;
            });
    };
};

export const constructDateExpire = date_expires_raw => {
    let raw = new moment(date_expires_raw).tz(timezone, true);
    let now = new moment().tz(timezone);

    let date_expire_hour = Math.round(moment.duration(raw.diff(now)).asHours());

    if (date_expires_raw && date_expire_hour > 1) {
        return `${date_expire_hour} hours`;
    }

    if (date_expires_raw && date_expire_hour === 1) {
        return `${date_expire_hour} hour`;
    }

    let date_expire_minute = Math.round(
        moment.duration(raw.diff(now)).asMinutes()
    );

    if (date_expires_raw && date_expire_minute > 1) {
        return `${date_expire_minute} minutes`;
    }

    return `1 minute`;
};

export const checkQueuedMins = date_queued => {
    let raw = new moment(date_queued).tz(timezone, true);
    let now = new moment().tz(timezone);

    return Math.round(moment.duration(now.diff(raw)).asMinutes());
};
