export const ApiURL = process.env.REACT_APP_API_URL;

export const AdTraceURL = `${ApiURL}/ad_trace`;
export const DfpCallbackURL = `${ApiURL}/dfp/callback`;
export const LoginURL = `${ApiURL}/auth/login`;
export const LogoutURL = `${ApiURL}/auth/logout`;
export const LoginStatusURL = `${ApiURL}/auth/status`;
export const ResetURL = `${ApiURL}/auth/reset`;
export const SetupPasswordURL = `${ApiURL}/auth/setup`;
export const UsersListURL = `${ApiURL}/users`;
export const GenerateKnowledgeOwlTokenURL = `${ApiURL}/auth/knowledgeowl_token`;
export const ScanReportByTiURL = `${ApiURL}/scan_report/share`;
export const SSPAdMarkUpURL = `${ApiURL}/ad_markup`;

// #if process.env.BUILD_SECURE === '1'
// export const DfpURL = `${ApiURL}/dfp`;
// export const DfpAuthorizeURL = `${ApiURL}/dfp/authorize`;
// export const AccountURL = `${ApiURL}/account`;
// export const AccountUsageURL = `${ApiURL}/account_usage`;
// export const SSPAdDimensionsURL = `${ApiURL}/ssp/creative_search/dimensions`;
// export const PubAdDimensionsURL = `${ApiURL}/creative_search/dimensions`;
// export const AlertLogURL = `${ApiURL}/alert/log`;
// export const AlertReportURL = `${ApiURL}/alert/report`;
// export const BlockPrefURL = `${ApiURL}/blocking/pref`;
// export const BrandsSearchURL = `${ApiURL}/brands/search`;
// export const PubCreativeSearchURL = `${ApiURL}/creative_search`;
// export const SSPCreativeSearchURL = `${ApiURL}/ssp/creative_search`;
// export const SSPCreativeSearchFeedbackURL = `${ApiURL}/ssp/creative_feedback`;
// export const ConfigurationSpecsURL = `${ApiURL}/configuration/specs`;
// export const DashboardCurrentMonitoringStatsURL = `${ApiURL}/dashboard/monitoring_stats/current`;
// export const DashboardBlockingTrendURL = `${ApiURL}/dashboard/blocking_trend`;
// export const DashboardSummaryByProviderURL = `${ApiURL}/dashboard/summary_by_provider`;
// export const DashboardSummaryByIssueURL = `${ApiURL}/dashboard/summary_by_issue`;
// export const DashboardIssueSummaryURL = `${ApiURL}/dashboard/issue_summary`;
// export const DashboardBlockedProvidersURL = `${ApiURL}/dashboard/blocked_providers`;
// export const DashboardMonitoredImpressionTrendsURL = `${ApiURL}/dashboard/monitored_impressions_trend`;
// export const DashboardTopUniqueIssuesURL = `${ApiURL}/dashboard/unique_violations`;
// export const DashboardImpressionBlocksURL = `${ApiURL}/dashboard/impression_blocks`;
// export const EmailPreferencesURL = `${ApiURL}/email_preferences`;
// export const EmailPreferenceUpdateURL = `${ApiURL}/email_preference/update`;
// export const EmailPreferencesDeleteURL = `${ApiURL}/email_preference/email`;
// export const GetCategoriesURL = `${ApiURL}/categories`;
// export const GetHrapURL = `${ApiURL}/specifications/hrap`;
// export const GetSpecsURL = `${ApiURL}/specifications`;
// export const PrivacyLogURL = `${ApiURL}/privacy/log `;
// export const PrivacyEntityURL = `${ApiURL}/privacy/entity_report `;
// export const PrivacyProvidersURL = `${ApiURL}/privacy/associated_providers`;
// export const PropertyURL = `${ApiURL}/property`;
// export const PropertiesURL = `${ApiURL}/properties`;
// export const ReportURL = `${ApiURL}/report`;
// export const ReportsURL = `${ApiURL}/reports`;
// export const SettingsURL = `${ApiURL}/settings`;
// export const SspDashboardTotalBlockedURL = `${ApiURL}/ssp/dashboard/total_blocked`;
// export const SspDashboardStatURL = `${ApiURL}/ssp/dashboard/stat`;
// export const SspDashboardDemandSourcesURL = `${ApiURL}/ssp/dashboard/demand_sources`;
// export const SSPScanLogURL = `${ApiURL}/ssp/scan/log`;
// export const ScanReportURL = `${ApiURL}/scan_report`;
// export const SSPPublisherBlockURL = `${ApiURL}/ssp/publisher_block`;
// export const BlockUnblockCreativeURL = `${ApiURL}/blocking/creative`;
// export const SelectedViewURL = `${ApiURL}/views`;
// export const WrapperURL = `${ApiURL}/wrapper/`;
// export const UserPrefsURL = `${ApiURL}/user/prefs`;
// #endif
