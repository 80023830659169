import * as actionTypes from '../actions/ActionTypes';

const InitialState = {
    resetSuccess: false,
    resetReqSuccess: false,
    resetStatusMessage: '',
};

export default function ResetPasswordReducer(state = InitialState, action) {
    switch (action.type) {
        case actionTypes.RESET_PASSWORD_REQ_SUCCESS:
            return {
                ...state,
                resetReqSuccess: true,
            };
        case actionTypes.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                resetSuccess: true,
            };
        case actionTypes.PASSWORD_RESET_FAILURE:
            return {
                ...state,
                resetSuccess: false,
                resetStatusMessage: action.message,
            };
        default:
            return state;
    }
}
