// Needs to be in unsecure build.

import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/ResetPasswordActions';
import {
    FormWithConstraints,
    FieldFeedbacks,
    FieldFeedback,
    Input,
} from 'react-form-with-constraints';
import { CompanyNavigations } from '../Navigations/CompanyNavigations';
import MobileHeaderBeforeLogin from '../Navigations/MobileHeaderBeforeLogin';

class ResetPasswordRequest extends React.Component {
    handleChange = async e => {
        await this.resetForm.validateFields(e.target);
    };

    handleSubmit = async e => {
        e.preventDefault();
        const target = e.target;
        await this.resetForm.validateForm();
        const formIsValid = this.resetForm.isValid();
        if (formIsValid) {
            this.props.sendResetPasswordRequest(target.email.value);
            target.email.value = '';
        }
    };

    render() {
        return (
            <div className="container-fluid no-padding--left mlr-xs-none">
                <CompanyNavigations />
                <main role="main">
                    <div className="page-header-out col-md-9 ml-sm-auto col-lg-10 px-4 d-none d-md-block fixed-top">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                            <h1>Reset Password</h1>
                        </div>
                    </div>
                    <MobileHeaderBeforeLogin />
                    <div className="page-header-out d-block d-md-none">
                        <div className="">
                            <h1>Reset Password</h1>
                        </div>
                    </div>
                    <div className="pt-3 mb-3 border-bottom">
                        <div className="dropdown dropdown-big">&nbsp;</div>
                    </div>

                    <div className="common-container">
                        <FormWithConstraints
                            onSubmit={this.handleSubmit}
                            ref={form => (this.resetForm = form)}
                            noValidate
                        >
                            <div className="form-group row row-form-mb">
                                <label
                                    htmlFor="email"
                                    className="col-lg-2 col-md-3 col-form-label"
                                >
                                    <font color="red">*</font>Email
                                </label>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <Input
                                        type="email"
                                        required
                                        onChange={this.handleChange}
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="Enter email address"
                                    />
                                    <FieldFeedbacks for="email">
                                        <FieldFeedback when="valueMissing">
                                            Email is required.
                                        </FieldFeedback>
                                        <FieldFeedback when="*" />
                                    </FieldFeedbacks>
                                </div>
                            </div>

                            <div className="form-group row row-form-mb pt-4">
                                <label className="col-lg-2 col-md-3 col-form-label"></label>
                                <div className="col-md-5">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary mr-4"
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </FormWithConstraints>
                    </div>
                </main>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendResetPasswordRequest: email =>
            dispatch(actions.sendResetPasswordRequest(email)),
    };
};

export default connect(null, mapDispatchToProps)(ResetPasswordRequest);
