// Needs to be in unsecure build.
export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const END_API_CALL = 'END_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const HIDE_COMPANY_NAME = 'HIDE_COMPANY_NAME';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const EXPIRED_PASSWORD = 'EXPIRED_PASSWORD';

export const RESET_PASSWORD_REQ_SUCCESS = 'RESET_PASSWORD_REQ_SUCCESS';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const VALIDATE_SETUP_PASSWORD_REQ_SUCCESS =
    'VALIDATE_SETUP_PASSWORD_REQ_SUCCESS';
export const PASSWORD_VALIDATE_SETUP_SUCCESS =
    'PASSWORD_VALIDATE_SETUP_SUCCESS';
export const PASSWORD_VALIDATE_SETUP_FAILURE =
    'PASSWORD_VALIDATE_SETUP_FAILURE';

export const AD_TRACE_DOM_INSTANCE_START = 'AD_TRACE_DOM_INSTANCE_START';
export const SET_AD_TRACE_DOM_INSTANCE = 'SET_TRACE_DOM_INSTANCE_SUCCESS';

export const LOAD_SCAN_LOG_REPORT_SUCCESS = 'LOAD_SCAN_LOG_REPORT_SUCCESS';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export const ACTIVATE_DEMO_MODE = 'ACTIVATE_DEMO_MODE';

// #if process.env.BUILD_SECURE === '1'
// export const LOAD_ACCOUNT_DETAILS = 'LOAD_ACCOUNT_DETAILS';
// export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS';
// export const SAVE_SETTINGS_SUCCESS = 'SAVE_SETTINGS_SUCCESS';
// export const SET_ACCOUNT_USAGE = 'SET_ACCOUNT_USAGE_SUCCESS';
// export const LOAD_EMAIL_PREF_START = 'LOAD_EMAIL_PREF_START';
// export const SET_EMAIL_PREFERENCES = 'SET_EMAIL_PREFERENCES';
// export const DELETE_EMAIL_PREFERENCE = 'DELETE_EMAIL_PREFERENCE';
// export const LOAD_PROPERTIES_SUCCESS = 'LOAD_PROPERTIES_SUCCESS';
// export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
// export const APPLY_FILTER_ALERT_LOG_SUCCESS = 'APPLY_FILTER_ALERT_LOG_SUCCESS';
// export const LOAD_ALERT_LOG_REPORT_SUCCESS = 'LOAD_ALERT_LOG_REPORT_SUCCESS';
// export const BLOCK_ALERT_LOG_REPORT_SUCCESS = 'BLOCK_ALERT_LOG_REPORT_SUCCESS';
// export const RESET_CREATIVE_REPORT = 'RESET_CREATIVE_REPORT';
// export const ALERT_LOG_LOADING = 'ALERT_LOG_LOADING';
// export const UPDATE_LOG_HEADERS = 'UPDATE_LOG_HEADERS';
// export const UPDATE_LOGS = 'UPDATE_LOGS';
// export const SET_PROPERTIES = 'SET_PROPERTIES';
// export const RESET_FILTERS = 'RESET_FILTERS';
// export const SET_SPECS = 'SET_SPECS';
// export const UPDATE_ALERT_LOG_FILTER = 'UPDATE_ALERT_LOG_FILTER';
// export const UPDATE_ALERT_LOG_DATE_FILTER = 'UPDATE_ALERT_LOG_DATE_FILTER';
// export const RESET_ALERT_FILTER = 'RESET_ALERT_FILTER';
// 
// export const SET_AVAILABLE_ORDERS = 'SET_AVAILABLE_ORDERS';
// export const SET_MONITORED_ORDERS = 'SET_MONITORED_ORDERS';
// export const SET_EXCLUDED_ORDERS = 'SET_EXCLUDED_ORDERS';
// export const SET_EXCLUDED_ORDERS_FAILURE = 'SET_EXCLUDED_ORDERS_FAILURE';
// export const SET_FILTERED_ORDERS = 'SET_FILTERED_ORDERS';
// export const UPDATE_SELECTED_ORDER_INDEX = 'UPDATE_SELECTED_ORDER_INDEX';
// export const UPDATE_ORDERS = 'UPDATE_ORDERS';
// export const MOVE_ORDER_TO_AVAILABLE = 'MOVE_ORDER_TO_AVAILABLE';
// export const MOVE_ORDER_TO_MONITORED = 'MOVE_ORDER_TO_MONITORED';
// export const SET_ORDER_MODIFICATION = 'SET_ORDER_MODIFICATION';
// export const CLEAR_MONITORING_FILTER = 'CLEAR_MONITORING_FILTER';
// 
// export const INIT_PASSBACK = 'INIT_PASSBACK_SUCCESS';
// export const SAVE_PASSBACK = 'SAVE_PASSBACK';
// export const UPDATE_PASSBACK = 'UPDATE_PASSBACK';
// export const DELETE_PASSBACK = 'DELETE_PASSBACK';
// 
// export const CHANGE_PROPERTY = 'CHANGE_PROPERTY';
// export const INIT_PROPERTIES = 'INIT_PROPERTIES';
// export const INIT_PROPERTIES_TO_BLANK = 'INIT_PROPERTIES_TO_BLANK_SUCCESS';
// export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES_SUCCESS';
// export const DELETE_PROPERTY = 'DELETE_PROPERTY';
// export const CHANGE_SPECS = 'CHANGE_SPECS';
// export const SSP_CHANGE_SPECS = 'SSP_CHANGE_SPECS';
// export const SHOW_SPECS = 'SHOW_SPECS_SUCCESS';
// export const RESET_SPECS = 'RESET_SPECS';
// export const UPDATE_NETWORK_LOAD_TOGGLE = 'UPDATE_NETWORK_LOAD_TOGGLE';
// export const UPDATE_HEAVINESS_SCORE_TOGGLE = 'UPDATE_HEAVINESS_SCORE_TOGGLE';
// export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';
// export const ADD_INVALID_DOMAINS = 'ADD_INVALID_DOMAINS';
// export const RESET_INVALID_DOMAINS = 'RESET_INVALID_DOMAINS';
// export const ADD_INVALID_KEYWORDS = 'ADD_INVALID_KEYWORDS';
// export const RESET_INVALID_KEYWORDS = 'RESET_INVALID_KEYWORDS';
// export const LOAD_CATEGORY_LIST = 'LOAD_CATEGORY_LIST';
// export const LOAD_BANNED_CATEGORIES = 'LOAD_BANNED_CATEGORIES';
// export const UPDATE_BANNED_CATEGORIES_TOGGLE =
//     'UPDATE_BANNED_CATEGORIES_TOGGLE';
// export const UPDATE_BANNED_BRANDS_TOGGLE = 'UPDATE_BANNED_BRANDS_TOGGLE';
// export const BANNED_BRANDS_SUCCESS = 'BANNED_BRANDS_SUCCESS';
// export const BANNED_BRANDS_START = 'BANNED_BRANDS_START';
// export const SET_ACTIVE_BANNED_RULES = 'SET_ACTIVE_BANNED_RULES';
// export const SET_QUERY_BANNED_RULES = 'SET_QUERY_BANNED_RULES';
// export const SET_ALL_BANNED_RULES = 'SET_ALL_BANNED_RULES';
// export const RESET_BANNED_BRAND_QUERY = 'RESET_BANNED_BRAND_QUERY';
// export const SET_HRAP = 'SET_HRAP_SUCCESS';
// export const BANNED_DOMAINS_START = 'BANNED_DOMAINS_START';
// export const BANNED_DOMAINS_SUCCESS = 'BANNED_DOMAINS_SUCCESS';
// export const BANNED_KEYWORDS_START = 'BANNED_KEYWORDS_START';
// export const BANNED_KEYWORDS_SUCCESS = 'BANNED_KEYWORDS_SUCCESS';
// export const BANNED_KEYWORDS_FAILURE = 'BANNED_KEYWORDS_FAILURE';
// 
// export const SET_STATIC_WRAPPER = 'SET_STATIC_WRAPPER';
// export const SET_ONPAGE_WRAPPER = 'SET_ONPAGE_WRAPPER';
// export const SET_PREBID_WRAPPER = 'SET_PREBID_WRAPPER';
// 
// export const DASHBOARD_CURRENT_MONITORING_STATS_START =
//     'DASHBOARD_CURRENT_MONITORING_STATS_START';
// export const DASHBOARD_BLOCKING_TRENDS_START =
//     'DASHBOARD_BLOCKING_TRENDS_START';
// export const DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_START =
//     'DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_START';
// export const DASHBOARD_TOP_UNIQUE_ISSUES_START =
//     'DASHBOARD_TOP_UNIQUE_ISSUES_START';
// export const DASHBOARD_SUMMARY_BY_PROVIDER_START =
//     'DASHBOARD_SUMMARY_BY_PROVIDER_START';
// export const DASHBOARD_SUMMARY_BY_ISSUE_START =
//     'DASHBOARD_SUMMARY_BY_ISSUE_START';
// export const DASHBOARD_ISSUE_SUMMARY_START = 'DASHBOARD_ISSUE_SUMMARY_START';
// export const DASHBOARD_BLOCKED_PROVIDERS_START =
//     'DASHBOARD_BLOCKED_PROVIDERS_START';
// export const DASHBOARD_IMPRESSION_BLOCKS_START =
//     'DASHBOARD_IMPRESSION_BLOCKS_START';
// export const SET_DASH_SPECS = 'SET_DASH_SPECS';
// 
// export const DASHBOARD_CURRENT_MONITORING_STATS_SUCCESS =
//     'DASHBOARD_CURRENT_MONITORING_STATS_SUCCESS';
// export const DASHBOARD_CURRENT_MONITORING_STATS_ERROR =
//     'DASHBOARD_CURRENT_MONITORING_STATS_ERROR';
// export const DASHBOARD_BLOCKING_TRENDS_SUCCESS =
//     'DASHBOARD_BLOCKING_TRENDS_SUCCESS';
// export const DASHBOARD_BLOCKING_TRENDS_ERROR =
//     'DASHBOARD_BLOCKING_TRENDS_ERROR';
// export const DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_SUCCESS =
//     'DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_SUCCESS';
// export const DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_ERROR =
//     'DASHBOARD_MONITORED_IMPRESSIONS_TRENDS_ERROR';
// export const DASHBOARD_TOP_UNIQUE_ISSUES_SUCCESS =
//     'DASHBOARD_TOP_UNIQUE_ISSUES_SUCCESS';
// export const DASHBOARD_TOP_UNIQUE_ISSUES_ERROR =
//     'DASHBOARD_TOP_UNIQUE_ISSUES_ERROR';
// export const DASHBOARD_SUMMARY_BY_PROVIDER_SUCCESS =
//     'DASHBOARD_SUMMARY_BY_PROVIDER_SUCCESS';
// export const DASHBOARD_SUMMARY_BY_PROVIDER_ERROR =
//     'DASHBOARD_SUMMARY_BY_PROVIDER_ERROR';
// export const DASHBOARD_SUMMARY_BY_ISSUE_SUCCESS =
//     'DASHBOARD_SUMMARY_BY_ISSUE_SUCCESS';
// export const DASHBOARD_SUMMARY_BY_ISSUE_ERROR =
//     'DASHBOARD_SUMMARY_BY_ISSUE_ERROR';
// export const DASHBOARD_ISSUE_SUMMARY_SUCCESS =
//     'DASHBOARD_ISSUE_SUMMARY_SUCCESS';
// export const DASHBOARD_ISSUE_SUMMARY_ERROR =
//     'DASHBOARD_ISSUE_SUMMARY_ERROR';
// export const DASHBOARD_BLOCKED_PROVIDERS_SUCCESS =
//     'DASHBOARD_BLOCKED_PROVIDERS_SUCCESS';
// export const DASHBOARD_BLOCKED_PROVIDERS_ERROR =
//     'DASHBOARD_BLOCKED_PROVIDERS_ERROR';
// export const DASHBOARD_ISSUE_SUMMARY_SORT_CHANGED =
//     'DASHBOARD_ISSUE_SUMMARY_SORT_CHANGED';
// export const DASHBOARD_UPDATE_FILTER = 'DASHBOARD_UPDATE_FILTER';
// export const DASHBOARD_IMPRESSION_BLOCKS_SUCCESS =
//     'DASHBOARD_IMPRESSION_BLOCKS_SUCCESS';
// export const DASHBOARD_IMPRESSION_BLOCKS_ERROR =
//     'DASHBOARD_IMPRESSION_BLOCKS_ERROR';
// export const LOAD_UNIQUE_ISSUE_REPORT = 'LOAD_UNIQUE_ISSUE_REPORT';
// 
// export const DASHBOARD_SUMMARY_BY_PROVIDER_SORT_CHANGED =
//     'DASHBOARD_SUMMARY_BY_PROVIDER_SORT_CHANGED';
// 
// export const LOAD_DFP_SUCCESS = 'LOAD_DFP_SUCCESS';
// export const REMOVE_DFP_SUCCESS = 'REMOVE_DFP_SUCCESS';
// export const SET_DFP_AUTHORIZE_LINK = 'SET_DFP_AUTHORIZE_LINK_SUCCESS';
// 
// export const GET_ALL_REQUESTED = 'GET_ALL_REQUESTED';
// export const REPORTS_LOADING = 'REPORTS_LOADING';
// export const INIT_PROPERTY_FOR_REPORTS = 'INIT_PROPERTY_FOR_REPORTS';
// export const DELETE_REPORT = 'DELETE_REPORT';
// export const UPDATE_PENDING_REPORTS = 'UPDATE_PENDING_REPORTS';
// export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
// export const UPDATE_FAILED_REPORTS = 'UPDATE_FAILED_REPORTS';
// 
// export const SSP_DASHBOARD_UPDATE_FILTER = 'SSP_DASHBOARD_UPDATE_FILTER';
// export const SSP_DASHBOARD_BLOCK_HISTORY_STATS_START =
//     'SSP_DASHBOARD_BLOCK_HISTORY_STATS_START';
// export const SSP_DASHBOARD_BLOCK_HISTORY_SUCCESS =
//     'SSP_DASHBOARD_BLOCK_HISTORY_SUCCESS';
// export const SSP_DASHBOARD_CREATIVE_STATS_START =
//     'SSP_DASHBOARD_CREATIVE_STATS_START';
// export const SSP_DASHBOARD_CREATIVE_STATS_SUCCESS =
//     'SSP_DASHBOARD_CREATIVE_STATS_SUCCESS';
// export const SSP_DASHBOARD_MONITORED_STATS_START =
//     'SSP_DASHBOARD_MONITORED_STATS_START';
// export const SSP_DASHBOARD_MONITORED_STATS_SUCCESS =
//     'SSP_DASHBOARD_MONITORED_STATS_SUCCESS';
// export const SSP_DASHBOARD_DEMAND_SOURCE_VIOLATION_START =
//     'SSP_DASHBOARD_DEMAND_SOURCE_VIOLATION_START';
// export const SSP_DASHBOARD_DEMAND_SOURCE_VIOLATION_SUCCESS =
//     'SSP_DASHBOARD_DEMAND_SOURCE_VIOLATION_SUCCESS';
// export const SSP_DASHBOARD_DETECTED_ISSUES_START =
//     'SSP_DASHBOARD_DETECTED_ISSUES_START';
// export const SSP_DASHBOARD_DETECTED_ISSUES_SUCCESS =
//     'SSP_DASHBOARD_DETECTED_ISSUES_SUCCESS';
// export const SET_DEMAND_SOURCES = 'SET_DEMAND_SOURCES';
// 
// export const APPLY_FILTER_SSP_SCAN_LOG_SUCCESS =
//     'APPLY_FILTER_SSP_SCAN_LOG_SUCCESS';
// export const SSP_SCAN_LOG_LOADING = 'SSP_SCAN_LOG_LOADING';
// export const UPDATE_SSP_SCAN_LOG_HEADERS = 'UPDATE_SSP_SCAN_LOG_HEADERS';
// export const UPDATE_SSP_LOGS = 'UPDATE_SSP_LOGS';
// export const SORT_SSP_HEADERS = 'SORT_SSP_HEADERS';
// export const RESET_SSP_SCANLOG_FILTERS = 'RESET_SSP_SCANLOG_FILTERS';
// export const UPDATE_SSP_ALERT_LOG_FILTER = 'UPDATE_SSP_ALERT_LOG_FILTER';
// export const UPDATE_SSP_ALERT_LOG_DATE_FILTER =
//     'UPDATE_SSP_ALERT_LOG_DATE_FILTER';
// export const LOAD_SPECS_SUCCESS = 'LOAD_SPECS_SUCCESS';
// export const LOAD_SCAN_LOG_REPORT_ADM_SUCCESS =
//     'LOAD_SCAN_LOG_REPORT_ADM_SUCCESS';
// 
// export const SSP_GET_ALL_REQUESTED = 'SSP_GET_ALL_REQUESTED';
// export const SSP_REPORTS_LOADING = 'SSP_REPORTS_LOADING';
// export const SSP_DELETE_REPORT = 'SSP_DELETE_REPORT';
// export const SSP_UPDATE_PENDING_REPORTS = 'SSP_UPDATE_PENDING_REPORTS';
// export const SSP_UPDATE_FAILED_REPORTS = 'SSP_UPDATE_FAILED_REPORTS';
// export const SET_SSP_REPORT_TYPE = 'SET_SSP_REPORT_TYPE';
// 
// export const UPDATE_SSP_PUB_BLOCK_DATE_FILTER =
//     'UPDATE_SSP_PUB_BLOCK_DATE_FILTER';
// export const UPDATE_SSP_PUB_BLOCK_FILTER = 'UPDATE_SSP_PUB_BLOCK_FILTER';
// export const RESET_SSP_PUB_BLOCK_FILTERS = 'RESET_SSP_PUB_BLOCK_FILTERS';
// export const UPDATE_PUB_BLOCK_HEADERS = 'UPDATE_PUB_BLOCK_HEADERS';
// export const APPLY_FILTER_SSP_PUB_BLOCK_SUCCESS =
//     'APPLY_FILTER_SSP_PUB_BLOCK_SUCCESS';
// export const SSP_PUB_BLOCK_LOG_LOADING = 'SSP_PUB_BLOCK_LOG_LOADING';
// export const UPDATE_SSP_PUB_BLOCK_LOGS = 'UPDATE_SSP_PUB_BLOCK_LOGS';
// export const SSP_PUB_BLOCK_HEADER_LOADED = 'SSP_PUB_BLOCK_HEADER_LOADED';
// export const LOAD_PUBLISHER_BLOCK_REPORT_SUCCESS =
//     'LOAD_PUBLISHER_BLOCK_REPORT_SUCCESS';
// export const LOAD_SSP_PUB_BLOCK_SPECS_SUCCESS =
//     'LOAD_SSP_PUB_BLOCK_SPECS_SUCCESS';
// export const RESET_SSP_PUB_BLOCK_ISSUE_TYPE = 'RESET_SSP_PUB_BLOCK_ISSUE_TYPE';
// 
// export const SAVE_VIEWS_SUCCESS = 'SAVE_VIEWS_SUCCESS';
// 
// export const LOAD_AD_SIZES = 'LOAD_AD_SIZES';
// export const UPDATE_CREATIVE_SEARCH_DATE_FILTER =
//     'UPDATE_CREATIVE_SEARCH_DATE_FILTER';
// export const UPDATE_CREATIVE_SEARCH_FILTER = 'UPDATE_CREATIVE_SEARCH_FILTER';
// export const RESET_CREATIVE_SEARCH_FILTERS = 'RESET_CREATIVE_SEARCH_FILTERS';
// export const UPDATE_CREATIVES = 'UPDATE_CREATIVES';
// export const CREATIVE_SEARCH_LOADING = 'CREATIVE_SEARCH_LOADING';
// export const UPDATE_CATEGORIES = 'UPDATE_CATEGORIES';
// export const CREATIVE_BLOCK_STATUS_SUCCESS = 'CREATIVE_BLOCK_STATUS_SUCCESS';
// export const REPORT_FLAG = 'REPORT_FLAG';
// export const SUBMIT_FEEDBACK = 'SUBMIT_FEEDBACK';
// export const RESET_FB_SUCCESS = 'RESET_FB_SUCCESS';
// 
// export const RESET_PRIVACY_FILTER = 'RESET_PRIVACY_FILTER';
// export const UPDATE_PRIVACY_FILTER = 'UPDATE_PRIVACY_FILTER';
// export const PRIVACY_LOG_LOADING = 'PRIVACY_LOG_LOADING';
// export const UPDATE_PRIVACY_LOGS = 'UPDATE_PRIVACY_LOGS';
// export const PRIVACY_TABLE_SORT_CHANGED = 'PRIVACY_TABLE_SORT_CHANGED';
// export const LOAD_ENTITY_REPORT_SUCCESS = 'LOAD_ENTITY_REPORT_SUCCESS';
// export const RESET_ENTITY_REPORT = 'RESET_ENTITY_REPORT';
// export const ENTITY_REPORT_START = 'ENTITY_REPORT_START';
// export const ENTITY_REPORT_FAILURE = 'ENTITY_REPORT_FAILURE';
// export const SET_ASSOCIATED_PROVIDERS = 'SET_ASSOCIATED_PROVIDERS';
// #endif
