import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { ConnectedConsentComponent } from './PaneComponentHOC';

const PopoverCustom = ({ children }) => {
    const popover = (
        <Popover id="popover-basic">
            <Popover.Content>
                <span>
                    Learn more about this report{' '}
                    <a
                        href="https://www.confiant.com/ad-trace-consent"
                        referrerPolicy="no-referrer"
                        className="pt-0 pb-0"
                        target="_blank"
                        rel="noreferrer"
                    >
                        here
                    </a>
                    .
                </span>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={popover}
            rootClose={true}
        >
            {children}
        </OverlayTrigger>
    );
};

const ConsentDetails = ({
    consentDetails,
    url,
    sessionData,
    consentMisType,
    cmpData,
    consentString,
    trackingType,
    cookies,
    localStorage,
    adTraceCMWidgets,
    gpc,
}) => {
    let type = consentMisType === 'us_privacy' ? 'US Privacy' : 'TCF';

    return (
        <React.Fragment>
            <div id="consent-details" className="panel">
                <div className="column">
                    <h1 className="main title">
                        {type} Consent Mismatch Tracking Info
                        <small className="ml-1">
                            <PopoverCustom>
                                <i className="fa fa-info-circle" />
                            </PopoverCustom>
                        </small>
                    </h1>

                    <p className="info my-2">
                        Confiant looks at all tracking elements delivered to the
                        user as the creative renders and does not look at any
                        data exchanged inside the bidstream. It can be
                        challenging to reach out to entities directly and can be
                        more time-effective to block the ad if you do not have a
                        relationship with the entity listed under "Tracking
                        Entity" below.
                    </p>
                    {(consentMisType && (
                        <ConnectedConsentComponent
                            adTraceCMWidgets={adTraceCMWidgets}
                            key="consent-detail-left-pane"
                            consentMisType={consentMisType}
                            consentDetails={consentDetails}
                            direction="left"
                            trackingType={trackingType}
                            cookies={cookies}
                            localStorage={localStorage}
                            gpc={gpc}
                        />
                    )) ||
                        null}
                </div>

                <div className="column">
                    {(consentMisType && (
                        <ConnectedConsentComponent
                            adTraceCMWidgets={adTraceCMWidgets}
                            key="consent-detail-right-pane"
                            direction="right"
                            consentMisType={consentMisType}
                            consentDetails={consentDetails}
                            url={url}
                            cmpData={cmpData}
                            sessionData={sessionData}
                            consentString={consentString}
                            gpc={gpc}
                        />
                    )) ||
                        null}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ConsentDetails;
