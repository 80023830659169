// Needs to be in unsecure build.

import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { TRIMMED_STRING_SUFFIX } from '../constants/CommonConstants';
import is from 'is_js';
import { toastr } from 'react-redux-toastr';

const currencyFormtter = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const capitalize = val_str => {
    if (typeof val_str !== 'string') return val_str;
    return val_str.replace(/^./, val_str[0].toUpperCase());
};

export const sortArrayOnName = arr => {
    arr.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
    return arr;
};

export const getFormattedDate = (
    date,
    outFormat,
    inFormat = 'ddd, DD MMM YYYY HH:mm:ss'
) => {
    const m = moment(date, inFormat);
    return m.format(outFormat);
};

export const trimLargeStrings = (str, acceptedLength) => {
    return str && str.length > acceptedLength
        ? str.substring(0, acceptedLength - 3) + TRIMMED_STRING_SUFFIX
        : str;
};

export const doEvent = (obj, event) => {
    event = new Event(event, { target: obj, bubbles: true });
    return obj ? obj.dispatchEvent(event) : false;
};

export const disableAndSetBlank = elmName => {
    var el = document.getElementById(elmName);
    el.value = '';
    if (!is.ie()) doEvent(el, 'input');
    el.disabled = true;
};

export const setValue = (elmName, value) => {
    var el = document.getElementById(elmName);
    el.value = value;
    if (!is.ie()) doEvent(el, 'input');
};

export const toCurrency = value => {
    return (
        (value !== undefined &&
            value !== null &&
            currencyFormtter.format(value)) ||
        ''
    );
};

export const truncate = (value, truncateAt, tail) => {
    if (value.length > truncateAt) {
        return value.substring(0, truncateAt) + (tail || null ? tail : '');
    }
    return value;
};

export const getFormattedNumber = (number, format) => {
    return numeral(number).format(format);
};

export const toInteger = value => {
    if (typeof value == 'string') {
        return parseInt(value.replace(/[^0-9]/g, ''));
    }
    let parsed = parseInt(value);
    return isNaN(parsed) ? value : parsed;
};

export const toFloat = value => {
    if (typeof value == 'string') {
        return parseFloat(value.replace(/[^0-9.!?]/g, ''));
    }
    let parsed = parseFloat(value);
    return isNaN(parsed) ? value : parsed;
};

export const sumToInteger = function() {
    let args = Object.values(arguments),
        reducableArgs = [];
    for (let indx in [...args]) {
        let arg = args[indx];
        if (typeof arg === 'function') arg = arg();
        if (Array.isArray(arg)) reducableArgs = [...reducableArgs, ...arg];
        else reducableArgs.push(arg);
    }
    return reducableArgs.reduce((total, current) => {
        return total + toInteger(current);
    }, 0);
};

export const sumToFloat = function() {
    let args = Object.values(arguments),
        reducableArgs = [];
    for (let indx in [...args]) {
        let arg = args[indx];
        if (typeof arg === 'function') arg = arg();
        if (Array.isArray(arg)) reducableArgs = [...reducableArgs, ...arg];
        else reducableArgs.push(arg);
    }
    return reducableArgs.reduce((total, current) => {
        return total + toFloat(current);
    }, 0.0);
};

export const toPercent = (numerator, denominator, zeroedOut, otherData) => {
    let result = 0;

    if (denominator) {
        result = Math.round(
            (toFloat(numerator) / toFloat(denominator)) * 100.0
        );
    }

    if (result === 0 && numerator !== 0 && otherData) {
        return `<1%`;
    }

    if (result === 100 && otherData && otherData !== 0) {
        return `>99%`;
    }

    return `${zeroedOut && result === 0 ? zeroedOut : `${result}%`}`;
};

export const range = (start, count) => {
    return [...Array(count).keys()].map(i => {
        return i + start;
    });
};

export const checkEmptyField = field => {
    return field === '' ? null : field;
};

export const sleep = require('util').promisify((a, f) => setTimeout(f, a));

export const BlockingStatus = function(status, creativeReport = false) {
    let statusMap = {
        block: 'Active Blocking',
        detect: 'Detection Only',
        varies: 'Rule Varies by Property',
        empty: 'Not Monitored',
    };
    statusMap.whitelist = `${statusMap.detect} (override)`;
    statusMap.blacklist = `${statusMap.block} (override)`;
    if (!creativeReport) {
        statusMap.block = (
            <React.Fragment>
                <i
                    title="Active Blocking"
                    className="fas fa-2x fa-shield-alt blocking-status-icon font-light"
                ></i>
                {statusMap.block}
            </React.Fragment>
        );
    }

    return statusMap[status || 'empty'] || statusMap['empty'];
};

export const TabSubTitle = function(tabName) {
    const pageSubtitles = {
        detection: {
            className: 'detection',
            txt: 'Manage detection and blocking preferences for these issues.',
        },
        editProperty: {
            className: 'editProperty',
            txt: 'Edit Property Settings below',
        },
        passback: {
            className: 'passback',
            txt:
                'Set up the passback tags that will be called when Confiant blocks a bad ad. If no passback is set, the user will see a blank space...',
        },
        wrapper: {
            className: 'wrapper wrappers',
            txt: 'Access integration code below',
        },
        monitoring: {
            additionalTxt:
                "Please note that if you are also using Confiant's Prebid Integration, you will not need to include your prebid orders here. If you have any questions, please reach out to ",
            className: 'monitoring',
            txt:
                'Select the GAM orders you want your Confiant integration to monitor',
        },
        empty: {
            className: '',
            txt: '',
        },
    };

    let datum = pageSubtitles[tabName || 'empty'] || pageSubtitles['empty'];
    return (
        <div
            className={`d-flex flex-column justify-content-start page-subtitle-gray ${datum['className']} border-bottom`}
        >
            <span>{datum['txt']}</span>
            {(datum.hasOwnProperty('additionalTxt') && (
                <span className="additional-text mt-1">
                    {datum['additionalTxt']}
                    <a href="mailto:support@confiant.com">
                        support@confiant.com
                    </a>
                    .
                </span>
            )) ||
                null}
        </div>
    );
};
export const IssueDetails = (
    category,
    type,
    isAdTrace = false,
    isScanReport = false,
    reportType = ''
) => {
    let detailMap = {
        quality: {
            color: isScanReport && reportType === 'ssp' ? '#FFC107' : '#5341BA',
            icon: `fas fa-exclamation-triangle ${
                isAdTrace ? 'fa-2x mt-1' : 'fa-3x'
            }`,
        },
        security: {
            color: '#ED1941',
            icon: `fas fa-bug ${isAdTrace ? 'fa-2x mt-1' : 'fa-3x'}`,
        },
        privacy: {
            color: '#E3A342',
            icon: `fas fa-lock ${isAdTrace ? 'fa-2x mt-1' : 'fa-3x'}`,
        },
        empty: {
            color: '',
            icon: '',
        },
    };

    let res = (
        <div className="row leftpane-subtitle align-items-center flex-nowrap">
            <div className="left-pane--icon">
                <i
                    className="far fa-check-circle fa-3x"
                    style={{ color: '#00d090' }}
                ></i>
            </div>
            <div className="left-pane--title d-flex align-items-center">
                <div className="row" style={{ paddingTop: '3%' }}>
                    {' '}
                    NO ISSUES DETECTED
                </div>
            </div>
        </div>
    );

    if (reportType === 'pubCW') {
        let name = category
            ? `${category.toUpperCase()} ISSUE(S) DETECTED`
            : '';
        let cat = category && category !== 'Unknown' ? category : 'empty';

        if (cat === 'empty') {
            return res;
        }

        res = (
            <div className="row leftpane-subtitle align-items-center flex-nowrap">
                <div className="left-pane--icon">
                    <i
                        className={detailMap[cat].icon}
                        style={{ color: detailMap[cat].color }}
                    ></i>
                </div>
                <div id="issue-title" className="left-pane--title">
                    <div className="row">{name}</div>
                </div>
            </div>
        );

        return res;
    } else {
        let name = category ? `${category.toUpperCase()} ISSUE` : '';
        let cat = category && category !== 'Unknown' ? category : 'empty';
        if (isAdTrace) {
            return (
                <i
                    className={detailMap[cat].icon}
                    style={{ color: detailMap[cat].color }}
                ></i>
            );
        }

        if (isScanReport && cat === 'empty') {
            return res;
        }

        res = (
            <div className="row leftpane-subtitle align-items-center flex-nowrap">
                <div className="left-pane--icon">
                    <i
                        className={detailMap[cat].icon}
                        style={{ color: detailMap[cat].color }}
                    ></i>
                </div>
                <div id="issue-title" className="left-pane--title">
                    <div className="row">{name}</div>
                    <div className="row second">{type}</div>
                </div>
            </div>
        );
    }

    return res;
};

export const getToastr = (toastrType, message, timeOut) => {
    const toastrOptions = {
        timeOut: timeOut ? timeOut : toastrType === 'error' ? 0 : 6500,
        icon: toastrType,
        status: toastrType,
        showCloseButton: true,
        removeOnHover: toastrType === 'error' ? false : true,
        removeOnHoverTimeOut: toastrType === 'error' ? 0 : 6500,
    };

    if (toastrType === 'error') {
        return toastr.error(toastrType.toUpperCase(), message, toastrOptions);
    }

    if (toastrType === 'warning') {
        return toastr.warning(toastrType.toUpperCase(), message, toastrOptions);
    }

    if (toastrType === 'info') {
        return toastr.info(toastrType.toUpperCase(), message, toastrOptions);
    }

    if (toastrType === 'success') {
        return toastr.success(toastrType.toUpperCase(), message, toastrOptions);
    }
};

export const isForcefulRedirect = (type, other_issues) => {
    return (
        type === 'Forceful Redirect' ||
        type === 'Forceful redirection (Automatic)' ||
        (other_issues &&
            other_issues.length &&
            (other_issues.includes('Forceful Redirect') ||
                other_issues.includes('Forceful redirection (Automatic)')))
    );
};

/*
    Returns an object of actualLevel and refactoredLevel
    actualLevel is based on the input param
    if null, then empty
    if greater than or equals to 10, then high
    if lower than 4, then low
    rest is medium
    refactored is normal by default
    if medium value is higher than 4 or low value is higher than 0, then refactored is set to higher
    for consent_mismatch_risk:
    if greater or equals to 10, actualLevel sets to high
    if greater or equal to 4, actualLevel sets to medium
    if greater than 0, actualLevel sets to 0
    else empty
    refactoredLevel for every case stays normal
*/
export const getRiskLevelVals = (val, riskFactor) => {
    let lvl = 'empty',
        refactored = 'normal';
    if (val === null) {
        return { actualLevel: lvl, refactoredLevel: refactored };
    }
    // metrics for consent_mismatch_risk
    if (riskFactor === 'consent_mismatch_risk') {
        if (val === 0) {
            return { actualLevel: lvl, refactoredLevel: refactored };
        }
        lvl = val >= 5 ? 'high' : val >= 3 ? 'medium' : 'low';
        refactored = 'normal';
        return { actualLevel: lvl, refactoredLevel: refactored };
    }
    lvl = val >= 10 ? 'high' : val > 0 ? 'medium' : 'low';
    refactored = 'normal';
    if (lvl === 'medium' && val > 4) {
        refactored = 'higher';
    }
    if (lvl === 'low' && val > 0) {
        refactored = 'higher';
    }
    return { actualLevel: lvl, refactoredLevel: refactored };
};

export const reactStringReplace = props => {
    const { pattern, decorator, input } = props;
    let output = [];

    let matchIndex = 0;
    let processedInput = input;
    let result = pattern.exec(processedInput);

    while (result !== null) {
        const matchStartAt = result.index;
        const match = result[0];

        const contentBeforeMatch = processedInput.substring(0, matchStartAt);
        const decoratedMatch = decorator(match, matchIndex);

        output.push(contentBeforeMatch);
        output.push(decoratedMatch);

        // clear processed content: before match, match
        processedInput = processedInput.substring(
            matchStartAt + match.length,
            processedInput.length + 1
        );

        pattern.lastIndex = 0;

        result = pattern.exec(processedInput);

        ++matchIndex;
    }

    if (processedInput) {
        output.push(processedInput);
    }

    return output;
};

export const secToDays = sec => {
    if (typeof sec !== 'number') return 0;

    return Math.floor(sec / (3600 * 24));
};

export const ModalPopoverCustom = ({
    children,
    popoverContent,
    trigger = 'click',
    placement = 'right',
    customClass = '',
}) => {
    const popover = (
        <Popover id="popover-basic" bsPrefix={customClass}>
            <Popover.Content>{popoverContent}</Popover.Content>
        </Popover>
    );
    return (
        <OverlayTrigger
            trigger={trigger}
            placement={placement}
            overlay={popover}
            rootClose={true}
        >
            {children}
        </OverlayTrigger>
    );
};

export const toUsDate = s => {
    const date = new Date(s);

    if (!(date instanceof Date && !isNaN(date))) {
        return '';
    }

    return new Date(s).toLocaleDateString('us', {
        day: 'numeric',
        month: 'numeric',
        year: '2-digit',
    });
};

export const getSensitiveCategoryOpts = () => {
    const sensitiveCategories = {
        weapon: 'Weapon',
        alcohol: 'Alcohol',
        tobacco: 'Tobacco',
        nudity: 'Nudity',
        violence: 'Violence',
        drugs: 'Drugs',
        visually_disturbing: 'Visually Disturbing',
        hate_symbols: 'Hate Symbols',
        gambling: 'Gambling',
        cannabis: 'Cannabis',
    };

    let opts = Object.keys(sensitiveCategories).map(cat => (
        <option key={`sensitive-${cat}`} value={cat}>
            {sensitiveCategories[cat]}
        </option>
    ));

    return opts;
};

export const getCreativeTypes = () => {
    const creativeTypes = ['display', 'video'];

    let creativeTypesItems = creativeTypes.map(type => (
        <option key={`creative-type-${type}`} value={type}>
            {capitalize(type)}
        </option>
    ));

    return creativeTypesItems;
};

export const getCategoryOpts = (categoryList, accountType) => {
    if (accountType === 'blocking') {
        let categoryListItems = [];
        const allOption = [{ value: '', label: 'All' }];

        categoryListItems.push(allOption);

        categoryList.forEach(element => {
            categoryListItems.push({
                value: element[1].id,
                label: element[1].name,
                name: 'categories',
            });
        });

        return categoryListItems;
    }

    let categoryListItems = categoryList.map(category => (
        <option
            key={`category-${category[1].id}-${category[1].code}`}
            value={category[1].id}
        >
            {category[1].name}
        </option>
    ));

    return categoryListItems;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

export const abbreviateNumber = number => {
    const SYMBOLS = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

    let tier = (Math.log10(Math.abs(number)) / 3) | 0;

    if (tier === 0) return number;

    let suffix = SYMBOLS[tier];
    let scaled = number / Math.pow(10, tier * 3);

    return scaled.toFixed(1) + suffix;
};

export const updateItems = (items, callback) => {
    items.forEach(callback);
    return items;
};

export const renameProperty = (property, index) => {
    property.name = `Property ${index + 1}`;
};

export const updateEmailWithIndex = (account, index) => {
    account.email = `demo-email${index === 0 ? '' : index + 1}@email.com`;
};

export const updateEmailStartingFrom1 = (account, index) => {
    account.email = `demo-email${index + 1}@email.com`;
};
