import React from 'react';
import AceEditor from 'react-ace';
import brace from 'brace';

import 'brace/mode/html';
import 'brace/theme/pastel_on_dark';
import 'brace/ext/searchbox';

const beautify_html = require('js-beautify').html;

class CodeArea extends React.Component {
    finder = () => {
        brace.edit('ace-code-editor').execCommand('find');
    };

    render() {
        const { codeBody } = this.props;

        return (
            <React.Fragment>
                <div className="ace-editor-container panel">
                    <button
                        onClick={this.finder}
                        className="search-code"
                        id="trigger-search"
                        title="Search the code"
                    >
                        <i className="fa fa-search"></i>
                    </button>
                    <AceEditor
                        id="ace-code-editor"
                        mode="html"
                        theme="pastel_on_dark"
                        name="ace-code-editor"
                        value={beautify_html(codeBody, {
                            max_preserve_newlines: '1',
                        })}
                        readOnly={true}
                        wrapEnabled={true}
                        fontSize="11"
                        showPrintMargin={false}
                        maxLines={Infinity}
                        height="auto"
                        width="auto"
                        setOptions={{
                            useWorker: false,
                            animatedScroll: true,
                            highlightActiveLine: true,
                            highlightSelectedWord: true,
                            highlightGutterLine: true,
                            autoScrollEditorIntoView: true,
                            fadeFoldWidgets: true,
                        }}
                        editorProps={{ $blockScrolling: Infinity }}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default CodeArea;
