import React from 'react';
import TooltipComponent from '../shared/TooltipComponent';

class ExpandableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: props.data.length,
            expanded: false,
        };
    }

    handleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    getTrimmedTooltip = (data, idx) => {
        return (
            <TooltipComponent
                id={`ellipsistooltip--${idx}--${data}--${data.length}`}
                copyAct={true}
                placement="bottom"
            >
                {data}
            </TooltipComponent>
        );
    };

    render() {
        const { count, expanded } = this.state;
        const { data } = this.props;

        let moreItems = count > 1;

        if (!moreItems) {
            return (
                <React.Fragment>
                    {this.getTrimmedTooltip(data[0], Math.random())}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {(!expanded && (
                        <ul className="expandable-list">
                            <li>
                                {this.getTrimmedTooltip(data[0], Math.random())}
                            </li>
                            <li className="filters" onClick={this.handleExpand}>
                                <i className="fas fa-plus fa-xs"></i>{' '}
                                <span>
                                    {count -
                                        1 +
                                        (count - 1 === 1
                                            ? ` Other`
                                            : ` Others`)}{' '}
                                </span>
                            </li>
                        </ul>
                    )) || (
                        <ul className="expandable-list">
                            {data.map((d, index) => (
                                <li key={index}>
                                    {this.getTrimmedTooltip(d, index)}
                                </li>
                            ))}
                            <li className="filters" onClick={this.handleExpand}>
                                <span> SHOW LESS </span>
                            </li>
                        </ul>
                    )}
                </React.Fragment>
            );
        }
    }
}

export default ExpandableList;
