import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TooltipComponent from '../shared/TooltipComponent';

class CopyText extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            copied: false,
        };

        this.onCopy = this.onCopy.bind(this);
    }

    onCopy() {
        this.setState({ copied: true });
        if (this._isMounted) {
            setTimeout(() => this.setState({ copied: false }), 1500);
        }
    }

    async componentDidMount() {
        this._isMounted = true;
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { id, className, text } = this.props;

        return (
            <span className={`${className}`}>
                <CopyToClipboard text={text} onCopy={this.onCopy}>
                    {this.state.copied ? (
                        <i className="fas fa-check text-success fa-lg mr-1"></i>
                    ) : (
                        <i className="far fa-copy fa-lg mr-1"></i>
                    )}
                </CopyToClipboard>
                <TooltipComponent
                    copyAct={true}
                    id={`${id}`}
                    placement="bottom"
                >
                    {text}
                </TooltipComponent>
            </span>
        );
    }
}

export default CopyText;
