import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const tableData = {
    gdpr: {
        unregistered: [
            { key: 1, question: 'Did tracking occur?', answer: 'YES' },
            {
                key: 2,
                question: 'Was Consent or Legitimate Interest present?',
                answer: 'NO',
                tooltip: true,
            },
        ],
        registered: {
            has_tracking: {
                key: 1,
                question: 'Did tracking occur?',
                answer: { yes: { txt: 'YES', className: 'purple' } },
            },
            vendor_consent_given: {
                key: 2,
                question: 'Was consent present for the vendor?',
                answer: {
                    yes: { txt: 'YES', className: 'black' },
                    no: { txt: 'NO', className: 'red', tooltip: true },
                },
            },
            purpose_consent_given: {
                key: 3,
                question: 'Was consent present for the purposes declared?',
                answer: {
                    yes: { txt: 'YES', className: 'black' },
                    no: { txt: 'NO', className: 'red', tooltip: true },
                },
            },
            legitimate_interest_vendor_objection: {
                key: 4,
                question: 'Was Legitimate Interest objected to for the vendor?',
                answer: {
                    yes: { txt: 'YES', className: 'red', tooltip: true },
                    no: { txt: 'NO', className: 'black' },
                },
            },
            legitimate_interest_purpose_objection: {
                key: 5,
                question:
                    'Was Legitimate Interest objected to for the purposes declared?',
                answer: {
                    yes: { txt: 'YES', className: 'red', tooltip: true },
                    no: { txt: 'NO', className: 'black' },
                },
            },
        },
    },
    us_privacy: [
        {
            key: 1,
            question: 'Did tracking occur?',
            answer: 'YES',
            className: 'red',
        },
        {
            key: 2,
            question: 'Did user opt out of data sales?',
            answer: 'YES',
            className: 'red',
            tooltip: true,
        },
    ],
};

const constructAnswerObj = (key, mismatch) => {
    let t = tableData['gdpr']['registered'];
    let a = t[key]['answer'];
    // Implement the answer and tooltip stuff here
    switch (key) {
        case 'vendor_consent_given':
            a = mismatch === true ? a['yes'] : a['no'];
            break;
        case 'purpose_consent_given':
            a =
                Object.values(mismatch).every(item => {
                    return item === true;
                }) === true
                    ? a['yes']
                    : a['no'];
            break;
        case 'legitimate_interest_vendor_objection':
            a = mismatch === false ? a['no'] : a['yes'];
            break;
        case 'legitimate_interest_purpose_objection':
            a =
                Object.values(mismatch).every(item => {
                    return item === false;
                }) === true
                    ? a['no']
                    : a['yes'];
            break;
        default:
            a = a['yes'];
            break;
    }
    return {
        answer: a['txt'],
        tooltip: a['tooltip'],
        className: a['className'],
    };
};

const getTableData = (mismatchType, isRegistered, mismatch, gpc) => {
    let tData = tableData[mismatchType];

    if (mismatchType === 'us_privacy') {
        tData.push({
            key: 3,
            question: 'Did user opt-out using Global Privacy Control (GPC)?',
            answer: gpc ? 'YES' : 'NO',
            className: gpc ? 'red' : 'black',
        });

        return tData;
    }

    if (mismatchType === 'gdpr') {
        if (!isRegistered) {
            return tData['unregistered'];
        } else {
            let regData = { ...tData['registered'] };
            let rows = [];
            let keys = Object.keys(regData);

            rows = keys.reduce((result, k) => {
                let v = mismatch[k];
                /*
                    if the value is null
                    and the key is legitimate_interest_purpose_objection or purpose_consent_given
                    then there are no gdpr_purposes related data in the db
                */
                if (
                    !(
                        v === null &&
                        [
                            'purpose_consent_given',
                            'legitimate_interest_purpose_objection',
                        ].includes(k)
                    )
                ) {
                    let answerObj = constructAnswerObj(k, mismatch[k]);
                    let { className, answer, tooltip } = answerObj;
                    result.push({
                        name: k,
                        key: regData[k].key,
                        question: regData[k].question,
                        answer: answer,
                        className: className,
                        tooltip: tooltip,
                        data: v,
                    });
                }
                return result;
            }, []);

            return rows;
        }
    }

    return [];
};

const getTooltipContent = (
    mismatchType,
    isRegistered,
    rowKey,
    rowClassName,
    rowData,
    iab
) => {
    if (mismatchType === 'us_privacy') {
        return (
            <div className="p-3">
                <b>Opt-Out Sale = Y</b>
                <br />
                <span>
                    (user opted-out of sale of their personal information)
                </span>
            </div>
        );
    }

    if (mismatchType === 'gdpr') {
        if (!isRegistered) {
            return (
                <div className="p-3">
                    Consent and Legitimate Interest is missing since entity is
                    not TCF registered.
                </div>
            );
        } else {
            // It is registered so there should be an iab id...Grab it
            let iab_id = iab.id;
            let result = getTooltipFields(rowKey, rowData, iab_id);
            return <div className="p-1">{result}</div>;
        }
    }
};

const getListed = data => {
    return (
        <ul className="tooltip-tree-list">
            {data &&
                Object.entries(data).map(([key, value]) => (
                    <li key={`${key}--li-item--${Math.random()}`}>
                        {(typeof value !== 'object' && (
                            <div className="d-flex flex-row obj-list ml-4">
                                <span>{`${key}:`}</span>&nbsp;
                                <span className="tooltip-colored ml-1">
                                    {value}
                                </span>
                            </div>
                        )) ||
                            null}
                        {(typeof value === 'object' && (
                            <ObjectTree keyItem={key} valItem={value} />
                        )) ||
                            null}
                    </li>
                ))}
        </ul>
    );
};

const ObjectTree = React.memo(({ keyItem, valItem }) => {
    let isFirst = ['vendor', 'purpose'].includes(keyItem);
    return (
        <div className={`d-flex flex-column ${!isFirst ? 'ml-2' : ''}`}>
            <div className={`d-flex flex-row obj-list`}>
                <div className="mr-1 d-flex arrow-down"></div>
                <span>{keyItem}</span>
            </div>
            {getListed(valItem)}
        </div>
    );
});

const getTooltipFields = (rowKey, rowData, iab_id = null) => {
    // Prepare the skeleton of the json for vendor and purpose
    let vData = {
        vendor: {},
    };

    let pData = {
        purpose: {},
    };

    let result = null;

    if (
        [
            'vendor_consent_given',
            'legitimate_interest_vendor_objection',
        ].includes(rowKey)
    ) {
        let vcgData = { ...vData };
        let vKey =
            rowKey === 'legitimate_interest_vendor_objection'
                ? 'legitimateInterests'
                : 'consents';
        let vValue =
            rowKey === 'legitimate_interest_vendor_objection'
                ? rowData === true
                    ? 'false'
                    : 'missing'
                : rowData === false
                ? 'false'
                : 'missing';
        vcgData['vendor'] = { [vKey]: {} };
        vcgData['vendor'][vKey][iab_id] = vValue;
        result = vcgData;
    }

    if (rowKey === 'purpose_consent_given') {
        let vcgData = { ...vData };
        vcgData['vendor'] = { purposes: {} };
        vcgData['vendor']['purposes'] = {};
        let entries = Object.entries(rowData).filter(
            ([key, value]) => value !== true
        );
        entries.forEach(
            ([key, value]) =>
                (vcgData['vendor']['purposes'][key] =
                    value === false ? 'false' : 'missing')
        );
        result = vcgData;
    }

    if (rowKey === 'legitimate_interest_purpose_objection') {
        let lipoData = { ...pData };
        lipoData['purpose'] = { legitimateInterests: {} };
        lipoData['purpose']['legitimateInterests'] = {};
        let entries = Object.entries(rowData).filter(
            ([key, value]) => value !== false
        );
        entries.forEach(
            ([key, value]) =>
                (lipoData['purpose']['legitimateInterests'][key] =
                    value === true ? 'false' : 'missing')
        );
        result = lipoData;
    }

    return getListed(result);
};

export const MismatchDetails = React.memo(
    ({ mismatchType, iab, mismatch, gpc }) => {
        let isRegistered = iab && iab.id;
        let tDatum = getTableData(mismatchType, isRegistered, mismatch, gpc);
        let datum = tDatum
            ? tDatum.map(d => {
                  let row = (
                      <tr key={`mismatch-row-${mismatchType}-${d.key}`}>
                          <td
                              key={`mismatch-row-column${mismatchType}-${d.key}-first`}
                          >
                              {d.question}
                          </td>
                          <td
                              key={`mismatch-row-column-${mismatchType}-${d.key}-second`}
                          >
                              <span
                                  className={`mismatch-answer-col--${d.className}`}
                              >
                                  {d.answer}
                              </span>
                          </td>
                      </tr>
                  );
                  if (d.tooltip) {
                      return (
                          <OverlayTrigger
                              key={`mismatch-row-column${mismatchType}-${d.key}`}
                              placement={'right'}
                              delay={{ show: 50, hide: 200 }}
                              overlay={
                                  <Tooltip
                                      id={`tooltip-${Math.random()}`}
                                      className={`adtrace-table-row-tooltip`}
                                  >
                                      {getTooltipContent(
                                          mismatchType,
                                          isRegistered,
                                          d.name,
                                          d.className,
                                          d.data,
                                          iab
                                      )}
                                  </Tooltip>
                              }
                          >
                              {row}
                          </OverlayTrigger>
                      );
                  } else {
                      return row;
                  }
              })
            : null;

        return (
            <div className="d-flex flex-column data-row pb-2">
                <table className={`mismatch-details-table`}>
                    <tbody className={`${mismatchType}`}>{datum}</tbody>
                </table>
            </div>
        );
    }
);

export default MismatchDetails;
