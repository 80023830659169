import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class TooltipComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasOverflowingChildren: false,
            text: undefined,
            prevPropsChildren: props.children,
        };
        this.updateOverflow = this.updateOverflow.bind(this);
    }

    updateOverflow(e) {
        const el = e.target;
        const { hasOverflowingChildren, text } = this.state;
        const { notEllipsis, tooltipContent, isLink, children } = this.props;

        if (
            !hasOverflowingChildren &&
            (notEllipsis || el.scrollWidth > el.clientWidth)
        ) {
            if (el.textContent !== text) {
                let val = isLink
                    ? children
                    : notEllipsis
                    ? tooltipContent
                    : el.textContent;
                this.setState({ text: val, hasOverflowingChildren: true });
            } else {
                this.setState({ hasOverflowingChildren: true });
            }
        } else {
            this.setState({ hasOverflowingChildren: false });
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.children === state.prevPropsChildren) return null;
        return {
            hasOverflowingChildren: false,
            prevPropsChildren: props.children,
        };
    }

    render() {
        const { hasOverflowingChildren, text } = this.state;
        const {
            placement = 'top',
            replaceLink = true,
            children,
            copyAct,
            isLink,
            popperConfig,
            notEllipsis,
            className,
            tooltipClassName,
            copyContent,
        } = this.props;
        const tooltip = (
            <Tooltip
                id={`tooltip-${Math.random()}`}
                className={`${tooltipClassName}`}
            >
                {text}
            </Tooltip>
        );
        const renderChildren =
            isLink && replaceLink
                ? children.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
                : children;

        const content = notEllipsis ? (
            renderChildren
        ) : (
            <div className={`ellipsis-style ${className}`}>
                {renderChildren}
            </div>
        );

        const popperConfigWithModifer = {
            ...popperConfig,
            preventOverflow: {
                enabled: false,
            },
        };

        return hasOverflowingChildren ? (
            isLink ? (
                <OverlayTrigger
                    placement={placement}
                    overlay={tooltip}
                    delay={{ show: 50, hide: 200 }}
                    popperConfig={popperConfigWithModifer}
                    data-testid="tooltip"
                >
                    <div className="ellipsis-style">
                        <a
                            href={children}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {content}
                        </a>
                    </div>
                </OverlayTrigger>
            ) : copyAct ? (
                <OverlayTrigger
                    placement={placement}
                    overlay={tooltip}
                    delay={{ show: 50, hide: 200 }}
                    popperConfig={popperConfigWithModifer}
                    data-testid="tooltip"
                >
                    <CopyToClipboard text={copyContent || children}>
                        {content}
                    </CopyToClipboard>
                </OverlayTrigger>
            ) : (
                <OverlayTrigger
                    placement={placement}
                    overlay={tooltip}
                    delay={{ show: 50, hide: 200 }}
                    popperConfig={popperConfigWithModifer}
                    data-testid="tooltip"
                >
                    {content}
                </OverlayTrigger>
            )
        ) : (
            <div
                className={notEllipsis ? '' : `ellipsis-style ${className}`}
                onMouseEnter={this.updateOverflow}
                data-testid="tooltip"
            >
                {renderChildren}
            </div>
        );
    }
}

export default TooltipComponent;
