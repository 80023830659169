import {
    // #if process.env.BUILD_SECURE === '1'
//     ALERT_LOG_LOADING,
//     CREATIVE_SEARCH_LOADING,
//     SSP_SCAN_LOG_LOADING,
//     SSP_PUB_BLOCK_LOG_LOADING,
//     PRIVACY_LOG_LOADING,
    // #endif
    BEGIN_API_CALL,
    END_API_CALL,
    API_CALL_ERROR,
} from '../actions/ActionTypes';

function actionTypeEndsInSuccess(type) {
    return type.substring(type.length - 8) === '_SUCCESS';
}

const InitialState = {
    isAlertLogLoading: false,
    isApiCallInProgress: false,
    isScanLogLoading: false,
    isPubBlockLoading: false,
    isCreativeSearchLoading: false,
    isPrivacyLogLoading: false,
    pendingCalls: 0,
};

export default function apiCallStatusReducer(state = InitialState, action) {
    if (action.type === BEGIN_API_CALL) {
        const { pendingCalls: existingPendingCalls } = state;
        const pendingCalls = existingPendingCalls + 1;
        return {
            ...state,
            pendingCalls: pendingCalls,
            isApiCallInProgress: pendingCalls !== 0,
            isAlertLogLoading: false,
        };
        // #if process.env.BUILD_SECURE === '1'
//     } else if (action.type === ALERT_LOG_LOADING) {
//         return {
//             ...state,
//             isApiCallInProgress: false,
//             isAlertLogLoading: action.loading,
//         };
//     } else if (action.type === SSP_SCAN_LOG_LOADING) {
//         return {
//             ...state,
//             isApiCallInProgress: false,
//             isScanLogLoading: action.loading,
//         };
//     } else if (action.type === SSP_PUB_BLOCK_LOG_LOADING) {
//         return {
//             ...state,
//             isApiCallInProgress: false,
//             isPubBlockLoading: action.loading,
//         };
//     } else if (action.type === PRIVACY_LOG_LOADING) {
//         return {
//             ...state,
//             isApiCallInProgress: false,
//             isPrivacyLogLoading: action.loading,
//         };
//     } else if (action.type === CREATIVE_SEARCH_LOADING) {
//         return {
//             ...state,
//             isApiCallInProgress: false,
//             isCreativeSearchLoading: action.loading,
//         };
        // #endif
    } else if (
        action.type === API_CALL_ERROR ||
        actionTypeEndsInSuccess(action.type) ||
        action.type === END_API_CALL
    ) {
        const { pendingCalls: existingPendingCalls } = state;
        const pendingCalls =
            existingPendingCalls === 0 ? 0 : existingPendingCalls - 1;
        return {
            ...state,
            pendingCalls: pendingCalls,
            isApiCallInProgress: pendingCalls !== 0,
        };
    }

    return state;
}
