import fetch from 'cross-fetch';
import { validateResponse, makeRequest } from './ApiCommon';
import { GET_REQUEST } from './ApiConstants';
import {
    // #if process.env.BUILD_SECURE === '1'
//     SSPScanLogURL,
//     GetSpecsURL,
//     ScanReportURL,
    // #endif
    SSPAdMarkUpURL,
    ScanReportByTiURL,
} from './ApiUrls';

// #if process.env.BUILD_SECURE === '1'
// export const filterLog = (filters, signal) => {
//     return fetch(`${SSPScanLogURL}`, {
//         method: 'POST',
//         body: JSON.stringify(filters),
//         credentials: 'include',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         signal,
//     }).then(validateResponse);
// };
// 
// export const getSpecs = signal => {
//     return fetch(`${GetSpecsURL}?user_specific=true`, {
//         ...GET_REQUEST,
//         signal,
//     }).then(validateResponse);
// };
// 
// export const getScanLogHeaders = (data, signal = undefined) => {
//     return makeRequest(`${SSPScanLogURL}/headers`, { ...data }, signal);
// };
// 
// export const getScanLogReport = (id, type = 'ssp', signal = undefined) => {
//     return fetch(`${ScanReportURL}/${type}/${id}`, {
//         ...GET_REQUEST,
//         signal,
//     }).then(validateResponse);
// };
// #endif

export const getScanLogReportADM = (ad_instance, signal = undefined) => {
    return fetch(`${SSPAdMarkUpURL}/${ad_instance}`, {
        ...GET_REQUEST,
        signal,
    }).then(validateResponse);
};

export const getScanLogReportByTi = (ti, token, signal = undefined) => {
    return fetch(`${ScanReportByTiURL}/${ti}?token=${token}`, {
        ...GET_REQUEST,
        signal,
    }).then(res => validateResponse(res, '/not-found'));
};
