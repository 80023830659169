import fetch from 'cross-fetch';
import { validateResponse } from './ApiCommon';
import {
    GenerateKnowledgeOwlTokenURL,
    LoginURL,
    LogoutURL,
    LoginStatusURL,
    UsersListURL,
} from './ApiUrls';

export const login = usercredentials => {
    return fetch(`${LoginURL}`, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Cookies: document.cookie,
        },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(usercredentials),
    }).then(validateResponse);
};

export const logout = () => {
    return fetch(`${LogoutURL}`, {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(validateResponse);
};

export const loginStatus = async () => {
    try {
        const response = await fetch(`${LoginStatusURL}`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return await response.json();
    } catch {
        return false;
    }
};
export const getUsersList = async (search = '') => {
    try {
        const response = await fetch(
            `${UsersListURL}?search=${encodeURIComponent(search)}`,
            {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );

        return await response.json();
    } catch {
        return false;
    }
};

export const generateKnowledgeOwlToken = async () => {
    const response = await fetch(GenerateKnowledgeOwlTokenURL, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return await validateResponse(response);
};
