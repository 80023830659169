import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AdTraceReducer from './AdTraceReducer';
import alertLogReport from './AlertLogReportReducer';
import apiCallStatus from './ApiCallStatusReducer';
import AuthReducer from './AuthReducer';
import resetPasswordReducer from './ResetPasswordReducer';

// #if process.env.BUILD_SECURE === '1'
// import alertLogReducer from './AlertLogReducer';
// import dashboardReducer from './DashboardReducer';
// import DFPReducer from './DFPReducer';
// import entityReportReducer from './EntityReportReducer';
// import hrapReducer from './HrapReducer';
// import MonitoringReducer from './MonitoringReducer';
// import PassbackReducer from './PassbackReducer';
// import PrivacyReducer from './PrivacyReducer';
// import PropertyReducer from './PropertyReducer';
// import reportsReducer from './ReportsReducer';
// import settingsReducer from './SettingsReducer';
// import sspAlertLogReducer from './SSPAlertLogReducer';
// import sspDashboardReducer from './SSPDashboardReducer';
// import sspPubBlockReportReducer from './SSPPubBlockReportReducer';
// import creativeSearchReducer from './CreativeSearchReducer';
// import sspPublisherBlocksReducer from './SSPPublisherBlocksReducer';
// import sspReportsReducer from './SSPReportsReducer';
// import WrapperReducer from './WrapperReducer';
// #endif

const rootReducer = combineReducers({
    adtraceReducer: AdTraceReducer,
    authReducer: AuthReducer,
    resetPasswordReducer,
    toastr: toastrReducer,
    apiCallStatus,
    alertLogReport,

    // #if process.env.BUILD_SECURE === '1'
//     alertLogReducer,
//     dashboardReducer,
//     dfpReducer: DFPReducer,
//     entityReportReducer,
//     hrapReducer,
//     monitoringReducer: MonitoringReducer,
//     passbackReducer: PassbackReducer,
//     privacyReducer: PrivacyReducer,
//     propertyReducer: PropertyReducer,
//     reportsReducer,
//     settings: settingsReducer,
//     sspAlertLogReducer,
//     sspDashboardReducer,
//     creativeSearchReducer,
//     sspReportsReducer,
//     sspPubBlockReportReducer,
//     sspPublisherBlocksReducer,
//     wrapperReducer: WrapperReducer,
    // #endif
});

export default rootReducer;
