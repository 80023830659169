import React from 'react';

export const riskAssessmentInfo = {
    entity_id_risk: {
        risk_factor: 'Known Entity',
        risk_info: {
            medium: {
                identified: 'No',
                details: [
                    {
                        risk_level: {
                            normal:
                                'Confiant is not familiar with this entity.',
                        },
                    },
                    {
                        risk_to_you:
                            'Confiant has done extensive documentation of entities and their associated domains across the ecosystem. If an entity is unknown, it could pose a risk to you as your readers are not able to opt out of tracking or data sales.',
                    },
                    {
                        next_steps: {
                            modify: {
                                pattern: /\[.*?\]/gim,
                                decorator: (match, index) => {
                                    return (
                                        <button
                                            key={match + index}
                                            target="_blank"
                                            href="https://forms.gle/kCEEVw7RbGMQc6LcA"
                                            referrerPolicy="no-referrer"
                                        >
                                            {match
                                                .replace('[', '')
                                                .replace(']', '')}
                                        </button>
                                    );
                                },
                            },
                            txt:
                                'Make sure you activate Consent Mismatch controls to prevent this entity from tracking without consent. If you do know this entity, please let us know through this [form].',
                        },
                    },
                ],
            },
            low: {
                identified: 'Yes',
                details: [
                    {
                        risk_level: {
                            normal:
                                'This is a known entity that Confiant is familiar with.',
                        },
                    },
                    {
                        risk_to_you:
                            'Confiant has done extensive documentation of entities and their associated domains across the ecosystem. If an entity is unknown, it could pose a risk to you as your readers are not able to opt out of tracking or data sales.',
                    },
                ],
            },
        },
    },
    consent_mismatch_risk: {
        risk_factor: 'Consent Mismatch Frequency',
        risk_info: {
            high: {
                identified: 'High',
                details: [
                    {
                        risk_level: {
                            normal:
                                'An above average rate of Consent Mismatches has been detected for this entity as compared to other entities.',
                        },
                    },
                    {
                        risk_to_you:
                            'Identification of Consent Mismatches means that we have identified mismatches between the tracking behavior of an Entity in an ad and the consent given by one or more users. This puts you at risk of being in violation of Privacy Regulations.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Activate Consent Mismatch controls to identify the various creatives associated with this Entity that are guilty of not properly obeying the consent passed in the Consent String. These instances will be found in the Alert Log once activtated. This will help protect yourself and your users from instances of rogue tracking that go against what is passed in the Consent String.',
                        },
                    },
                ],
            },
            medium: {
                identified: 'Medium',
                details: [
                    {
                        risk_level: {
                            normal:
                                'An average rate of Consent Mismatches have been detected for this entity as compared to other entities.',
                        },
                    },
                    {
                        risk_to_you:
                            'Identification of Consent Mismatches means that we have identified mismatches between the tracking behavior of an Entity in an ad and the consent given by one or more users. This puts you at risk of being in violation of Privacy Regulations.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Activate Consent Mismatch controls to identify the various creatives associated with this Entity that are guilty of not properly obeying the consent passed in the Consent String. These instances will be found in the Alert Log once activtated. This will help protect yourself and your users from instances of rogue tracking that go against what is passed in the Consent String.',
                        },
                    },
                ],
            },
            low: {
                identified: 'Low',
                details: [
                    {
                        risk_level: {
                            normal:
                                'Very few Consent Mismatches have been detected for this entity as compared to other entities.',
                        },
                    },
                    {
                        risk_to_you:
                            'Identification of Consent Mismatches means that we have identified mismatches between the tracking behavior of an Entity in an ad and the consent given by one or more users. This puts you at risk of being in violation of Privacy Regulations.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Make sure you have Consent Mismatch controls toggled on to protect yourself and your users from instances of rogue tracking that go against what is passed in the Consent String.',
                        },
                    },
                ],
            },
            empty: {
                identified: 'No Mismatches',
                details: [
                    {
                        risk_level: {
                            normal:
                                'No Consent Mismatches have been detected for this entity.',
                        },
                    },
                    {
                        risk_to_you:
                            'Identification of Consent Mismatches means that we have identified mismatches between the tracking behavior of an Entity in an ad and the consent given by one or more users. This puts you at risk of being in violation of Privacy Regulations.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Make sure you have Consent Mismatch controls toggled on to protect yourself and your users from instances of rogue tracking that go against what is passed in the Consent String.',
                        },
                    },
                ],
            },
        },
    },
    browser_fingerprinting_risk: {
        risk_factor: 'Browser Fingerprinting',
        risk_info: {
            medium: {
                identified: 'Detected',
                details: [
                    {
                        risk_level: {
                            normal:
                                'We have previously identified Browser Fingerprinting for this entity but not in the last 30 days.',
                            higher:
                                'Browser fingerprinting has been detected in last 30 days.',
                        },
                    },
                    {
                        risk_to_you:
                            'This is a form of cookieless tracking that users can’t easily opt out of. Browser fingerprinting is when the entity looks at the characteristics of a device and triangulates that information to identify a user and follow them as they visit websites or apps.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Make sure you have Browser Fingerprinting controls toggled on to protect your users from this form of tracking.',
                        },
                    },
                ],
            },
            low: {
                identified: 'Not Detected',
                details: [
                    {
                        risk_level: {
                            normal:
                                'We have never identified Browser Fingerprinting for this entity.',
                        },
                    },
                    {
                        risk_to_you:
                            'This is a form of cookieless tracking that users can’t easily opt out of. Browser fingerprinting is when the entity looks at the characteristics of a device and triangulates that information to identify a user and follow them as they visit websites or apps.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Make sure you have Browser Fingerprinting controls toggled on to protect your users from this form of tracking.',
                        },
                    },
                ],
            },
        },
    },
    local_storage_risk: {
        risk_factor: 'Local Storage Tracking',
        risk_info: {
            medium: {
                identified: 'Detected',
                details: [
                    {
                        risk_level: {
                            normal:
                                'Local storage tracking was found in the last 30 days.',
                        },
                    },
                    {
                        risk_to_you:
                            'Local Storage is a form of cookieless tracking. Users are tracked by storing data in their browser without an expiration date. Similar to cookies, it does not always point to malicious intent.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'If you have a direct relationship with this entity, use the data from the Tracking Details tab to find out more about their actions. Reach out to the entity to understand what they are doing. If you do not have a direct relationship with this entity, reach out to the associated SSP to get more information. For TCF registered entities, make sure they are declaring Special Feature 2 in TCF.',
                        },
                    },
                ],
            },
            low: {
                identified: 'Not Detected',
                details: [
                    {
                        risk_level: {
                            normal:
                                'No local storage tracking was found in the last 30 days.',
                        },
                    },
                    {
                        risk_to_you:
                            'Local Storage is a form of cookieless tracking. Users are tracked by storing data in their browser without an expiration date. Similar to cookies, it does not always point to malicious intent.',
                    },
                ],
            },
        },
    },
    tcf_registration_risk: {
        risk_factor: 'TCF Registration Status',
        risk_info: {
            low: {
                identified: 'Registered',
                details: [
                    {
                        risk_level: {
                            normal: 'This entity is TCF registered.',
                        },
                    },
                    {
                        risk_to_you:
                            'If an entity is not TCF registered, then it may not be legally permitted to track users in Europe',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Make sure this entity is included in your list of approved vendors in your CMP.',
                        },
                    },
                ],
            },
            medium: {
                identified: 'Not Registered',
                details: [
                    {
                        risk_level: {
                            normal: 'This entity is not TCF Registered.',
                        },
                    },
                    {
                        risk_to_you:
                            'If an entity is not TCF registered, then it may not be legally permitted to track users in Europe',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'If you have a direct relationship with this entity, ask them why they are not TCF registered and how they are staying GDPR compliant. If you do not have a direct relationship with this entity, reach out to the associated SSP(s) to get more information.',
                        },
                    },
                ],
            },
        },
    },
    special_feature_risk: {
        risk_factor: 'Declared Special Feature 2 in TCF',
        risk_info: {
            medium: {
                identified: 'Present',
                details: [
                    {
                        risk_level: {
                            normal:
                                'This entity is TCF registered and Special Feature 2 was declared.',
                        },
                    },
                    {
                        risk_to_you:
                            'Special Feature 2 is essentially admission to fingerprinting which is a form of tracking that users can not easily opt out of.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'If you have a direct relationship with this entity, ask why they declared Special Feature 2 in their TCF registration. If you do not have a direct relationship with this entity, reach out to the associated SSP(s) to get more information. Enable Browser Fingerprinting detection/blocking to be alerted or block instances of fingerprinting.',
                        },
                    },
                ],
            },
            low: {
                identified: 'Not Present',
                details: [
                    {
                        risk_level: {
                            normal:
                                'This entity is TCF registered and Special Feature 2 was not declared.',
                        },
                    },
                    {
                        risk_to_you:
                            'Special Feature 2 is essentially admission to fingerprinting which is a form of tracking that users can not easily opt out of.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'Consider enabling Browser Fingerprinting detection/blocking to be alerted or block instances of fingerprinting in case it occurs in the future.',
                        },
                    },
                ],
            },
            empty: {
                identified: 'Not Applicable',
                details: [
                    {
                        risk_level: {
                            normal: 'This entity is not TCF registered.',
                        },
                    },
                    {
                        risk_to_you:
                            'Special Feature 2 is essentially admission to fingerprinting which is a form of tracking that users can not easily opt out of.',
                    },
                    {
                        next_steps: {
                            modify: false,
                            txt:
                                'If you have a direct relationship with this entity, ask them why they are not TCF registered and how they are staying GDPR compliant. If you do not have a direct relationship with this entity, reach out to the associated SSP(s) to get more information.',
                        },
                    },
                ],
            },
        },
    },
};

/*
     information that we want to display on the left and right pane in consent details tab
     GDPR:
        Left: tracking info, mismatch details,
            type of tracking identified, entity declared tcf 2.0 purposes,
            entity declared tcf 2.0 legitimate interest purposes
        Right: user session, consent string, consent details

    CCPA:
        Left: tracking info, mismatch details,
            type of tracking identified
        Right: user session, consent string,
    */
export const adTraceCMWidgets = {
    left: {
        gdpr: [
            {
                key: 1,
                name: 'tracking entity',
                type: 'tracking_info',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 2,
                name: 'mismatch details',
                type: 'mismatch_details',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 3,
                name: 'type of tracking identified:',
                type: 'tracking_identified',
                track_type: 'cookie',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
            {
                key: 4,
                name: 'type of tracking identified:',
                type: 'tracking_identified',
                track_type: 'local storage',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
            {
                key: 5,
                name: 'entity declared tcf 2 purposes',
                type: 'declared_purposes',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
            {
                key: 6,
                name: 'entity declared tcf 2 legitimate interest purposes',
                type: 'declared_legint_purposes',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
        ],
        us_privacy: [
            {
                key: 1,
                name: 'tracking entity',
                type: 'tracking_info',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 2,
                name: 'mismatch details',
                type: 'mismatch_details',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 3,
                name: 'type of tracking identified:',
                type: 'tracking_identified',
                track_type: 'cookie',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
            {
                key: 4,
                name: 'type of tracking identified:',
                type: 'tracking_identified',
                track_type: 'local_storage',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
        ],
    },
    right: {
        gdpr: [
            {
                key: 1,
                name: 'user session',
                type: 'user_session',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 2,
                name: 'consent string',
                type: 'consent_string',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
            {
                key: 3,
                name: 'consent details',
                type: 'consent_details',
                collapseOptions: {
                    collapsible: true,
                    collapsed: true,
                },
            },
        ],
        us_privacy: [
            {
                key: 1,
                name: 'user session',
                type: 'user_session',
                collapseOptions: {
                    collapsible: false,
                    collapsed: null,
                },
            },
            {
                key: 2,
                name: 'consent string',
                type: 'consent_string',
                collapseOptions: {
                    collapsible: true,
                    collapsed: false,
                },
            },
        ],
    },
};
