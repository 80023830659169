import React from 'react';
import LinkCopy from './LinkCopy';
import ImageContainer from './ImageContainer';

const LandingPageTab = props => {
    const { landing_page } = props;
    if (landing_page) {
        return (
            <React.Fragment>
                <div className="row second-tab-titles pt-10">
                    <div className="col-9 col-md-9 col-sm-9">
                        <div className="d-flex flex-row">
                            <LinkCopy
                                type={'landing'}
                                landing_page={landing_page}
                                parentComp={'tab'}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-12 col-md-12 col-sm-12 pt-10">
                    {landing_page.screenshot && (
                        <ImageContainer
                            type={'landing'}
                            img_url={landing_page.screenshot}
                            openImageViewer={props.openImageViewer}
                        />
                    )}
                </div>
            </React.Fragment>
        );
    }

    return null;
};

export default LandingPageTab;
