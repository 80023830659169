import React from 'react';

const PurposeDeclaration = ({ list = {}, legitimate = false, iab }) => {
    let purposeArr = Object.entries(list);
    if (!iab || !iab.id) {
        purposeArr = [['N/A', 'Entity is not TCF Registered']];
    } else if (!purposeArr.length) {
        purposeArr = [['N/A', 'No Purpose Declared']];
    }

    return (
        <div className="scrollable-table">
            <div className="purposes header">
                <span>Purpose ID</span>
                <span>Purpose Definition</span>
            </div>
            <div className="purposes-body">
                {purposeArr.map(([key, value]) => (
                    <div className="purposes" key={`key-${key}--${value}`}>
                        <span>{key}</span>
                        <span>{value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PurposeDeclaration;
