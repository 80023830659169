// Needs to be in unsecure build.

import * as actionTypes from './ActionTypes';
import * as resetPasswordApis from '../api/ResetPasswordApi';
import { beginApiCall, apiCallError } from './ApiCallStatusActions';
import { getToastr } from '../utils/utils';

export const sendResetPasswordRequest = email => {
    return dispatch => {
        dispatch(beginApiCall());
        return resetPasswordApis
            .sendResetRequest(email)
            .then(json => {
                json.success
                    ? getToastr('success', json.message)
                    : getToastr('error', json.message);
                dispatch({ type: actionTypes.RESET_PASSWORD_REQ_SUCCESS });
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};

export const resetPassword = passwordDetails => {
    return dispatch => {
        dispatch(beginApiCall());
        return resetPasswordApis
            .resetPassword(passwordDetails)
            .then(json => {
                if (json.success) {
                    dispatch({ type: actionTypes.PASSWORD_RESET_SUCCESS });
                } else {
                    dispatch(apiCallError());
                    dispatch({
                        type: actionTypes.PASSWORD_RESET_FAILURE,
                        success: false,
                        message: json.message,
                    });
                }
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};

export const sendValidateSetupPasswordRequest = token => {
    return dispatch => {
        dispatch(beginApiCall());
        return resetPasswordApis
            .validateSetupRequest(token)
            .then(json => {
                dispatch({
                    type: actionTypes.VALIDATE_SETUP_PASSWORD_REQ_SUCCESS,
                });
                if (json.success) {
                    return json.message;
                }
                throw new Error(json.message);
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};

export const sendSetupPasswordRequest = passwordDetails => {
    return dispatch => {
        dispatch(beginApiCall());
        return resetPasswordApis
            .setupPassword(passwordDetails)
            .then(json => {
                if (json.success) {
                    dispatch({
                        type: actionTypes.PASSWORD_VALIDATE_SETUP_SUCCESS,
                    });
                    return true;
                }
                dispatch(apiCallError());
                dispatch({
                    type: actionTypes.PASSWORD_VALIDATE_SETUP_FAILURE,
                    success: false,
                    message: json.message,
                });
                return json.message;
            })
            .catch(error => {
                dispatch(apiCallError());
                throw error;
            });
    };
};
