// Needs to be in unsecure build.

import fetch from 'cross-fetch';
import { ResetURL, SetupPasswordURL } from './ApiUrls';
import {validateResponse} from './ApiCommon';

export const sendResetRequest = (email) => {
    return fetch(`${ResetURL}`, {
        method: 'POST',
        body: JSON.stringify({ "email": email }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(validateResponse);
}

export const resetPassword = (pwdDetails) => {
    return fetch(`${ResetURL}/${pwdDetails.token}`, {
        method: 'POST',
        body: JSON.stringify({
            "password_verification": pwdDetails.confirmPassword,
            "password": pwdDetails.password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(validateResponse);
}

export const validateSetupRequest = (token) => {
    return fetch(`${SetupPasswordURL}/${token}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(validateResponse);
}

export const setupPassword = (pwdDetails) => {
    return fetch(`${SetupPasswordURL}/${pwdDetails.token}`, {
        method: 'POST',
        body: JSON.stringify({
            "password_verification": pwdDetails.confirmPassword,
            "password": pwdDetails.password
        }),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(validateResponse);
}
