// Needs to be in unsecure build.

import React from 'react';
import { NavLink } from 'react-router-dom';

const MobileHeaderBeforeLogin = () => (
    <div className="sm-header-out navbar-brand col-sm-12 mr-0 d-block d-md-none fixed-top">
        <div className="row">
            <div className="col-3">
                <button className="btn btn-light hamburger-btn">
                    <span className="icon-hamburger-menu"></span>
                </button>
                <button className="btn btn-light close-hamburger-btn d-none">
                    <span className="icon-close"></span>
                </button>
            </div>
            <div className="col-6">
                <NavLink exact to="/" className="company-logo">
                </NavLink>
            </div>
        </div>
    </div>
);

export default MobileHeaderBeforeLogin;