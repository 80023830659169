import React from 'react';
import { ModalPopoverCustom } from '../../../../utils/utils';
import TooltipComponent from '../../../shared/TooltipComponent';

export const SessionInfo = ({ sessionData, url, gpc }) => {
    return (
        <div className="d-flex flex-row">
            <div className="d-flex flex-column data-row user-session">
                <div className="d-flex flex-row session">
                    <label className="titles">
                        Geography{' '}
                        <ModalPopoverCustom
                            trigger={['hover', 'focus']}
                            popoverContent={
                                <div>
                                    <p>
                                        Seeing an unexpected geo? Confiant
                                        utilizes the information provided by a
                                        publisher's Consent Management Platform
                                        (CMP) to determine whether a given
                                        impression should be evaluated for
                                        privacy compliance. In some cases,
                                        publishers apply one region's notice and
                                        consent requirements to all users (e.g.
                                        applying GDPR requirements to users
                                        located outside the European Union).
                                        While consent mismatches for users
                                        outside a regulatory region might not
                                        present a legal risk, we bring them to
                                        your attention because the mismatch is
                                        inconsistent with the wishes of the
                                        publisher and the user.
                                    </p>
                                </div>
                            }
                        >
                            <i className="fa fa-info-circle" />
                        </ModalPopoverCustom>
                    </label>
                    <div>
                        <label>
                            {sessionData &&
                            sessionData.geo &&
                            sessionData.geo.country
                                ? sessionData.geo.country
                                : 'Data not found'}
                        </label>
                    </div>
                </div>
                <div className="d-flex flex-row session">
                    <label className="titles">Page URL</label>
                    <div className="url">
                        <label>{url ? url : 'Data not found'}</label>
                    </div>
                </div>
                <div className="d-flex flex-row session">
                    <label className="titles">
                        GPC Status{' '}
                        <ModalPopoverCustom
                            trigger={['hover', 'focus']}
                            popoverContent={
                                <div>
                                    <p>
                                        Companies covered by the CCPA must honor
                                        a “global privacy control” (GPC) if it
                                        is present as a method of opting out of
                                        the sale of personal information.
                                    </p>
                                </div>
                            }
                        >
                            <i className="fa fa-info-circle" />
                        </ModalPopoverCustom>
                    </label>
                    <div>
                        <label>
                            {gpc ? 'Signal Present' : 'Signal Not Present'}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TrackingInfo = ({ entity, iab }) => {
    return (
        <div className="d-flex flex-column align-items-start titles">
            <span className="entity mt-n2">
                {entity && entity.name ? entity.name : 'Unknown'}
            </span>
            <div className="d-flex flex-column pl-3 w-100">
                <table className="tracking-entity-table">
                    <tbody>
                        <tr>
                            <th>Domain</th>
                            <td>
                                {(entity && entity.domain && (
                                    <span>{entity.domain}</span>
                                )) ||
                                    null}
                            </td>
                        </tr>
                        <tr>
                            <th>TCF Registration Name and ID</th>
                            <td>
                                {iab && iab.id
                                    ? `${iab.name} (ID:${iab.id})`
                                    : 'Not Registered'}
                            </td>
                        </tr>
                        <tr>
                            <th>Privacy Policy for Contact Info</th>
                            <td>
                                <TooltipComponent
                                    key={iab.id}
                                    placement="right"
                                    isLink={entity && entity.policy_url}
                                    replaceLink={false}
                                >
                                    <span>
                                        {entity && entity.policy_url
                                            ? entity.policy_url
                                            : 'Unknown'}
                                    </span>
                                </TooltipComponent>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const getTableHeaders = type => {
    let t = type ? type : 'empty';
    const headers = {
        cookie: ['Cookie Name', 'Life Span'],
        'local storage': ['Key Name'],
        empty: [''],
    };

    return (
        <thead className={t}>
            <tr key={`tracking-table--${t}`}>
                {headers[t].map(h => (
                    <th key={`header-col--${t}-${h}`}>{h}</th>
                ))}
            </tr>
        </thead>
    );
};

const getTableRows = (type, entries) => {
    let t = type ? type : 'empty';
    const lookup = {
        cookie: ['name', 'lifespan'],
        'local storage': ['name'],
        empty: [''],
    };

    return (
        <tbody className={t} key={`tracking-body--${type}`}>
            {entries.map(entry => (
                <tr
                    key={`tracking-body-row--${t}-${
                        entry.name
                    }-${Math.random()}`}
                >
                    {lookup[t].map(l => (
                        <td
                            key={`tracking-body-col--${t}-${entry.name}-${l}`}
                            className="long-text"
                        >
                            <TooltipComponent
                                key={`tracking-body-col-tooltip--${t}-${entry.name}-${l}`}
                                placement="right"
                            >
                                {entry[l]}
                            </TooltipComponent>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

export const TrackingIdentified = ({ data, track_type }) => {
    let entries = [];

    if (
        !data ||
        (data &&
            ((Array.isArray(data) && !data.length) || !Array.isArray(data)))
    ) {
        track_type === 'cookie'
            ? entries.push({
                  name: 'Tracking Details Unavailable',
                  lifespan: '-',
              })
            : entries.push({ name: 'Tracking Details Unavailable' });
    } else {
        entries = data;
    }

    return (
        <div className="scrollable-tracking-table data-row">
            <table>
                {getTableHeaders(track_type)}
                {getTableRows(track_type, entries)}
            </table>
        </div>
    );
};
