import {
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    HIDE_COMPANY_NAME,
    USER_LIST_SUCCESS,
    EXPIRED_PASSWORD,
    ACTIVATE_DEMO_MODE,
} from '../actions/ActionTypes';

const initialState = {
    isLoggedIn: false,
    isImpersonating: false,
    isCompanyVisible: true,
    role: null,
    subUser: false,
    readOnly: false,
    searchResult: null,
    isExpired: false,
    knowledgeOwlToken: null,
    demoMode: null,
};

export default function AuthReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                isImpersonating: action.isImpersonating,
                role: action.role,
                subUser: action.isSubUser,
                readOnly: action.isReadOnly,
                isExpired: false,
            };

        case USER_LIST_SUCCESS:
            let list = action.json.list;

            list = list.map(l => {
                let newItem = {
                    value: l.email,
                    label:
                        l.company !== ''
                            ? `${l.email} (${l.company})`
                            : l.email,
                };

                return newItem;
            });
            return { ...state, isExpired: false, searchResult: list };
        case EXPIRED_PASSWORD:
            return { ...state, isExpired: action.isExpired };
        case LOGOUT_SUCCESS:
            return { ...initialState };

        case HIDE_COMPANY_NAME:
            return { ...state, isExpired: false, isCompanyVisible: false };
        case ACTIVATE_DEMO_MODE:
            return { ...state, demoMode: true };
        default:
            return state;
    }
}
