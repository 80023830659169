import React, { useState } from 'react';
import ConsentDetailHeader from './ConsentDetailHeader';

const ConsentDetailComponent = props => {
    const [hidden, setHidden] = useState(props.collapsed);

    return (
        <div className="d-flex flex-column pb-2">
            <div className="d-flex flex-row">
                {(props.collapsible && (
                    <h2
                        className="title arrow consent-detail-header"
                        onClick={() => setHidden(!hidden)}
                    >
                        {hidden ? '\u25B8' : '\u25BE'}
                    </h2>
                )) ||
                    null}
                <ConsentDetailHeader
                    key={props.id}
                    id={props.id}
                    title={props.title}
                    data={
                        props.type === 'tracking_identified'
                            ? props.trackingType
                            : undefined
                    }
                    handleClick={() => setHidden(!hidden)}
                    collapsible={props.collapsible}
                />
            </div>
            {(props.children &&
                !hidden &&
                React.cloneElement(props.children)) ||
                null}
        </div>
    );
};

export default ConsentDetailComponent;
