import React from 'react';

const tableData = {
    headers: ['Component', 'Possible Values', 'Definition'],
    data: [
        ['Specification Version', 'Number', 'Version used to encode string'],
        [
            'Explicit Notice/Opportunity to Opt Out',
            ['N = No', 'Y = Yes', '- = Not Applicable'],
            'Has explicit notice been provided as required by CCPA?',
        ],
        [
            'Opt-Out Sale',
            ['N = No', 'Y = Yes', '- = Not Applicable (CCPA does not apply'],
            'Has user opted-out of sale of their personal information?',
        ],
        [
            'LSPA Covered Transaction',
            ['N = No', 'Y = Yes', '- = Not Applicable'],
            'Publisher is a signatory of the IAB Limited Service Provider Agreement and declares transaction is covered as a “Covered Opt Out Transaction” or a “Non Opt Out Transaction.',
        ],
    ],
};

const masterSplitter = (str, sign) => {
    return str.split(sign);
};
const processArray = arr => {
    let a = arr.map(a => {
        let b = masterSplitter(a, '=');
        let rand = Math.random();
        return (
            <ul key={`unordered-list-${rand}`}>
                <li key={`list-${rand}`}>
                    <b>{b[0]}</b> = {b[1]}
                </li>
            </ul>
        );
    });
    return a;
};
export const ConsentStringContainer = ({ mismatchType, consentString }) => {
    let tableWidget =
        mismatchType === 'us_privacy' ? (
            <div className="consent-string-details-table py-2">
                <table>
                    <thead>
                        <tr key="ccpa-string-details-tbl">
                            {tableData.headers.map(header => (
                                <th key={`${header}--header`}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.data.map(datum => (
                            <tr
                                key={`row-ccpa-str-detail-${datum[0]
                                    .split(' ')
                                    .join('')}`}
                            >
                                {datum.map(dat => (
                                    <td
                                        key={`row-column-ccpa-${Math.random()}`}
                                    >
                                        {Array.isArray(dat)
                                            ? processArray(dat)
                                            : dat}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : null;
    return (
        <div className="d-flex flex-column data-row">
            {(consentString && (
                <div className="consent-string-box">
                    <div className="p-2">
                        <span className="p-1">{consentString}</span>
                    </div>
                </div>
            )) || <label>Unable to reconcile consent</label>}
            {tableWidget}
        </div>
    );
};

export default ConsentStringContainer;
