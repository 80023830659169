import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PIE_CHART_COLORS } from '../../constants/CommonConstants';

const ImpactedBrowsers = ({ browsers }) => {
    let browsersList = [],
        pieOptions = {};

    if (browsers && Object.keys(browsers).length) {
        Object.keys(browsers).forEach(function(browser) {
            browsersList.push({
                name: browser,
                y: browsers[browser],
            });
        });
        browsersList.sort((a, b) => {
            return b.y < a.y ? -1 : b.y > a.y ? 1 : 0;
        });

        pieOptions = {
            title: {
                text: '',
            },
            series: [
                {
                    name: 'Browsers',
                    data: browsersList,
                    colorByPoint: true,
                },
            ],
            chart: {
                type: 'pie',
                height: 260,
                spacingLeft: 0,
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'left',
                verticalAlign: 'bottom',
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    showInLegend: true,
                    colors: PIE_CHART_COLORS,
                },
            },
            tooltip: {
                pointFormat: '{point.y} impressions',
                shared: true,
            },
            credits: {
                enabled: false,
            },
        };
    }

    return (
        <div className="chart-wrapper">
            <HighchartsReact highcharts={Highcharts} options={pieOptions} />
        </div>
    );
};

export default ImpactedBrowsers;
