import React from 'react';

class ResourcesLoaded extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            isExpanded: false,
        };

        this.state = this.initialState;
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
    }

    handleExpandToggle(e) {
        e.preventDefault();
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded });
    }

    render() {
        const { resources } = this.props;
        const resource =
            resources && resources.length
                ? resources.map(i => <li key={Math.random()}>{i}</li>)
                : null;

        let res = [];
        if (resource && resource.length) {
            res =
                resource.length < 5 || this.state.isExpanded
                    ? resource
                    : resource.slice(0, 5);
        }

        return (
            <React.Fragment>
                <ul
                    className="leftpane-list--resources"
                    style={{ paddingLeft: '0 !important' }}
                >
                    {res}
                </ul>

                {resources && resources.length > 5 ? (
                    <a
                        href="/#"
                        className="table-toggler"
                        onClick={this.handleExpandToggle}
                    >
                        {this.state.isExpanded ? 'Show Less' : 'Show More'}
                    </a>
                ) : null}
            </React.Fragment>
        );
    }
}

export default ResourcesLoaded;
