import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const ADMModal = React.memo(({ adm, show, onHide }) => (
    <Modal id="admModal"
           show={show}
           onHide={onHide}
           tabIndex='-1'
           aria-labelledby='sdmModalTitle'
           aria-hidden='true'
           centered>
        <Modal.Header closeButton>
            <Modal.Title>
                <h5 className='modal-title'>AD MARKUP</h5>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='adm-container'>
                <div >
                    <textarea rows="10" readOnly defaultValue={adm} />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <CopyToClipboard text={adm}><button type='button' className='btn btn-secondary width-125' >COPY</button></CopyToClipboard>
        </Modal.Footer>
    </Modal>
));

export default ADMModal;