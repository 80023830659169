import fetch from 'cross-fetch';
import { validateResponse } from './ApiCommon';
import {
    // #if process.env.BUILD_SECURE === '1'
//     SettingsURL,
//     AccountURL,
//     AccountUsageURL,
//     EmailPreferencesURL,
//     EmailPreferenceUpdateURL,
//     EmailPreferencesDeleteURL,
//     SelectedViewURL,
//     PropertiesURL,
//     DfpURL,
//     DfpAuthorizeURL,
//     UserPrefsURL,
    // #endif
    DfpCallbackURL,
} from './ApiUrls';
import {
    // #if process.env.BUILD_SECURE === '1'
//     POST_NOBODY_REQUEST,
//     DELETE_REQUEST,
    // #endif
    GET_REQUEST,
} from './ApiConstants';

// #if process.env.BUILD_SECURE === '1'
// export const getSettings = signal => {
//     return fetch(SettingsURL, { ...GET_REQUEST, signal }).then(
//         validateResponse
//     );
// };
// 
// export const updateSettings = (settings, signal) => {
//     return fetch(SettingsURL, {
//         method: 'POST',
//         body: JSON.stringify(settings),
//         credentials: 'include',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         signal,
//     }).then(validateResponse);
// };
// 
// export const getAccount = signal => {
//     return fetch(AccountURL, { ...GET_REQUEST, signal }).then(validateResponse);
// };
// 
// export const updateAccount = (settings, signal) => {
//     return fetch(AccountURL, {
//         method: 'POST',
//         body: JSON.stringify(settings),
//         credentials: 'include',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         signal,
//     }).then(validateResponse);
// };
// 
// export const getAccountUsage = signal => {
//     return fetch(AccountUsageURL, { ...GET_REQUEST, signal }).then(
//         validateResponse
//     );
// };
// 
// export const getEmailPreferences = signal => {
//     return fetch(EmailPreferencesURL, { ...GET_REQUEST, signal }).then(
//         validateResponse
//     );
// };
// 
// export const deleteEmailPreferences = (data, signal) => {
//     return fetch(EmailPreferencesDeleteURL, {
//         ...DELETE_REQUEST,
//         body: JSON.stringify(data),
//         signal,
//     }).then(validateResponse);
// };
// 
// export const updateEmailPreference = (data, signal) => {
//     return fetch(EmailPreferenceUpdateURL, {
//         ...POST_NOBODY_REQUEST,
//         body: JSON.stringify(data),
//         signal,
//     }).then(validateResponse);
// };
// 
// export const createEmailPreference = (data, signal) => {
//     return fetch(EmailPreferencesURL, {
//         ...POST_NOBODY_REQUEST,
//         body: JSON.stringify(data),
//         signal,
//     }).then(validateResponse);
// };
// 
// export const getProperties = () => {
//     return fetch(`${PropertiesURL}`, GET_REQUEST).then(validateResponse);
// };
// 
// // export const getProperties = () => {
// //     return fetch(`${PropertiesURL}`, {
// //         headers: {
// //             Accept: 'application/json',
// //             'Content-Type': 'application/json',
// //             Cookies: document.cookie,
// //         },
// //         method: 'GET',
// //     }).then(validateResponse);
// // };
// 
// export const getViews = signal => {
//     return fetch(SelectedViewURL, { ...GET_REQUEST, signal }).then(
//         validateResponse
//     );
// };
// 
// export const getDFPAccounts = signal => {
//     return fetch(DfpURL, { ...GET_REQUEST, signal }).then(validateResponse);
// };
// 
// export const deleteDFPAccount = (dfpId, signal) => {
//     return fetch(`${DfpURL}/${dfpId}`, {
//         method: 'DELETE',
//         credentials: 'include',
//         signal,
//     }).then(validateResponse);
// };
// 
// #endif

export const getDFPLink = signal => {
    return fetch(`${DfpAuthorizeURL}`, { ...GET_REQUEST, signal }).then(
        validateResponse
    );
};

export const dfpApiCallback = (code, signal) => {
    return fetch(`${DfpCallbackURL}`, {
        method: 'POST',
        body: JSON.stringify(code),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    }).then(validateResponse);
};

export const getUserPrefs = signal => {
    return fetch(`${UserPrefsURL}`, { ...GET_REQUEST, signal }).then(
        validateResponse
    );
};

export const updateUserAPIVersion = (version, signal) => {
    return fetch(`${UserPrefsURL}/update`, {
        method: 'POST',
        body: JSON.stringify({ rtb_external_version: version }),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        signal,
    }).then(validateResponse);
};
