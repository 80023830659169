import {
    // #if process.env.BUILD_SECURE === '1'
//     LOAD_ALERT_LOG_REPORT_SUCCESS,
//     BLOCK_ALERT_LOG_REPORT_SUCCESS,
//     CREATIVE_BLOCK_STATUS_SUCCESS,
    // #endif
    LOAD_SCAN_LOG_REPORT_SUCCESS,
} from './ActionTypes';

// #if process.env.BUILD_SECURE === '1'
// import {
//     getAlertLogReport,
//     blockPref,
//     blockUnblockCreative,
// } from '../api/AlertLogApi';
// import { getScanLogReport } from '../api/SSPAlertLogApi';
// import { getToastr } from '../utils/utils';
// #endif

import { getScanLogReportByTi } from '../api/SSPAlertLogApi';
import { beginApiCall, endApiCall } from './ApiCallStatusActions';
import { dispatchApi } from './helpers';
import * as utils from '../utils/utils';

// #if process.env.BUILD_SECURE === '1'
// export const loadAlertLogReportSuccess = alertLogReport => ({
//     type: LOAD_ALERT_LOG_REPORT_SUCCESS,
//     alertLogReport,
// });
// 
// export const loadAlertLogReport = (
//     issueId,
//     reportName,
//     creativeType,
//     dates,
//     property
// ) => {
//     return dispatchApi(
//         beginApiCall,
//         getAlertLogReport.bind(this, issueId, creativeType, {
//             dates,
//             property_id: utils.checkEmptyField(property),
//         }),
//         (dispatch, json) => {
//             if (!json.success) {
//                 utils.getToastr('error', 'Error with loading the report.');
// 
//                 throw Error(json.status || 'Error.');
//             }
//             return dispatch(
//                 loadAlertLogReportSuccess({
//                     ...json.report,
//                     issueId: issueId,
//                     reportName: reportName,
//                     creativeType: creativeType,
//                     dates: dates,
//                 })
//             );
//         },
//         dispatch => {
//             dispatch(endApiCall());
//         }
//     );
// };
// 
// export const blockAlertLogReportSuccess = (id, status) => ({
//     type: BLOCK_ALERT_LOG_REPORT_SUCCESS,
//     id,
//     status,
// });
// 
// export const blockIssue = (type, id, status) => {
//     return dispatchApi(
//         beginApiCall,
//         blockPref.bind(this, type, id, status),
//         (dispatch, json) => {
//             if (!json.success) {
//                 throw Error(json.status || 'Error.');
//             }
//             dispatch(blockAlertLogReportSuccess(id, status));
//             return json;
//         }
//     );
// };
// 
// export const blockUnblockCreativeSuccess = blocking_status => ({
//     type: CREATIVE_BLOCK_STATUS_SUCCESS,
//     blocking_status,
// });
// 
// export const changeCreativeBlockingStatus = (tpCRID, advertiserID, action) => {
//     return dispatchApi(
//         beginApiCall,
//         blockUnblockCreative.bind(this, tpCRID, advertiserID, action),
//         (dispatch, json) => {
//             const toastrErrorMsg = 'Something went wrong.';
// 
//             if (!json.success) {
//                 getToastr('error', toastrErrorMsg);
//                 throw Error(json.msg || 'Error.');
//             }
//             let toastrSuccessMsg = `Creative is set to ${
//                 action === 'block' ? 'Block' : 'Allow'
//             }`;
// 
//             getToastr('success', toastrSuccessMsg);
// 
//             let blockingStatus = action === 'block' ? 'block' : 'allow';
//             return dispatch(blockUnblockCreativeSuccess(blockingStatus));
//         }
//     );
// };
// 
// export const scanLogReport = (id, type) => {
//     return dispatchApi(
//         beginApiCall,
//         getScanLogReport.bind(this, id, type),
//         (dispatch, json) => {
//             if (!json.success) {
//                 throw Error(json.status || 'Error.');
//             }
//             return dispatch(
//                 loadScanLogReportSuccess({
//                     ...json,
//                 })
//             );
//         },
//         dispatch => {
//             dispatch(endApiCall());
//         }
//     );
// };
// #endif

export const scanLogReportByTi = (ti, token) => {
    return dispatchApi(
        beginApiCall,
        getScanLogReportByTi.bind(this, ti, token),
        (dispatch, json) => {
            if (!json.success) {
                throw Error(json.status || 'Error');
            }
            return dispatch(loadScanLogReportSuccess({ ...json }));
        },
        dispatch => {
            dispatch(endApiCall());
        }
    );
};

export const loadScanLogReportSuccess = scanLogReport => ({
    type: LOAD_SCAN_LOG_REPORT_SUCCESS,
    scanLogReport,
});
