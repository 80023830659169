// Needs to be in unsecure build.

import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/ResetPasswordActions';
import { getToastr } from '../../utils/utils';
import {
    FormWithConstraints,
    FieldFeedbacks,
    FieldFeedback,
    Input,
} from 'react-form-with-constraints';
import { CompanyNavigations } from '../Navigations/CompanyNavigations';
import MobileHeaderBeforeLogin from '../Navigations/MobileHeaderBeforeLogin';

class SetupPassword extends React.Component {
    state = {
        email: '',
        invalid: null,
        success: null,
    };

    componentDidMount() {
        let that = this;
        this.props
            .validateSetup(this.props.match.params.hash)
            .then(res => {
                that.setState({ invalid: false });
                that.setState(res);
            })
            .catch(() => {
                that.setState({ invalid: true });
            });
    }

    handleChange = async e => {
        if (e.target.name === 'password')
            this.setState({ password: e.target.value });
        document.getElementById('confirmPassword').setCustomValidity('');
        await this.setupForm.validateFields(e.target);
    };

    handleSubmit = async e => {
        let that = this;

        e.preventDefault();
        const target = e.target;
        if (target.password.value !== target.confirmPassword.value) {
            document
                .getElementById('confirmPassword')
                .setCustomValidity('error');
            await this.setupForm.validateFields();
            return false;
        }
        await this.setupForm.validateForm();
        const formIsValid = this.setupForm.isValid();
        if (formIsValid) {
            let pwdDetails = {
                password: target.password.value,
                confirmPassword: target.confirmPassword.value,
                token: this.getToken(),
            };
            this.props.setupPassword(pwdDetails).then(res => {
                if (res === true) {
                    that.setState({ success: true });
                } else {
                    getToastr('error', res);
                }
            });
            target.password.value = '';
            target.confirmPassword.value = '';
        }
    };

    getToken = () => {
        return this.props.match.params.hash;
    };

    render() {
        let { email, invalid, success } = this.state;

        // setup has valid token, show form
        let validSetup =
            success !== true && invalid === false ? (
                <div>
                    <div className="page-header-out col-md-9 ml-sm-auto col-lg-10 px-4 d-none d-md-block fixed-top">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                            <h1>Setup your password</h1>
                        </div>
                    </div>
                    <MobileHeaderBeforeLogin />
                    <div className="page-header-out d-block d-md-none">
                        <div className="">
                            <h1>Setup your password</h1>
                        </div>
                    </div>
                    <div className="pt-3 mb-3 border-bottom">
                        <div className="dropdown dropdown-big">&nbsp;</div>
                    </div>

                    <div className="common-container">
                        <FormWithConstraints
                            onSubmit={this.handleSubmit}
                            ref={form => (this.setupForm = form)}
                            noValidate
                        >
                            <div className="form-group row row-form-mb">
                                <label
                                    htmlFor="confirmPassword"
                                    className="col-lg-2 col-md-3 col-form-label"
                                >
                                    Email
                                </label>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <Input
                                        type="string"
                                        disabled="disabled"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        value={email}
                                    />
                                </div>
                            </div>

                            <div className="form-group row row-form-mb">
                                <label
                                    htmlFor="password"
                                    className="col-lg-2 col-md-3 col-form-label"
                                >
                                    Password
                                </label>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <Input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        id="password"
                                        placeholder="Enter Password"
                                        defaultValue=""
                                        onChange={this.handleChange}
                                        required
                                        pattern=".{8,}"
                                    />
                                    <FieldFeedbacks for="password">
                                        <FieldFeedback when="valueMissing">
                                            Password is required.
                                        </FieldFeedback>
                                        <FieldFeedback when="patternMismatch">
                                            Password should be at least 8
                                            characters long.
                                        </FieldFeedback>
                                        <FieldFeedback
                                            when={value => !/\d/.test(value)}
                                        >
                                            Password should contain at least one
                                            number.
                                        </FieldFeedback>
                                        <FieldFeedback
                                            when={value =>
                                                !/[a-zA-Z]/.test(value)
                                            }
                                        >
                                            Password should contain at least one
                                            letter.
                                        </FieldFeedback>
                                        <FieldFeedback
                                            when={value =>
                                                !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(
                                                    value
                                                )
                                            }
                                        >
                                            Password should contain at least one
                                            special character.
                                        </FieldFeedback>
                                    </FieldFeedbacks>
                                </div>
                            </div>

                            <div className="form-group row row-form-mb">
                                <label
                                    htmlFor="confirmPassword"
                                    className="col-lg-2 col-md-3 col-form-label"
                                >
                                    Confirm Password
                                </label>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <Input
                                        type="password"
                                        required
                                        className="form-control"
                                        name="confirmPassword"
                                        id="confirmPassword"
                                        placeholder="Password"
                                        defaultValue=""
                                        onChange={this.handleChange}
                                    />
                                    <FieldFeedbacks for="confirmPassword">
                                        <FieldFeedback when="valueMissing">
                                            Confirm Password is required.
                                        </FieldFeedback>
                                        <FieldFeedback when="*">
                                            Passwords don't match.
                                        </FieldFeedback>
                                    </FieldFeedbacks>
                                </div>
                            </div>

                            <div className="form-group row row-form-mb pt-4">
                                <label className="col-lg-2 col-md-3 col-form-label"></label>
                                <div className="col-md-5">
                                    <button
                                        type="submit"
                                        className="btn btn-secondary mr-4"
                                    >
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </FormWithConstraints>
                    </div>
                </div>
            ) : (
                ''
            );

        // setup is invalid
        let invalidSetup =
            success !== true && invalid === true ? (
                <div>
                    <h1>Supplied Setup Token is Invalid</h1>
                    <h5>
                        An error occurred while validating this setup token.
                        Please double check the url and try again.
                    </h5>
                </div>
            ) : (
                ''
            );

        // password was setup
        let successfulSetup =
            success === true ? (
                <div>
                    <h1>Your password has been successfully updated.</h1>
                    <h5>
                        You can now <a href="/login">login</a>.
                    </h5>
                </div>
            ) : (
                ''
            );

        return (
            <div className="container-fluid no-padding--left mlr-xs-none">
                <CompanyNavigations />

                {validSetup}
                {invalidSetup}
                {successfulSetup}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        validateSetup: token =>
            dispatch(actions.sendValidateSetupPasswordRequest(token)),
        setupPassword: passwordDetails =>
            dispatch(actions.sendSetupPasswordRequest(passwordDetails)),
    };
};

export default connect(null, mapDispatchToProps)(SetupPassword);
