// Needs to be in unsecure build.

import { BEGIN_API_CALL, END_API_CALL, API_CALL_ERROR } from './ActionTypes';

export function beginApiCall(increment = 0) {
    return { type: BEGIN_API_CALL, increment: increment };
}

export function endApiCall(decrement = 0) {
    return { type: END_API_CALL, decrement: decrement }
}

export function apiCallError() {
    return { type: API_CALL_ERROR };
}

