import React from 'react';
import { NoDataComponent } from './CommonComponents';
import { BreakComponent } from '../shared/CommonComponents';
import { getFormattedNumber } from '../../utils/utils';

class NetworkLoad extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            isExpanded: false,
        };

        this.state = this.initialState;
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
        this.camelize = this.camelize.bind(this);
    }

    handleExpandToggle(e) {
        e.preventDefault();
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded });
    }

    camelize(str) {
        if (str.includes('html')) {
            return str.toUpperCase();
        }

        return str.replace(/^./, str[0].toUpperCase());
    }

    render() {
        const { network_load } = this.props;

        let summaries = [];
        if (network_load && network_load.length) {
            summaries =
                network_load.length < 5 || this.state.isExpanded
                    ? network_load
                    : network_load.slice(0, 5);
        }

        return (
            <div
                className="row second-tab-titles"
                style={{ paddingRight: '30px' }}
            >
                <div className="col-12" style={{ paddingLeft: '0' }}>
                    Network Load
                    <BreakComponent break={10} />
                </div>
                {(summaries && summaries.length && (
                    <div className="table-responsive impacted-provider-table">
                        <table className="table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Initial </th>
                                    <th>Polite </th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaries &&
                                    summaries.map(summary => (
                                        <tr key={summary.label}>
                                            <td>
                                                {this.camelize(summary.label)}
                                            </td>
                                            <td className="font-light">
                                                {getFormattedNumber(
                                                    summary.initial * 1024,
                                                    '0.0 b'
                                                )}
                                            </td>
                                            <td className="font-light">
                                                {getFormattedNumber(
                                                    summary.polite * 1024,
                                                    '0.0 b'
                                                )}
                                            </td>
                                            <td className="font-light">
                                                {getFormattedNumber(
                                                    summary.total * 1024,
                                                    '0.0 b'
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            {network_load && network_load.length > 5 ? (
                                <tfoot>
                                    <tr>
                                        <td
                                            colSpan="8"
                                            className="table-footer uppercase bt-none p-b-0"
                                        >
                                            <a
                                                href="/#"
                                                className="table-toggler"
                                                onClick={
                                                    this.handleExpandToggle
                                                }
                                            >
                                                {this.state.isExpanded
                                                    ? 'Show Less'
                                                    : 'Show More'}
                                            </a>
                                        </td>
                                    </tr>
                                </tfoot>
                            ) : null}
                        </table>
                    </div>
                )) || (
                    <div
                        className="col-4 col-sm-4"
                        style={{ paddingLeft: '0' }}
                    >
                        <NoDataComponent />{' '}
                    </div>
                )}
            </div>
        );
    }
}

export default NetworkLoad;
