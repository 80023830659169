// Needs to be in unsecure build.

export const GET_REQUEST = {
    method: 'GET',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json'
    }
};

export const POST_NOBODY_REQUEST = {
    method: 'POST',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json'
    }
};

// #if process.env.BUILD_SECURE === '1'
// export const DELETE_REQUEST = {
//     method: 'DELETE',
//     credentials: 'include',
//     headers: {
//         'Content-Type': 'application/json'
//     }
// };
// #endif
