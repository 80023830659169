import React from 'react';

const ImpactedCountries = ({geoLocations}) => {
        let geoList = [];

        let geos = [];

        for (let country_code in geoLocations) {

            let geo_location = geoLocations[country_code];
            geos.push({ ...geo_location, code: country_code });
        }

        for (let geo_location of geos.sort((a, b) => a.percent < b.percent ? 1 : -1)) {
            let percentage = parseInt(geo_location.percent * 100),
                listItem = (
                    <li key={geo_location.code} style={{ textTransform: 'none', fontWeight: 'normal', color: '#797d82' }}>
                        <span className={'flag-icon flag-icon-' + geo_location.code}></span>
                        {geo_location.name} {percentage >= 1 ? percentage : '< 1'}%
                            </li>
                );
            geoList.push(listItem);
        }

        return (
            <div style={{ marginLeft: '10px'}}>
                <ul className="list-unstyled">
                    {geoList}
                </ul>
            </div>
        )
    
}

export default ImpactedCountries;