import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import NestedModal from '../shared/CustomModal/NestedModal';
import { BreakComponent } from '../shared/CommonComponents';
import { NoDataComponent, NetworkContent } from './CommonComponents';
import LinkCopy from './LinkCopy';
import ImageContainer from './ImageContainer';
import LandingPageTab from './LandingPageTab';
import DailyImpression from './DailyImpression';
import ImpactedBrowsers from './ImpactedBrowsers';
import ImpactedCountries from './ImpactedCountries';
import CPMDistribution from './CPMDistribution';
import ImpactedProviders from './ImpactedProviders';
import NetworkLoad from './NetworkLoad';
import AdMarkUp from './AdMarkUp';
import ResourcesLoaded from './ResourcesLoaded';
import { getDateRange } from './helpers';

class CreativeReportTabsShared extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.type,
            activeTab: 1,
            nestedModal: false,
            openType: undefined,
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        this.openNestedModal = this.openNestedModal.bind(this);
        this.closeNested = this.closeNested.bind(this);

        this.evenKeyMap = this.props.evenKeyMap;
    }

    addDefaultSrc(ev) {
        ev.target.src = '/static/images/noscreenshot.png';
    }

    handleSelect(selectedTab) {
        // The active tab must be set into the state so that
        // the Tabs component knows about the change and re-renders.
        this.setState({
            activeTab: selectedTab,
        });
    }

    openNestedModal(type) {
        this.setState({ nestedModal: true, openType: type });
    }

    closeNested() {
        this.setState({ nestedModal: false, openType: undefined });
    }

    render() {
        const { openType } = this.state;
        const {
            screenshot,
            landing_page,
            threatInfo,
            adomains,
            networkLoad,
            links,
            textOcr,
            audioTranscription,
            ad_instance,
            resources,
            har,
            issue_type,
            issue_detail,
            isForceful,
            tabs,
            originType,
            hostCount,
            flagged_by_chrome,
            providers,
            impressions,
            browsers,
            geo_locations,
            cpm,
            dateRange,
            publishers,
            dates,
            video_url,
        } = this.props;

        let linkContent = null,
            audioText = audioTranscription ? audioTranscription : null,
            textContent = null;

        if (links) {
            let isBannedKW = issue_type === 'Banned Keywords';

            linkContent = links.map(i => (
                <li
                    style={{
                        color: isBannedKW && i === issue_detail && '#ef1e4a',
                    }}
                    key={Math.random()}
                >
                    {i}
                </li>
            ));
        }

        if (textOcr) {
            textContent = textOcr.map(i => <li key={Math.random()}>{i}</li>);
        }

        let date_range =
            dateRange || (dates ? getDateRange(dates.from, dates.to) : '');

        const multipleCharts = (
            <div className="row second-tab-titles pt-10 pr-30">
                <div className="col-6">
                    cpm distribution
                    <BreakComponent break={15} />
                    {(cpm && cpm.length > 0 && (
                        <CPMDistribution cpm={cpm} />
                    )) || <NoDataComponent />}
                </div>
                <div className="col-6">
                    daily detected impressions
                    <BreakComponent break={0} />
                    <span className="notitle-text"> (past 7 days)</span>
                    <BreakComponent break={5} />
                    {(impressions && impressions.length > 0 && (
                        <DailyImpression impressions={impressions} />
                    )) || <NoDataComponent />}
                </div>
            </div>
        );

        const insightContentTab = (
            <div className="row second-tab-titles pt-10">
                {originType === 'sandboxing' ? (
                    <div className="col-4 col-sm-4"></div>
                ) : (
                    <div className="col-4 col-sm-4">
                        CREATIVE SCREENSHOT
                        <br />
                        <br />
                        {screenshot ? (
                            <ImageContainer
                                img_url={screenshot}
                                openImageViewer={this.openNestedModal}
                            />
                        ) : (
                            <NoDataComponent />
                        )}
                        <BreakComponent break={15} />
                        <LinkCopy
                            type={'landing'}
                            landing_page={landing_page}
                        />
                        <BreakComponent break={30} />
                        <NetworkContent
                            networkLoad={networkLoad}
                            hostCount={hostCount}
                            flagged_by_chrome={flagged_by_chrome}
                        />
                        <BreakComponent break={15} />
                    </div>
                )}
                <div className="col-8 col-sm-8">
                    <ImpactedProviders
                        providers={providers}
                        dateRange={date_range}
                        publishers={publishers}
                    />
                    <BreakComponent break={15} />
                    {multipleCharts}
                </div>
            </div>
        );

        const insightContentTabPub = (
            <div
                className="row second-tab-titles"
                style={{ paddingTop: '10px' }}
            >
                <div className="col-4 col-sm-4">
                    CREATIVE SCREENSHOT
                    <br />
                    <br />
                    {screenshot ? (
                        <ImageContainer
                            img_url={screenshot}
                            openImageViewer={this.openNestedModal}
                        />
                    ) : (
                        <NoDataComponent />
                    )}
                    <BreakComponent break={15} />
                    <LinkCopy type={'landing'} landing_page={landing_page} />
                    <BreakComponent break={15} />
                    <div className="pb-15">adomain(s)</div>
                    {adomains ? (
                        Array.isArray(adomains) ? (
                            adomains.map(adomain => (
                                <LinkCopy
                                    key={adomain}
                                    type={'adomain'}
                                    adomain={adomain}
                                />
                            ))
                        ) : (
                            <LinkCopy
                                key={adomains}
                                type={'adomain'}
                                adomain={adomains}
                            />
                        )
                    ) : (
                        <div className="no-data-text">Data not available</div>
                    )}
                    <BreakComponent break={15} />
                </div>

                <div className="col-8 col-sm-8">
                    <div className="row second-tab-titles">
                        <div
                            className="col-4 col-sm-4"
                            style={{ paddingLeft: '0' }}
                        >
                            <NetworkContent
                                networkLoad={networkLoad}
                                hostCount={hostCount}
                                flagged_by_chrome={flagged_by_chrome}
                            />
                        </div>
                        {((linkContent || textContent) && (
                            <div className="col" style={{ paddingLeft: '0' }}>
                                Text Analyzed
                                <div className="pl-2">
                                    <ul className="leftpane-list--resources">
                                        {linkContent}
                                        {textContent}
                                    </ul>
                                </div>
                            </div>
                        )) ||
                            null}
                    </div>
                    <BreakComponent break={15} />
                    {(video_url && (
                        <div
                            className="col-8 col-sm-8"
                            style={{ paddingLeft: '0' }}
                        >
                            <LinkCopy
                                type={'video_url'}
                                video_url={video_url}
                            />
                        </div>
                    )) ||
                        null}
                </div>
            </div>
        );

        const insightContentTabSSP = (
            <div
                className="row second-tab-titles"
                style={{ paddingTop: '10px' }}
            >
                <div className="col-4 col-sm-4">
                    CREATIVE SCREENSHOT
                    <br />
                    <br />
                    {screenshot ? (
                        <ImageContainer
                            img_url={screenshot}
                            openImageViewer={this.openNestedModal}
                        />
                    ) : (
                        <NoDataComponent />
                    )}
                    <BreakComponent break={15} />
                    <LinkCopy type={'landing'} landing_page={landing_page} />
                    <BreakComponent break={15} />
                    <div className="pb-15">adomain(s)</div>
                    {adomains &&
                        (Array.isArray(adomains) ? (
                            adomains.map(adomain => (
                                <LinkCopy
                                    key={adomain}
                                    type={'adomain'}
                                    adomain={adomain}
                                />
                            ))
                        ) : (
                            <LinkCopy
                                key={adomains}
                                type={'adomain'}
                                adomain={adomains}
                            />
                        ))}
                    <BreakComponent break={15} />
                    <div className="pb-15">ad markup</div>
                    <AdMarkUp ad_instance={ad_instance} />
                </div>

                <div className="col-8 col-sm-8">
                    <NetworkLoad network_load={networkLoad} />
                    <BreakComponent break={15} />
                    <div className="row second-tab-titles">
                        <div
                            className="col-4 col-sm-4"
                            style={{ paddingLeft: '0' }}
                        >
                            Resources Loaded
                            <BreakComponent break={5} />
                            {(resources && resources.length && (
                                <div className="resources">
                                    <span
                                        style={{
                                            textTransform: 'none',
                                            fontWeight: 'normal',
                                        }}
                                    >
                                        Count: {resources.length}
                                    </span>
                                    {(resources.length && (
                                        <ResourcesLoaded
                                            resources={resources}
                                        />
                                    )) ||
                                        null}
                                </div>
                            )) || <NoDataComponent />}
                        </div>
                        {((linkContent || textContent || audioText) && (
                            <div className="col" style={{ paddingLeft: '0' }}>
                                Text Analyzed
                                <div className="pl-2">
                                    <ul className="leftpane-list--resources">
                                        {linkContent}
                                        {textContent}
                                        {audioText}
                                    </ul>
                                </div>
                            </div>
                        )) ||
                            null}
                        <BreakComponent break={15} />
                        {(video_url && (
                            <div
                                className="col-8 col-sm-8"
                                style={{ paddingLeft: '0' }}
                            >
                                <LinkCopy
                                    type={'video_url'}
                                    video_url={video_url}
                                />
                            </div>
                        )) ||
                            null}
                    </div>
                </div>
            </div>
        );

        const harContentTab = (
            <div
                style={{
                    marginTop: '1%',
                    paddingBottom: '56.25%',
                    position: 'relative',
                    display: 'block',
                    width: '100%',
                }}
            >
                {(har && (
                    <iframe
                        title="har-iframe"
                        src={`${har}`}
                        style={{ position: 'absolute', top: '0', left: '0' }}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allowFullScreen=""
                    ></iframe>
                )) || <NoDataComponent />}
            </div>
        );

        let additionalContentTab = (
            <div className="row second-tab-titles pt-10">
                <div className="col-4 col-sm4">
                    IMPACTED OPERATING SYSTEMS
                    {browsers && Object.keys(browsers).length ? (
                        <ImpactedBrowsers browsers={browsers} />
                    ) : (
                        <div>
                            <BreakComponent break={15} /> <NoDataComponent />
                        </div>
                    )}
                </div>
                <div className="col-4 col-sm4">
                    Your Impacted Countries
                    <BreakComponent break={15} />
                    {geo_locations ? (
                        <ImpactedCountries geoLocations={geo_locations} />
                    ) : (
                        <div>
                            <BreakComponent break={15} /> <NoDataComponent />
                        </div>
                    )}
                </div>
            </div>
        );

        const threatTab = threatInfo && (
            <div className="row second-tab-titles pt-10">
                <div className="col-4 col-sm4">
                    {threatInfo['screenshot_url'] && (
                        <React.Fragment>
                            SECURITY THREAT CAPTURED
                            <br />
                            <br />
                            <div
                                id="threat-screenshot"
                                className="carousel slide"
                                data-ride="carousel"
                            >
                                <div className="carousel-inner">
                                    <div
                                        className={'carousel-item active'}
                                        key={1}
                                    >
                                        <img
                                            src={threatInfo['screenshot_url']}
                                            alt=""
                                            className="ss-img"
                                            width="auto"
                                            height="auto"
                                        />
                                    </div>
                                </div>
                                <br />
                            </div>
                            <BreakComponent break={15} />
                        </React.Fragment>
                    )}

                    {threatInfo['public_description'] && (
                        <React.Fragment>
                            <div className="pb-15">Threat Description</div>
                            <div className="threat-desc">
                                {threatInfo['public_description']}
                            </div>
                            <BreakComponent break={15} />
                        </React.Fragment>
                    )}

                    {threatInfo['unsafe_url'] && (
                        <React.Fragment>
                            <div className="pb-15">Threat Destination URL</div>
                            <LinkCopy
                                key={threatInfo['unsafe_url']}
                                type={'threat_url'}
                                threat_url={threatInfo['unsafe_url']}
                            ></LinkCopy>
                            <BreakComponent break={30} />
                        </React.Fragment>
                    )}
                </div>
                <div className="col-8 col-sm-8"></div>
            </div>
        );

        const landingPageTab = !isForceful &&
            landing_page &&
            landing_page.screenshot && (
                <LandingPageTab
                    landing_page={landing_page}
                    openImageViewer={this.openNestedModal}
                />
            );

        const availableTabs = {
            creativeInsights: {
                title: 'CREATIVE INSIGHTS',
                content: insightContentTab,
            },
            creativeInsightsPub: {
                title: 'CREATIVE INSIGHTS',
                content: insightContentTabPub,
            },
            creativeInsightsSSP: {
                title: 'CREATIVE INSIGHTS',
                content: insightContentTabSSP,
            },
            landingPage: { title: 'LANDING PAGE', content: landingPageTab },
            threatInfo: { title: 'THREAT INFORMATION', content: threatTab },
            harContent: { title: 'HAR FILE', content: harContentTab },
            additionalDetails: {
                title: 'ADDITIONAL DETAILS',
                content: additionalContentTab,
            },
        };

        return (
            <div className="col-7 col-md-9">
                <Tabs
                    className="creative-report-tabs"
                    activeKey={this.state.activeTab}
                    onSelect={this.handleSelect}
                >
                    {tabs.map((tab, index) => {
                        if (tab in availableTabs) {
                            return (
                                availableTabs[tab].content && (
                                    <Tab
                                        key={index}
                                        eventKey={index + 1}
                                        title={availableTabs[tab].title}
                                    >
                                        {availableTabs[tab].content}
                                    </Tab>
                                )
                            );
                        }
                        return <></>;
                    })}
                </Tabs>

                {screenshot && !this.state.openType ? (
                    <NestedModal
                        show={this.state.nestedModal}
                        close={this.closeNested}
                    >
                        <img
                            id="nestedCreativeScreenshot"
                            src={screenshot}
                            onError={this.addDefaultSrc}
                            alt="Creative Screenshot"
                            width="auto"
                            height="auto"
                        />
                    </NestedModal>
                ) : null}
                {!isForceful &&
                landing_page &&
                landing_page.screenshot &&
                openType === 'landing' ? (
                    <NestedModal
                        show={this.state.nestedModal}
                        close={this.closeNested}
                    >
                        <img
                            id="nestedLandingPageScreenshot"
                            src={landing_page.screenshot}
                            onError={this.addDefaultSrc}
                            alt="Landing Page Screenshot"
                            width="auto"
                            height="auto"
                        />
                    </NestedModal>
                ) : null}
            </div>
        );
    }
}

export default CreativeReportTabsShared;
