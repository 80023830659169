// #if process.env.BUILD_SECURE === '1'
// import {
//     LOAD_DFP_SUCCESS,
//     REMOVE_DFP_SUCCESS,
//     SET_DFP_AUTHORIZE_LINK,
// } from './ActionTypes';
// #endif

import {
    // #if process.env.BUILD_SECURE === '1'
//     getDFPAccounts,
//     deleteDFPAccount,
//     getDFPLink,
    // #endif
    dfpApiCallback,
} from '../api/SettingsApi';

import { DELETE_DFP_ACCOUNT_SUCCESS_MESSAGE } from '../constants/MessageConstants';
import { getToastr } from '../utils/utils';
import { beginApiCall, apiCallError, endApiCall } from './ApiCallStatusActions';
import { dispatchApi } from './helpers';

// #if process.env.BUILD_SECURE === '1'
// export const loadDFPSuccess = (json, demoMode = false) => ({
//     type: LOAD_DFP_SUCCESS,
//     accounts: json.accounts,
//     demoMode,
// });
// 
// export const setDfpAuthorizeLink = dfpAuthorizeLink => ({
//     type: SET_DFP_AUTHORIZE_LINK,
//     dfpAuthorizeLink,
// });
// 
// export const loadDFP = (demoMode = false) => {
//     return dispatchApi(
//         beginApiCall,
//         getDFPAccounts,
//         (dispatch, json) => {
//             if (json.success) {
//                 return dispatch(loadDFPSuccess(json, demoMode));
//             }
//             getToastr('error', json.message);
//             dispatch(apiCallError());
//         },
//         dispatch => {
//             dispatch(endApiCall());
//         }
//     );
// };
// 
// export const removeDFPSuccess = dfpId => ({
//     type: REMOVE_DFP_SUCCESS,
//     dfpId,
// });
// 
// export const removeDFP = dfpId => {
//     return dispatchApi(
//         beginApiCall,
//         deleteDFPAccount.bind(this, dfpId),
//         (dispatch, json) => {
//             if (json.success) {
//                 getToastr('success', DELETE_DFP_ACCOUNT_SUCCESS_MESSAGE);
//                 return dispatch(loadDFP());
//             }
//             getToastr('error', json.message);
//             dispatch(apiCallError());
//         },
//         dispatch => {
//             dispatch(endApiCall());
//         }
//     );
// };
// 
// export const linkDFP = () => {
//     return dispatchApi(
//         beginApiCall,
//         getDFPLink,
//         (dispatch, json) => {
//             if (json.success) {
//                 return dispatch(setDfpAuthorizeLink(json.redirect));
//             }
//             getToastr('error', json.message);
//             dispatch(apiCallError());
//         },
//         dispatch => {
//             dispatch(endApiCall());
//         }
//     );
// };
// 
// #endif

export const dfpCallback = code => {
    return dispatchApi(
        beginApiCall,
        dfpApiCallback.bind(this, code),
        (dispatch, json) => {
            if (json.success) {
                getToastr('success', json.message);
                return dispatch(loadDFP());
            }
            getToastr('error', json.message);
            dispatch(apiCallError());
        },
        dispatch => {
            dispatch(endApiCall());
        }
    );
};
