import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/AdTraceActions';

import AdTraceHeader from './AdTraceHeader';
import InfoBar from './InfoBar';
import CodeArea from './CodeArea';
import Loader from '../Loader';
import ConsentDetails from './ConsentDetails/ConsentDetails';
import { BreakComponent } from '../shared/CommonComponents';

export class AdTrace extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
        };

        this.changeActiveTab = this.changeActiveTab.bind(this);
    }
    componentDidMount() {
        if (this.props.match.params.dom_instance_id) {
            this.props.onloadAdTraceDomInstance(
                this.props.match.params.dom_instance_id
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.match.params.dom_instance_id !==
            prevProps.match.params.dom_instance_id
        ) {
            this.props.onloadAdTraceDomInstance(
                this.props.match.params.dom_instance_id
            );
        }
    }

    changeActiveTab(type) {
        const { isConsentMismatch } = this.props;

        let newActiveTab = isConsentMismatch && type === 'html' ? 2 : 1;
        this.setState({ activeTab: newActiveTab });
    }

    render() {
        const { activeTab } = this.state;

        const {
            domInstanceResponse,
            isAdTraceLoading,
            isConsentMismatch,
            sessionData,
            url,
            consentDetails,
            consentMisType,
            cmpData,
            consentString,
            trackingType,
            cookies,
            localStorage,
            adTraceCMWidgets,
            gpc,
        } = this.props;

        let activeComp =
            isConsentMismatch && activeTab === 1 ? 'consent' : 'html';

        return (
            <React.Fragment>
                {isAdTraceLoading ? (
                    <Loader className="adtrace-loader" />
                ) : (
                    <div id="ad-trace-container">
                        <AdTraceHeader
                            domInstanceResponse={domInstanceResponse}
                            isConsentMismatch={isConsentMismatch}
                            setActive={this.changeActiveTab}
                            activeTab={this.state.activeTab}
                        />
                        <BreakComponent break={2} />
                        <div className="table fill-page">
                            {domInstanceResponse &&
                            domInstanceResponse.dom_instance ? (
                                <div className="top full ">
                                    <InfoBar response={domInstanceResponse} />
                                    {activeComp === 'html' ? (
                                        <CodeArea
                                            codeBody={atob(
                                                domInstanceResponse.dom_dump
                                            )}
                                        />
                                    ) : (
                                        <ConsentDetails
                                            adTraceCMWidgets={adTraceCMWidgets}
                                            consentDetails={consentDetails}
                                            url={url}
                                            sessionData={sessionData}
                                            consentMisType={consentMisType}
                                            cmpData={cmpData}
                                            consentString={consentString}
                                            trackingType={trackingType}
                                            cookies={cookies}
                                            localStorage={localStorage}
                                            gpc={gpc}
                                        />
                                    )}
                                </div>
                            ) : (
                                <div className="flex-notice">
                                    No DOM Report with this diid
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        domInstanceResponse: state.adtraceReducer.domInstanceResponse,
        isAdTraceLoading: state.adtraceReducer.isAdTraceLoading,
        isConsentMismatch: state.adtraceReducer.isConsentMismatch,
        consentDetails: state.adtraceReducer.consentDetails,
        url: state.adtraceReducer.url,
        sessionData: state.adtraceReducer.sessionData,
        consentMisType: state.adtraceReducer.consentMisType,
        cmpData: state.adtraceReducer.cmpData,
        consentString: state.adtraceReducer.consentString,
        trackingType: state.adtraceReducer.trackingType,
        cookies: state.adtraceReducer.cookies,
        localStorage: state.adtraceReducer.localStorage,
        adTraceCMWidgets: state.adtraceReducer.adTraceCMWidgets,
        gpc: state.adtraceReducer.gpc,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onloadAdTraceDomInstance: domInstanceId =>
            dispatch(actions.loadAdTraceDomInstance(domInstanceId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdTrace);
