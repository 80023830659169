import React from 'react';
import { NoDataComponent } from './CommonComponents';
import { BreakComponent } from '../shared/CommonComponents';

class ImpactedProviders extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            isExpanded: false,
        };

        this.state = this.initialState;
        this.handleExpandToggle = this.handleExpandToggle.bind(this);
    }

    handleExpandToggle(e) {
        e.preventDefault();
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded });
    }

    render() {
        const { providers, dateRange, publishers } = this.props;

        let summaries = [];
        let isProvider = false;
        if (providers && providers.length) {
            summaries =
                providers.length < 5 || this.state.isExpanded
                    ? providers
                    : providers.slice(0, 5);
            isProvider = true;
        }

        if (publishers && publishers.length) {
            summaries =
                publishers.length < 5 || this.state.isExpanded
                    ? publishers
                    : publishers.slice(0, 5);
        }

        return (
            <div className="row second-tab-titles pr-30">
                <div className="col no-padding--left">
                    Impacted {isProvider ? 'Providers' : 'Publishers'}
                    <BreakComponent break={0} />
                    <span className="notitle-text"> ({dateRange}) </span>
                </div>
                <BreakComponent block={5}></BreakComponent>
                {(summaries && summaries.length && (
                    <div className="table-responsive impacted-provider-table">
                        <table className="table table-borderless table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        {isProvider ? 'Provider' : 'Publisher'}
                                    </th>
                                    <th>Blocked </th>
                                    <th>
                                        {isProvider
                                            ? 'Detected Only'
                                            : 'Allowed'}
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {summaries &&
                                    summaries.map(summary => (
                                        <tr
                                            key={`${Math.random()}-${
                                                summary.instance
                                            }`}
                                        >
                                            <td>
                                                {isProvider
                                                    ? summary.provider
                                                    : summary.publishers}
                                            </td>
                                            <td className="font-light">
                                                {summary.blocked &&
                                                    summary.blocked.toLocaleString(
                                                        'en-US'
                                                    )}
                                            </td>
                                            <td className="font-light">
                                                {summary.alerted &&
                                                    summary.alerted.toLocaleString(
                                                        'en-US'
                                                    )}
                                            </td>
                                            <td className="dark-blue-font">
                                                <a
                                                    href={summary.instance}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <b>Ad Trace</b>
                                                </a>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                            {(providers && providers.length > 5) ||
                            (publishers && publishers.length > 5) ? (
                                <tfoot>
                                    <tr>
                                        <td
                                            colSpan="8"
                                            className="table-footer uppercase bt-none p-b-0"
                                        >
                                            <a
                                                href="/#"
                                                className="table-toggler"
                                                id="tableToggler"
                                                onClick={
                                                    this.handleExpandToggle
                                                }
                                            >
                                                {this.state.isExpanded
                                                    ? 'Show Less'
                                                    : 'Show More'}
                                            </a>
                                        </td>
                                    </tr>
                                </tfoot>
                            ) : null}
                        </table>
                    </div>
                )) || <NoDataComponent />}
            </div>
        );
    }
}

export default ImpactedProviders;
