import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { getFormattedNumber } from '../../utils/utils';

const DailyImpression = ({ impressions }) => {
    let impressionsList = [],
        lineOptions = {};

    if (impressions && impressions.length) {
        let dateFormat = 'll';
        let xCategories = [
            moment()
                .subtract(6, 'days')
                .format(dateFormat),
            moment()
                .subtract(5, 'days')
                .format(dateFormat),
            moment()
                .subtract(4, 'days')
                .format(dateFormat),
            moment()
                .subtract(3, 'days')
                .format(dateFormat),
            moment()
                .subtract(2, 'days')
                .format(dateFormat),
            moment()
                .subtract(1, 'days')
                .format(dateFormat),
            moment().format(dateFormat),
        ];

        impressions.forEach((i, index) => {
            impressionsList.push(i);
        });

        lineOptions = {
            chart: {
                type: 'spline',
                inverted: false,
                height: 250,
                spacingLeft: 0,
            },
            xAxis: {
                visible: false,
                categories: xCategories,
            },
            yAxis: {
                labels: {
                    format: '{value}',
                },
                lineWidth: 1,
                softMin: 0,
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                headerFormat: '',
                formatter: function() {
                    return (
                        '<b>' +
                        this.x +
                        '</b> : ' +
                        getFormattedNumber(this.y, 0, '0,0') +
                        ' impressions'
                    );
                },
            },
            plotOptions: {
                series: {
                    color: '#A9A9A9',
                    marker: {
                        enable: true,
                        fillColor: '#FF0000',
                        lineWidth: 2,
                        lineColor: '#FF0000', // inherit from series,
                    },
                },
            },
            series: [
                {
                    data: impressionsList,
                },
            ],
        };
    }
    return (
        <div className="chart-wrapper-impressions">
            <HighchartsReact highcharts={Highcharts} options={lineOptions} />
        </div>
    );
};

export default DailyImpression;
