import React from 'react';

class DomChain extends React.Component {
    render() {
        const { domChain } = this.props;

        return (
            <React.Fragment>
                <div className="adtrace-call-chain">
                    <ul>
                        {domChain.map((value, index) => {
                            return <li className="long-text" key={index}>{value}</li>
                        })}
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default DomChain;