import React from 'react';

class ImageContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imgErr: false,
        };

        this.addDefaultSrc = this.addDefaultSrc.bind(this);
        this.openNestedModal = this.openNestedModal.bind(this);
    }

    addDefaultSrc(ev) {
        ev.target.src = '/static/images/noscreenshot.png';
        this.setState({ imgErr: true });
    }

    openNestedModal() {
        if (!this.state.imgErr) {
            this.props.openImageViewer(this.props.type);
        }
    }

    render() {
        const { img_url, type } = this.props;
        return (
            <div id="demo" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className={'carousel-item active'} key={1}>
                        <img
                            id={
                                type
                                    ? 'landingPageScreenshot'
                                    : 'creativeScreenshot'
                            }
                            onClick={this.openNestedModal}
                            src={img_url}
                            onError={this.addDefaultSrc}
                            alt="Creative Screenshot"
                            className={`ss-img ${type}`}
                            width="auto"
                            height="auto"
                        />
                    </div>
                </div>
                <br />
            </div>
        );
    }
}

export default ImageContainer;
