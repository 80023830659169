import React from 'react';

const NestedModal = props => {
    if (props.show) {
        return (
            <div className="modal-wrapper">
                <div className="modal-backdrop"></div>
                <div className="modal-box">
                    <div className="d-flex flex-row-reverse">
                        <i
                            onClick={props.close}
                            className="fa fa-times popup-image"
                            aria-hidden="true"
                        ></i>
                    </div>
                    <div className="modal-box-content">{props.children}</div>
                </div>
            </div>
        );
    }

    return null;
};

export default NestedModal;
