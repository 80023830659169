import React from 'react';

const ReportModalInfoField = ({
    parentCName,
    labelCName,
    labelData,
    valueCName,
    valueData,
}) => {
    return (
        <div className={parentCName}>
            <div className={labelCName}>{labelData}</div>
            <div className={valueCName}>{valueData}</div>
        </div>
    );
};

export default ReportModalInfoField;
