import React from 'react';
import { connect } from 'react-redux';

import { getScanLogReportADM } from '../../api/SSPAlertLogApi';
import { getToastr } from '../../utils/utils';
import { beginApiCall, endApiCall } from '../../actions/ApiCallStatusActions';
import ADMModal from './ADMModal';

export class AdMarkUp extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            copyReady: false,
            adMarkUP: null,
            openModal: false,
        };

        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        const { ad_instance } = this.props;
        const { copyReady, openModal } = this.state;

        // make the api call if and only if the call was not made before
        if (!copyReady) {
            this.props.beginLoading();
            getScanLogReportADM(ad_instance)
                .then(json => {
                    if (json.success) {
                        this.setState({ adMarkUP: json.ad_markup });
                        this.setState({ copyReady: true, openModal: true });
                    } else {
                        getToastr('error', json.message);
                    }
                })
                .catch(error => {
                    getToastr('error', error.message);
                })
                .finally(this.props.endLoading());
        } else {
            this.setState({ openModal: !openModal });
        }
    }

    render() {
        const { adMarkUP, openModal } = this.state;

        return (
            <React.Fragment>
                <div className="adm-markup mr-4">
                    <button
                        className="btn btn-secondary"
                        onClick={this.handleClick}
                    >
                        {' '}
                        view
                    </button>

                    <ADMModal
                        show={openModal}
                        adm={adMarkUP}
                        onHide={() => this.setState({ openModal: false })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        beginLoading: increment => dispatch(beginApiCall(increment)),
        endLoading: () => dispatch(endApiCall()),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(AdMarkUp);
