// Needs to be in unsecure build.
import fetch from 'cross-fetch';
import history from '../component/Navigations/History';
import store from '../store/ConfigureStore';
import { logoutSuccess } from '../actions/AuthActions';
import { POST_NOBODY_REQUEST } from './ApiConstants';

let abortController = new AbortController();

export const getAbortSignal = () => {
    return abortController.signal;
};

export const validateResponse = (response, pushTo = '/login') => {
    if (!response.ok) {
        if (response.status === 401) {
            store.dispatch(logoutSuccess());
            abortController.abort();
            abortController = new AbortController();
            history.push(pushTo);
        } else {
            throw Error(response.statusText);
        }
    }
    return response.json();
};

export const makeRequest = (url, data, signal) => {
    return fetch(url, {
        ...POST_NOBODY_REQUEST,
        body: JSON.stringify(data),
        signal,
    })
        .then(validateResponse)
        .catch(error => {
            if (error.name !== 'AbortError') {
                return Promise.reject(error);
            }
        });
};
