import React from 'react';
import ConsentDetailComponent from './shared/ConsentDetailComponent';
import {
    SessionInfo,
    TrackingInfo,
    TrackingIdentified,
} from './shared/ComponentPool';
import PurposeDeclaration from './gdpr/PurposeDeclaration';
import ConsentStringDetails from './gdpr/ConsentStringDetails';
import ConsentStringContainer from './shared/ConsentStringContainer';
import MismatchDetails from './shared/MismatchDetails';

const wrapWithConsentData = Component => {
    // return a newly generated React component
    // using a functional, stateless component
    return function(props) {
        const { direction, consentMisType, adTraceCMWidgets } = props;

        let widgetData = Array.from(
            adTraceCMWidgets[direction][consentMisType]
        );

        return widgetData.map(w => {
            return (
                <Component
                    {...props}
                    key={`${w.key}-${direction}-${consentMisType}`}
                    id={`${w.key}-${direction}-${consentMisType}`}
                    containerData={w}
                />
            );
        });
    };
};

const ConsentDetailComponentRenderer = props => {
    const {
        id,
        containerData,
        consentDetails,
        url,
        sessionData,
        consentMisType,
        cmpData,
        consentString,
        cookies,
        localStorage,
        gpc,
    } = props;

    const {
        name,
        key: ckey,
        collapseOptions,
        type,
        track_type,
    } = containerData;
    const { collapsible, collapsed } = collapseOptions;
    const { entity, gdpr_purposes, mismatch } = consentDetails;
    const { iab } = entity;
    let ChildComponent = null;

    if (type === 'user_session') {
        ChildComponent = (
            <SessionInfo url={url} sessionData={sessionData} gpc={gpc} />
        );
    }

    if (type === 'tracking_info') {
        ChildComponent = <TrackingInfo entity={entity} iab={iab} />;
    }

    if (type === 'mismatch_details') {
        ChildComponent = (
            <MismatchDetails
                mismatchType={consentMisType}
                iab={iab}
                mismatch={mismatch}
                gpc={gpc}
            />
        );
    }

    if (type === 'declared_purposes') {
        let pList = {};
        if (gdpr_purposes && gdpr_purposes.purposes) {
            pList = gdpr_purposes.purposes;
        }
        ChildComponent = <PurposeDeclaration list={pList} iab={iab} />;
    }

    if (type === 'declared_legint_purposes') {
        let plList = {};
        if (gdpr_purposes && gdpr_purposes.legitimate_purposes) {
            plList = gdpr_purposes.legitimate_purposes;
        }
        ChildComponent = (
            <PurposeDeclaration list={plList} legitimate={true} iab={iab} />
        );
    }

    if (type === 'consent_details') {
        ChildComponent = <ConsentStringDetails cmpData={cmpData} />;
    }

    if (type === 'tracking_identified' && track_type === 'cookie') {
        ChildComponent = (
            <TrackingIdentified data={cookies} track_type={track_type} />
        );
    }

    if (type === 'tracking_identified' && track_type === 'local storage') {
        ChildComponent = (
            <TrackingIdentified data={localStorage} track_type={track_type} />
        );
    }

    if (type === 'consent_string') {
        ChildComponent = (
            <ConsentStringContainer
                mismatchType={consentMisType}
                consentString={consentString}
            />
        );
    }

    return (
        <React.Fragment>
            <ConsentDetailComponent
                key={`${id}-${name}-${ckey}`}
                id={`${id}-${name}-${ckey}`}
                title={name}
                collapsible={collapsible || false}
                collapsed={collapsed}
                data={consentDetails}
                url={url}
                sessionData={sessionData}
                type={type}
                trackingType={track_type}
            >
                {ChildComponent}
            </ConsentDetailComponent>
        </React.Fragment>
    );
};

export const ConnectedConsentComponent = wrapWithConsentData(
    ConsentDetailComponentRenderer
);
