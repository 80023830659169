// Needs to be in unsecure build.

import React from 'react';
import { connect } from 'react-redux';
import { CompanyNavigations } from '../Navigations/CompanyNavigations';
import MobileHeaderBeforeLogin from '../Navigations/MobileHeaderBeforeLogin';

class ResetPasswordResponse extends React.Component {
    render() {
        return (
            <div className="container-fluid no-padding--left mlr-xs-none" key={Math.random}>
            <CompanyNavigations />
                <div className="page-header-out col-md-9 ml-sm-auto col-lg-10 px-4 d-none d-md-block fixed-top">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap">
                        &nbsp;
                    </div>
                </div>
                <MobileHeaderBeforeLogin componentName={"Dashboard"}/>
                <div className="page-header-out d-block d-md-none">
                    <div className="">
                        &nbsp;
                    </div>
                </div>
                <div className="common-container">
                    <div className="reset-pwd-success-container">
                        {this.props.resetSuccess?
                            (
                                <div>
                                    <h1 >
                                        Congratulations! You’ve successfully changed your password.
			                        </h1>
                                    <button type="button" className="btn btn-secondary mr-4" onClick={() => this.props.history.push('/login')}>CONTINUE</button>
                                </div>
                            )
                            :
                            (<h1 >
                                {this.props.resetStatusMessage}
			                </h1>)
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        resetSuccess: state.resetPasswordReducer.resetSuccess,
        resetStatusMessage: state.resetPasswordReducer.resetStatusMessage
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordResponse);