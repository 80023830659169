// Needs to be in unsecure build.

import React from 'react';

const NotFound = () =>
	<div>
		<h1>404 Page Not Found</h1>
	</div>


export default NotFound;