import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/ConfigureStore';
import App from './App';
import history from './component/Navigations/History';

ReactDOM.render(
    <Provider store={store}>
        <div>
            <Router history={history}>
                <App />
            </Router>
        </div>
    </Provider>,
    document.getElementById('root')
);
