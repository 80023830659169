import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { withRouter } from 'react-router-dom';

// #if process.env.BUILD_SECURE === '1'
// import * as AppRouteGuard from './AppRouteGuard';
// import AlertLog from '../AlertLog/blocking/AlertLog';
// import BlockingProperties from '../Properties/blocking/BlockingProperties';
// import Dashboard from '../Dashboard/blocking/Dashboard';
// import DynamicSecureRoute from './DynamicSecureRoute';
// import MyAccount from '../Settings/MyAccount';
// import Privacy from '../Privacy/Privacy';
// import PublisherBlocks from '../SSPPublisherBlocks/PublisherBlocks';
// import RoleSwitch from './RoleSwitch';
// import Role from './Role';
// import Reports from '../Reports/blocking/Reports';
// import Settings from '../Settings/Settings';
// import SSPAlertLog from '../AlertLog/ssp/SSPAlertLog';
// import SSPCreativeSearch from '../CreativeSearch/SSPCreativeSearch';
// import PubCreativeSearch from '../CreativeSearch/PubCreativeSearch';
// import SSPDashboard from '../Dashboard/ssp/SSPDashboard';
// import SSPProperties from '../Properties/ssp/SSPProperties';
// import SSPReports from '../Reports/ssp/SSPReports';
// import * as Views from '../../constants/SelectedViewConstants';
// #endif

import AdTrace from '../AdTrace/AdTrace';
import DFPCallback from '../Settings/DFPCallback';
import Login from '../Auth/Login';
import NotFound from '../NotFound';
import ReduxToastr from 'react-redux-toastr';
import ResetPassword from '../Auth/ResetPassword';
import ResetPasswordRequest from '../Auth/ResetPasswordRequest';
import ResetPasswordResponse from '../Auth/ResetPasswordResponse';
import SetupPassword from '../Auth/SetupPassword';
import Tos from './Tos';
import ScanLogSSPCreativeReport from '../CreativeReport/SSPCreativeSearchCreativeReport/ScanLogSSPCreativeReport';

class Main extends React.Component {
    render() {
        return (
            <main className="medium-main no-padding--left" role="main">
                <Router>
                    <Switch>
                        {/*#if process.env.BUILD_SECURE === '1'*/}






































































































































































                        {/*#endif*/}

                        <Route path="/tos" component={Tos} />
                        <Route path="/login" component={Login} />
                        <Route
                            path="/setup-password/:hash"
                            component={SetupPassword}
                        />
                        <Route
                            path="/reset-password"
                            component={ResetPassword}
                        />
                        <Route
                            path="/reset-resp"
                            component={ResetPasswordResponse}
                        />
                        <Route path="/reset" component={ResetPasswordRequest} />
                        <Route path="/dfp/callback" component={DFPCallback} />
                        <Route
                            path="/adtrace/:dom_instance_id"
                            component={AdTrace}
                        />
                        <Route
                            path="/scan_report/share/:ti"
                            component={ScanLogSSPCreativeReport}
                        />
                        {/*#if process.env.BUILD_SECURE !== '1'*/}
                        <Route path="/" component={Login} />
                        {/*#endif*/}
                        <Route component={NotFound} />
                    </Switch>
                </Router>

                <ReduxToastr
                    timeOut={3000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar={false}
                />
            </main>
        );
    }
}

export default withRouter(Main);
