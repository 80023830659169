// Needs to be in unsecure build.

import React from 'react';
import * as queryString from 'querystring';
import * as actions from '../../actions/ResetPasswordActions';
import { connect } from 'react-redux';
import {
    FormWithConstraints,
    FieldFeedbacks,
    FieldFeedback,
    Input,
} from 'react-form-with-constraints';
import { CompanyNavigations } from '../Navigations/CompanyNavigations';
import MobileHeaderBeforeLogin from '../Navigations/MobileHeaderBeforeLogin';

class ResetPassword extends React.Component {
    state = {
        password: '',
    };
    handleChange = async e => {
        if (e.target.name === 'password')
            this.setState({ password: e.target.value });
        document.getElementById('confirmPassword').setCustomValidity('');
        await this.resetForm.validateFields(e.target);
    };

    handleSubmit = async e => {
        e.preventDefault();
        const target = e.target;
        if (target.password.value !== target.confirmPassword.value) {
            document
                .getElementById('confirmPassword')
                .setCustomValidity('error');
            await this.resetForm.validateFields();
            return false;
        }
        await this.resetForm.validateForm();
        const formIsValid = this.resetForm.isValid();
        if (formIsValid) {
            let pwdDetails = {
                password: target.password.value,
                confirmPassword: target.confirmPassword.value,
                token: this.getToken(),
            };
            this.props.resetPassword(pwdDetails);
            target.password.value = '';
            target.confirmPassword.value = '';
            this.props.history.push('/reset-resp');
        }
    };

    getToken = () => {
        let searchString = this.props.location.search;
        searchString = searchString
            ? searchString.substring(1, searchString.length)
            : '';
        return queryString.parse(searchString).token;
    };

    render() {
        return (
            <div className="container-fluid no-padding--left mlr-xs-none">
                <CompanyNavigations />

                <div className="page-header-out col-md-9 ml-sm-auto col-lg-10 px-4 d-none d-md-block fixed-top">
                    <div
                        className="d-flex justify-content-between flex-wrap flex-md-nowrap"
                        style={{ paddingTop: '3%' }}
                    >
                        <h4 style={{ color: '#150d45' }}>
                            RESET YOUR PASSWORD
                        </h4>
                    </div>
                </div>
                <MobileHeaderBeforeLogin />
                <div className="page-header-out d-block d-md-none pt-5 mt-2">
                    <div className="">
                        <h4 style={{ color: '#150d45' }}>
                            RESET YOUR PASSWORD
                        </h4>
                    </div>
                </div>
                <div className="pt-3 mb-3 border-bottom">
                    <div className="dropdown dropdown-big">&nbsp;</div>
                </div>

                <div className="common-container" style={{ marginLeft: '10%' }}>
                    <FormWithConstraints
                        onSubmit={this.handleSubmit}
                        ref={form => (this.resetForm = form)}
                        noValidate
                    >
                        <div className="form-group row row-form-mb">
                            <label
                                htmlFor="password"
                                className="col-lg-2 col-md-3 col-form-label"
                            >
                                Password
                            </label>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <Input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    id="password"
                                    placeholder="Enter Password"
                                    defaultValue=""
                                    onChange={this.handleChange}
                                    required
                                    pattern=".{8,}"
                                />
                                <FieldFeedbacks for="password">
                                    <FieldFeedback when="valueMissing">
                                        Password is required.
                                    </FieldFeedback>
                                    <FieldFeedback when="patternMismatch">
                                        Password should be at least 8 characters
                                        long.
                                    </FieldFeedback>
                                    <FieldFeedback
                                        when={value => !/\d/.test(value)}
                                    >
                                        Password should contain at least one
                                        number.
                                    </FieldFeedback>
                                    <FieldFeedback
                                        when={value => !/[a-zA-Z]/.test(value)}
                                    >
                                        Password should contain at least one
                                        letter.
                                    </FieldFeedback>
                                    <FieldFeedback
                                        when={value =>
                                            !/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(
                                                value
                                            )
                                        }
                                    >
                                        Password should contain at least one
                                        special character.
                                    </FieldFeedback>
                                </FieldFeedbacks>
                            </div>
                        </div>

                        <div className="form-group row row-form-mb">
                            <label
                                htmlFor="confirmPassword"
                                className="col-lg-2 col-md-3 col-form-label"
                            >
                                Confirm Password
                            </label>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <Input
                                    type="password"
                                    required
                                    className="form-control"
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    placeholder="Password"
                                    defaultValue=""
                                    onChange={this.handleChange}
                                />
                                <FieldFeedbacks for="confirmPassword">
                                    <FieldFeedback when="valueMissing">
                                        Confirm Password is required.
                                    </FieldFeedback>
                                    <FieldFeedback when="*">
                                        Passwords don't match.
                                    </FieldFeedback>
                                </FieldFeedbacks>
                            </div>
                        </div>

                        <div className="form-group row row-form-mb pt-4">
                            <label className="col-lg-2 col-md-3 col-form-label"></label>
                            <div className="col-lg-4 col-md-4 col-sm-6 d-flex flex-row-reverse">
                                <button
                                    type="submit"
                                    className="btn btn-secondary"
                                >
                                    SUBMIT
                                </button>
                            </div>
                        </div>
                    </FormWithConstraints>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetPassword: passwordDetails =>
            dispatch(actions.resetPassword(passwordDetails)),
    };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
