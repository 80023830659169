import React from 'react';

export const BreakComponent = props => {
    return (
        <div
            className="w-100 d-none d-md-block"
            style={{ paddingBottom: `${props.break}px` }}
        ></div>
    );
};
