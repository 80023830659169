import React from 'react';
import ReportModalInfoField from './ReportModalInfoField';

const fields = {
    flagged_since: {
        key: 'flagged_since',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        labelData: 'Confiant Identified',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
    ad_size: {
        key: 'ad_size',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-7 leftpane-subtitle--rest',
        labelData: 'Ad Size',
        valueCName: 'col-5 leftpane-subtitle--second',
    },
    issue_detail: {
        key: 'issue_detail',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-7 leftpane-subtitle--rest',
        labelData: 'Issue Detail',
        valueCName: 'col-5 leftpane-subtitle--second',
    },
    originating_source: {
        key: 'originating_source',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-8 leftpane-subtitle--rest',
        labelData: 'Originating Source',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
    other_issues: {
        key: 'other-issues',
        parentCName: 'leftpane-subtitle leftpane-subtitle--last',
        labelCName: 'row leftpane--row leftpane-subtitle--rest',
        labelData: 'Other Issues Found',
        valueCName: 'row',
    },
    blocking_status: {
        key: 'blocking-status',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-7 leftpane-subtitle--rest',
        labelData: 'Current Blocking Status',
        valueCName: 'col-5 leftpane-subtitle--second',
    },
    date_first_seen: {
        key: 'date_first_seen',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        labelData: 'First Seen In Account',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
    heaviness_level: {
        key: 'heaviness_level',
        parentCName: 'row leftpane--row blocking',
        labelCName: 'col-7 leftpane-subtitle--rest',
        labelData: 'Heaviness Score',
        valueCName: 'col-5 leftpane-subtitle--second',
    },
    empty: {
        key: '',
        parentCName: '',
        labelCName: '',
        labelData: '',
        valueCName: '',
    },
};

let scanFields = {
    creative_id: {
        key: 'creative_id',
        parentCName: 'row leftpane--row',
        labelCName: 'col-6 leftpane-subtitle--rest scanlog',
        labelData: 'Creative ID',
        valueCName: 'col-6 leftpane-subtitle--second scanlog',
    },
    status: {
        key: 'status',
        parentCName: 'row leftpane--row scan-report status',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        labelData: 'Status',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
    issue_detail: {
        key: 'issue_detail',
        parentCName: 'row leftpane--row scan-report',
        labelCName: 'col-5 leftpane-subtitle--rest pr-0',
        labelData: 'Issue Detail',
        valueCName: 'col-7 leftpane-subtitle--second',
    },
    ad_instance: {
        key: 'ad_instance',
        parentCName: 'row leftpane--row scan-report--last',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        valueCName: 'col-4 leftpane-subtitle--second',
        labelData: 'Confiant Ad Instance',
    },
    other_issues: {
        key: 'other_issues',
        parentCName: 'leftpane-subtitle leftpane-subtitle--last',
        labelCName: 'row leftpane-subtitle other-issues',
        labelData: 'Other Issues Found',
        valueCName: 'row',
    },
    brand: {
        key: 'brand',
        parentCName: 'row leftpane--row scan-report',
        labelCName: 'col-5 leftpane-subtitle--rest pr-0',
        labelData: 'BRAND',
        valueCName: 'col-7 leftpane-subtitle--second',
    },
    category: {
        key: 'category',
        parentCName: 'row leftpane--row scan-report',
        labelCName: 'col-5 leftpane-subtitle--rest pr-0',
        labelData: 'CATEGORY',
        valueCName: 'col-7 leftpane-subtitle--second',
    },
    sensitiveImagery: {
        key: 'sensitiveImagery',
        parentCName: 'row leftpane--row',
        labelCName: 'col-5 leftpane-subtitle--rest pr-0',
        labelData: 'SENSITIVE IMAGERY',
        valueCName: 'col-7 leftpane-subtitle--second',
    },
    provider: {
        parentCName: 'row leftpane--row--last',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        labelData: 'Provider',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
    issues_detected: {
        key: 'issues-detected',
        parentCName: 'row leftpane--row--last scan-report',
        labelCName: 'col-7 leftpane-subtitle--rest',
        valueCName: 'col-5 leftpane-subtitle--second',
        labelData: 'Issue(s) Detected',
    },
    default: {
        parentCName: 'row leftpane--row scan-report',
        labelCName: 'col-8 leftpane-subtitle--rest pr-0',
        valueCName: 'col-4 leftpane-subtitle--second',
    },
};

let privacyFields = {
    default: {
        parentCName: 'row leftpane--row pb-2',
        labelCName: 'col-7 leftpane-subtitle--rest',
        valueCName: 'col-5 leftpane-subtitle--second',
    },
};

const labelMappings = {
    issue_detail: 'Issue Detail',
    ad_type: 'Ad Type',
    dsp: 'DSP',
    seat: 'SEAT',
    issues_detected: 'Issue(s) Detected',
    dimensions: 'DIMENSIONS',
    impressions_count: 'Impressions Served',
    ssl_error: 'SSL ERROR',
    confiant_identified: 'Confiant Identified',
    ad_instance: 'Confiant Ad Instance',
    legal_name: 'LEGAL ENTITY',
    risk_level: 'RISK LEVEL',
    tcf_status: 'GDPR TCF STATUS',
    domains: 'DOMAIN',
    policy_url: 'POLICY URL',
    last_seen: 'LAST SEEN TRACKING',
    tracking_cali: 'TRACKING IN CALIFORNIA',
    tracking_eu: 'TRACKING IN EUROPE',
    tp_crid: 'THIRD PARTY CRID',
    provider: 'PROVIDER',
    ad_trace: 'EXAMPLE AD TRACE',
    sentiment_language: 'LANGUAGE SPOKEN',
    sentiment_political_attack: 'POLITICAL ATTACK AD',
    sentiment_is_sponsored: 'PAID FOR BY',
    sentiment_score: 'OVERALL SENTIMENT',
};

export const getFieldWrapper = (type, data, scan = false, privacy = false) => {
    let keyFields = scan
        ? scanFields[type]
        : privacy
        ? privacyFields[type]
        : fields[type];

    if (scan && !keyFields) {
        keyFields = {
            ...scanFields['default'],
            key: type,
            labelData: labelMappings[type],
        };
    }

    if (privacy && !keyFields) {
        let fields = { ...privacyFields['default'] };
        if (type === 'risk_level') {
            fields.valueCName = `${fields.valueCName} risk-level ${data}-color`;
        }

        keyFields = {
            ...fields,
            key: type,
            labelData: labelMappings[type],
        };
    }

    return (
        <ReportModalInfoField
            {...keyFields}
            valueData={data}
        ></ReportModalInfoField>
    );
};
