import React, { Component } from 'react';

class Loader extends Component {
	render(){
		return (
			<img className={this.props.className ? this.props.className : ""} src={'/static/images/loading.gif'} alt="Loading..." />
		);
	}
}

export default Loader;