// Needs to be in unsecure build

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
// #if process.env.BUILD_SECURE === '1'
// import { updateSettings } from '../../api/SettingsApi';
// #endif

import { loginStatus } from '../../api/AuthApi';

class Tos extends Component {
    constructor(props) {
        super(props);

        this.initialState = {
            isTosAccepted: false,
            redirectToRoot: false,
        };

        this.state = this.initialState;
    }

    async componentWillMount() {
        const status = await loginStatus();
        if (!status.success) {
            this.setState({ redirectToRoot: true });
        }
    }

    handleAcceptTosToggle = e => {
        this.setState({ isTosAccepted: e.target.checked });
    };

    handleSubmit = async () => {
        try {
            // #if process.env.BUILD_SECURE === '1'
//             const result = await updateSettings({
//                 tos: this.state.isTosAccepted ? 'on' : 'off',
//             });
//             if (result.success) {
//                 this.setState({ redirectToRoot: true });
//             }
            // #endif
        } catch (e) {
            // Swallow for now
        }
    };

    render() {
        if (this.state.redirectToRoot) {
            return <Redirect to="/" />;
        }

        return (
            <div className="tos-container">
                <img
                    className="logo"
                    src="/static/images/logo.svg"
                    alt="Confiant"
                />
                <h5 className="p-top-15">
                    Please confirm your agreement to the{' '}
                    <a
                        href="https://www.confiant.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>{' '}
                    before continuing.
                </h5>
                <div className="p-top-5">
                    <input
                        type="checkbox"
                        name="agreeToTOS"
                        id="agreeToTOS"
                        className="checkbox"
                        onChange={this.handleAcceptTosToggle}
                        checked={this.state.isTosAccepted}
                    />
                    <label htmlFor="agreeToTOS">
                        &nbsp;I agree to the Terms of Service
                    </label>
                </div>
                <button
                    className="btn btn-primary m-top-10"
                    id="continueToAccount"
                    disabled={!this.state.isTosAccepted}
                    onClick={this.handleSubmit}
                >
                    Continue
                </button>
            </div>
        );
    }
}

export default Tos;
