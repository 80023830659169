import React, { useState, useEffect } from 'react';

const getType = v => {
    if (Array.isArray(v)) return 'array';
    else if (typeof v == 'string') return 'string';
    else if (v != null && typeof v == 'object') return 'object';
    else if (typeof v == 'number') return 'number';
    else if (typeof v == 'boolean') return 'boolean';
    else if (v == null) return 'undefined';
    else return 'other';
};

const Tree = ({ data }) => {
    return (
        <ul className="cd-tree-list">
            {(data &&
                Object.entries(data).map(([key, value]) => (
                    <li key={`${key}-${getType(value)}-li-item`}>
                        {(getType(value) !== 'object' && (
                            <div className="d-flex flex-row">
                                <span>{key}</span>
                                <span className="ml-2">:</span>&nbsp;
                                <span
                                    className={`value-color-${getType(value)}`}
                                >
                                    {`${
                                        getType(value) === 'boolean'
                                            ? value.toString()
                                            : getType(value) === 'undefined'
                                            ? 'null'
                                            : value
                                    }`}
                                </span>
                            </div>
                        )) ||
                            null}
                        {(getType(value) === 'object' && (
                            <ObjectTree keyItem={key} valItem={value} />
                        )) ||
                            null}
                    </li>
                ))) ||
                null}
        </ul>
    );
};

const ObjectTree = ({ keyItem, valItem }) => {
    const firstObj = keyItem === 'Consent string';
    const [hidden, setHidden] = useState(true);

    useEffect(() => {
        if (firstObj) setHidden(false);
    }, [firstObj]);

    return (
        <div className="d-flex flex-column">
            <div
                className={`d-flex flex-row obj-list ${
                    firstObj ? 'first-obj' : ''
                }`}
                onClick={() => setHidden(!hidden)}
            >
                <span className="mr-1">
                    {firstObj ? '' : hidden ? '\u25B8' : '\u25BE'}
                </span>
                <span>{`${keyItem} {${Object.keys(valItem).length}}`}</span>
            </div>
            {!hidden && (
                <Tree
                    key={`${keyItem}-${
                        Object.keys(valItem).length
                    }-object-tree-item`}
                    data={valItem}
                />
            )}
        </div>
    );
};
const ConsentStringDetails = ({ cmpData }) => {
    if (cmpData) {
        return (
            <div className="scrollable-row data-row px-0 pt-1">
                <Tree data={cmpData} />
            </div>
        );
    }

    return null;
};

export default ConsentStringDetails;
