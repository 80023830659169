// Needs to be in unsecure build.

import React from 'react';
import { connect } from 'react-redux';
import { dfpCallback } from '../../actions/DFPActions';
import * as queryString from 'querystring';

export class DFPCallback extends React.Component {
    componentDidMount() {
        let code = this.getCode();
        this.props.dfpCallback({ code: code });
    }

    getCode = () => {
        let searchString = this.props.location.search;
        searchString = searchString
            ? searchString.substring(1, searchString.length)
            : '';
        return queryString.parse(searchString).code;
    };

    render() {
        return (
            <div>
                <h2>
                    <p>Your account was successfully connected.</p>
                    <p>You can now close this page.</p>
                </h2>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dfpCallback: code => dispatch(dfpCallback(code)),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(DFPCallback);
