// Needs to be in unsecure build.

import React from 'react';
export const CompanyNavigations = () => {
    const year = new Date();
    return (
        <div className="col-md-3 col-lg-2 d-md-block primary-bg-color sidebar sm-hidden">
            <a
                className="navbar-brand mr-0 d-none d-md-block"
                href="/"
                title="Confiant Home"
            >
                <span className="company-logo"></span>
            </a>
            <nav>
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://www.confiant.com/solutions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                SOLUTIONS
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://www.confiant.com/company"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                ABOUT US
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://www.confiant.com/careers"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CAREERS
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href="https://www.confiant.com/about-us"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                CONTACT US
                            </a>
                        </li>
                    </ul>
                    <div className="secondary-menu">
                        <div className="social-links-container">
                            <p>Follow us</p>
                            <a
                                href="https://www.linkedin.com/company/weareconfiant/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a
                                href="https://twitter.com/weareconfiant"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a
                                href="https://www.confiant.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="/static/images/confiant_logo_mark.svg"
                                    alt="Confiant, Inc"
                                />
                            </a>
                        </div>
                        <div className="copy-container">
                            <a
                                href="https://status.confiant.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Status Page
                            </a>
                        </div>
                        <div className="copy-container">
                            <a
                                href="https://www.confiant.com/terms-of-service"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Terms
                            </a>
                            <a
                                href="https://www.confiant.com/privacy-policy"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy
                            </a>
                            <p>
                                &copy; Copyright {year.getFullYear()} Confiant
                            </p>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
};
