import React from 'react';
import Modal from 'react-bootstrap/Modal';

const ReportModalWrapper = props => {
    return (
        <Modal
            show={props.show}
            onHide={props.hide}
            aria-labelledby="dfpLinksTitle"
            dialogClassName="modal-90w"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="creative-report-title">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
        </Modal>
    );
};

export default ReportModalWrapper;
