import React from 'react';
import { getFormattedNumber } from '../../utils/utils';

export const NetworkContent = React.memo(
    ({ networkLoad, hostCount, flagged_by_chrome }) => {
        // make some adjustments due to different network load data formats for Pub and SSP
        if (!!networkLoad) {
            if (networkLoad.length) {
                networkLoad = networkLoad[0];
            } else {
                hostCount = networkLoad.hosts_count;
            }
        }

        return (
            <React.Fragment>
                <div style={{ paddingBottom: '15px' }}>Ad Heaviness</div>
                <div className="row-sm-10">
                    <div
                        className="heaviness-title"
                        style={{ paddingBottom: '10px' }}
                    >
                        Network Load
                    </div>
                    {(!!networkLoad && (
                        <div className="network-load">
                            <div className="row">
                                <div className="col-2">Total</div>
                                <div className="col-8">
                                    {getFormattedNumber(
                                        (networkLoad.initial +
                                            networkLoad.polite) *
                                            1024,
                                        '0.0 b'
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">Initial</div>
                                <div className="col-8">
                                    {getFormattedNumber(
                                        networkLoad.initial * 1024,
                                        '0.0 b'
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">Polite</div>
                                <div className="col-8">
                                    {getFormattedNumber(
                                        networkLoad.polite * 1024,
                                        '0.0 b'
                                    )}
                                </div>
                            </div>
                        </div>
                    )) || <NoDataComponent />}
                    {!!hostCount && (
                        <div
                            id="host-count"
                            className="heaviness-title"
                            style={{ paddingTop: '10px' }}
                        >
                            Total Distinct Hosts: {hostCount}
                        </div>
                    )}
                    <div
                        id="flagged-by-chrome"
                        className="heaviness-title"
                        style={{ paddingTop: '10px' }}
                    >
                        Flagged by Chrome Heavy Ad Intervention?:{' '}
                        {flagged_by_chrome ? 'Yes' : 'No'}
                    </div>
                </div>
            </React.Fragment>
        );
    }
);

export const NoDataComponent = React.memo(() => (
    <div className="no-data-text">Not enough data</div>
));

export const NotAvailable = React.memo(({ text }) => (
    <div className="no-data-text">{text || 'Data not available'}</div>
));
