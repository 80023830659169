import { SET_AD_TRACE_DOM_INSTANCE, AD_TRACE_DOM_INSTANCE_START } from './ActionTypes';
import * as adTraceApi from '../api/AdTraceApi';
import { dispatchApi } from './helpers';

export const startAdTraceDomInstance = () => ({
    type: AD_TRACE_DOM_INSTANCE_START,
});

export const setAdTraceDomInstance = (domInstance) => ({
    type: SET_AD_TRACE_DOM_INSTANCE,
    domInstance,
});

export const loadAdTraceDomInstance = (domInstanceId) => {
    return dispatchApi(
        startAdTraceDomInstance,
        adTraceApi.getAdTraceDomInstance.bind(this, domInstanceId),
        (dispatch, json) => {
            return dispatch(setAdTraceDomInstance(json));
        },
        () => {}
    );
}
