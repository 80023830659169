import fetch from 'cross-fetch';
import { validateResponse } from './ApiCommon';
import { AdTraceURL } from './ApiUrls';

export const getAdTraceDomInstance = (dom_instance_id) => {
    return fetch(`${AdTraceURL}/${dom_instance_id}`, {
        credentials: 'include',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(validateResponse)
}